import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const DeckFlashingSelection = ({
  handleDeckFlashingChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedDeckFlashingOption, setSelectedDeckFlashingOption] =
    useState("");

  const handleDeckFlashingOptionChange = (selected) => {
    setSelectedDeckFlashingOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "deck flashing option",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "deck flashing option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "deck flashing option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDeckFlashingChange(selected);
  };

  return (
    <div
      className="popupwindow existingconcretefooting"
      style={{ marginBottom: "1%", width: "100%" }}
    >
      <BoxHeader
        componentName="Deck Flashing"
        redBorderFields={redBorderFields}
        redFieldName="deck flashing option"
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedDeckFlashingOption === "YES" ? "clicked" : ""}`}
          onClick={() => handleDeckFlashingOptionChange("YES")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedDeckFlashingOption === "NO" ? "clicked" : ""}`}
          onClick={() => handleDeckFlashingOptionChange("NO")}
        >
          No
        </div>
      </div>
    </div>
  );
};

DeckFlashingSelection.propTypes = {
  handleDeckFlashingChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default DeckFlashingSelection;
