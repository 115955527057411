import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BoxHeader from "./BoxHeader";

const DimensionsSelection = ({
  handleDimensionChange,
  redBorderFields,
  setFieldsWithRedBorder,
  pavstyle,
}) => {
  const [widthInchesOption, setWidthInchesOption] = useState("");
  const [lengthInchesOption, setLengthInchesOption] = useState("");
  const [widthFeetValue, setWidthFeetValue] = useState("");
  const [lengthFeetValue, setLengthFeetValue] = useState("");

  const handleWidthInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, "");

    const numericInput = parseFloat(input);
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11.999";
      } else {
        input = numericInput.toString();
      }
    }

    setWidthInchesOption(input);

    const updatedRedBorderFields = redBorderFields.includes("width inches")
      ? input
        ? redBorderFields.filter((field) => field !== "width inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "width inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDimensionChange(widthFeetValue, input, "width");
  };

  const handleWidthFeetChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    setWidthFeetValue(input);
    const updatedRedBorderFields = redBorderFields.includes("width feet")
      ? input
        ? redBorderFields.filter((field) => field !== "width feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "width feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDimensionChange(input, widthInchesOption, "width");
  };

  const handleLengthInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, "");

    const numericInput = parseFloat(input);
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11.999";
      } else {
        input = numericInput.toString();
      }
    }

    setLengthInchesOption(input);

    const updatedRedBorderFields = redBorderFields.includes("length inches")
      ? input
        ? redBorderFields.filter((field) => field !== "length inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDimensionChange(lengthFeetValue, input, "length");
  };

  const handleLengthFeetChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    setLengthFeetValue(input);
    const updatedRedBorderFields = redBorderFields.includes("length feet")
      ? input
        ? redBorderFields.filter((field) => field !== "length feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "length feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleDimensionChange(input, lengthInchesOption, "length");
  };

  useEffect(() => {
    handleDimensionChange(widthFeetValue, widthInchesOption, "width");
  }, [widthFeetValue, widthInchesOption, handleDimensionChange]);

  useEffect(() => {
    handleDimensionChange(lengthFeetValue, lengthInchesOption, "length");
  }, [lengthFeetValue, lengthInchesOption, handleDimensionChange]);

  return (
    <div className="load-selection">
      <div className="popupwindow lengthdim">
        {pavstyle !== "ST Thomas" && pavstyle !== "Santa FE" && (
          <BoxHeader
            componentName="Roof Projection"
            defaultMessage="Projection has a minimum of 4 feet."
          />
        )}
        {pavstyle === "ST Thomas" && (
          <BoxHeader
            componentName="Post Footprint Projection"
            defaultMessage="Post Footprint Projection refers to Projection of footprint. A ST Thomas gets a one foot overhang.<br/>Your roof is 2 feet longer than your projection if it's Free-Standing.<br/>Your roof is 1 foot longer than your projection if it is attached.<br/>Projection has a minimum of 4 feet."
          />
        )}
        {pavstyle === "Santa FE" && (
          <BoxHeader
            componentName="Post Footprint Projection"
            defaultMessage="Post Footprint Projection refers to Projection of the footprint. A Santa Fe gets no roof overhang.<br/>Instead there is an overhang of Pergola Components.<br/> Your pergola components are 2 feet longer than your projection and roof if it's Free-Standing.<br/>Your pergola components are 1 foot longer than your projection and roof if it is attached.<br/>Projection has a minimum of 4 feet."
          />
        )}
        <div className="fontsizeforinputsrow" id="rooflength">
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Feet:&nbsp;
            </span>
            <input
              id="lengthfeet"
              className={
                redBorderFields.includes("length feet")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={lengthFeetValue}
              onChange={handleLengthFeetChange}
              maxLength={3}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Inch:&nbsp;
            </span>
            <input
              id="lengthinches"
              className={
                redBorderFields.includes("length inches")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={lengthInchesOption}
              onChange={handleLengthInchesChange}
              style={{ width: "50%" }}
            />
          </span>
        </div>
      </div>
      <div className="popupwindow widthdim">
        {pavstyle !== "ST Thomas" && pavstyle !== "Santa FE" && (
          <BoxHeader
            componentName={"Roof Width"}
            defaultMessage={
              "Roof Width refers to distance from side to side.<br/>Width has a minimum of 4 feet."
            }
          />
        )}
        {pavstyle === "ST Thomas" && (
          <BoxHeader
            componentName="Post Footprint Width"
            defaultMessage="Post Footprint Width refers to Front overall width of footprint. A ST Thomas gets a one foot overhang.<br/>Your roof is 2 feet longer than your width.<br/>Width has a minimum of 4 feet."
          />
        )}
        {pavstyle === "Santa FE" && (
          <BoxHeader
            componentName="Post Footprint Width"
            defaultMessage="Post Footprint Width refers to Front overall width of footprint. A Santa Fe gets no roof overhang.<br/>Instead there is an overhang of Pergola Components.<br/>Your pergola components are 2 feet longer than your width and roof.<br/>Width has a minimum of 4 feet."
          />
        )}
        <div id="roofwidth" className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Feet:&nbsp;
            </span>
            <input
              id="widthfeet"
              className={
                redBorderFields.includes("width feet")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={widthFeetValue}
              onChange={handleWidthFeetChange}
              maxLength={3}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Inch:&nbsp;
            </span>
            <input
              id="widthinches"
              className={
                redBorderFields.includes("width inches")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={widthInchesOption}
              onChange={handleWidthInchesChange}
              style={{ width: "50%" }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

DimensionsSelection.propTypes = {
  handleDimensionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  pavstyle: PropTypes.string,
};

export default DimensionsSelection;
