import React from "react";
import AscendingDescendingButton from "../ascending_descending";
import UnprocessedRow from "./UnprocessedRow";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const UnprocessedTable = ({
  unprocessedIsLoading,
  unprocessed,
  unprocessedData,
  reformatDateSent,
  setsortUnprocessedOrderByLastName,
  setUnprocessedData,
  sortUnprocessedOrderByLastName,
  sortUnprocessedOrderByFirstName,
  setsortUnprocessedOrderByFirstName,
}) => {
  const handleSortUnprocessedByLastName = () => {
    const newOrder = sortUnprocessedOrderByLastName === "asc" ? "desc" : "asc";
    const sortedData = sortUnprocessedByLastName(newOrder);
    setsortUnprocessedOrderByLastName(newOrder);
    setUnprocessedData(sortedData);
  };

  const handleSortUnprocessedByFirstName = () => {
    const newOrder = sortUnprocessedOrderByFirstName === "asc" ? "desc" : "asc";
    const sortedData = sortUnprocessedByFirstName(newOrder);
    setsortUnprocessedOrderByFirstName(newOrder);
    setUnprocessedData(sortedData);
  };

  const sortUnprocessedByFirstName = (order) => {
    const sortedUnprocessed = [...unprocessed].sort((a, b) => {
      const nameA = a.firstName ? a.firstName.replace(/^\(C\) /i, "") : "";
      const nameB = b.firstName ? b.firstName.replace(/^\(C\) /i, "") : "";

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else if (order === "desc") {
        return nameB.localeCompare(nameA);
      }
      return 0;
    });
    return sortedUnprocessed;
  };

  const sortUnprocessedByLastName = (order) => {
    const sortedUnprocessed = [...unprocessed].sort((a, b) => {
      const nameA = a.lastName ? a.lastName.replace(/^\(C\) /i, "") : "";
      const nameB = b.lastName ? b.lastName.replace(/^\(C\) /i, "") : "";

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else if (order === "desc") {
        return nameB.localeCompare(nameA);
      }
      return 0;
    });
    return sortedUnprocessed;
  };

  return (
    <table style={{ maxHeight: "60vh", width: "100%", tableLayout: "fixed" }}>
      <thead className="table-header-div theadThStyle">
        <tr style={{ width: "100%" }}>
          <th className="theadThStyle">
            Future Folder
            <AscendingDescendingButton
              onClick={handleSortUnprocessedByLastName}
            />
          </th>
          <th className="theadThStyle">
            Last Name
            <AscendingDescendingButton
              onClick={handleSortUnprocessedByLastName}
            />
          </th>
          <th className="theadThStyle">
            First Name
            <AscendingDescendingButton
              onClick={handleSortUnprocessedByFirstName}
            />
          </th>
          <th className="theadThStyle"> Sent By </th>
          <th className="theadThStyle"> Date Sent </th>
          <th className="theadThStyle">File Name</th>
          {/* <th className="theadThStyle" style={{ borderRight: "none" }}>
            Viewable Files
          </th> */}
        </tr>
      </thead>
      <SimpleBar style={{ maxHeight: "50vh", width: "100%" }}>
        <tbody
          style={{
            display: "block",
            width: "100%",
          }}
        >
          {unprocessedIsLoading ? (
            <tr style={{ width: "100%" }}>
              <td colSpan={6} style={{ width: "100%" }}>
                Loading...
              </td>
            </tr>
          ) : unprocessed.length === 0 ? (
            <tr style={{ width: "100%" }}>
              <td colSpan={6} style={{ width: "100%" }}>
                No data available.
              </td>
            </tr>
          ) : (
            unprocessedData.map((unprocess, i) => (
              <UnprocessedRow
                unprocess={unprocess}
                reformatDateSent={reformatDateSent}
                key={i}
                rowKey={i}
              />
            ))
          )}
        </tbody>
      </SimpleBar>
      <tfoot style={{ width: "100%" }}>
        <tr style={{ width: "100%" }}>
          <td
            style={{
              backgroundColor: "#9ecb6a",
              fontSize: "medium",
              fontWeight: "bold",
              color: "white",
              width: "100%",
              zIndex: "0",
              position: "relative",
            }}
            colSpan={6}
          >
            <b>ALL ORDERS WILL BE PROCESSED WITHIN 3 BUSINESS DAYS</b>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

UnprocessedTable.displayName = "UnprocessedTable";

UnprocessedTable.propTypes = {
  unprocessedIsLoading: PropTypes.bool,
  unprocessed: PropTypes.array,
  unprocessedData: PropTypes.array,
  reformatDateSent: PropTypes.func,
  setsortUnprocessedOrderByLastName: PropTypes.func,
  setUnprocessedData: PropTypes.func,
  sortUnprocessedOrderByLastName: PropTypes.string,
  sortUnprocessedOrderByFirstName: PropTypes.string,
  setsortUnprocessedOrderByFirstName: PropTypes.func,
};

export default UnprocessedTable;
