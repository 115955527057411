import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const PergolaGutterSelection = ({
  handlePergolaGutterChange,
  redBorderFields,
  setFieldsWithRedBorder,
  selectedOperableOption,
}) => {
  const [selectedPergolaGutterOption, setSelectedPergolaGutterOption] =
    useState("");

  const handlePergolaGutterOptionChange = (selected) => {
    setSelectedPergolaGutterOption(selected);
    const updatedRedBorderFields = redBorderFields.includes("Gutter Option")
      ? selected
        ? redBorderFields.filter((field) => field !== "Gutter Option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Gutter Option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handlePergolaGutterChange(selected);
    if (selectedOperableOption === "PERGOLA FIXED") {
      handlePergolaGutterChange("NO");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: "1%",
      }}
    >
      <div className="popupwindow skylightselection">
        <BoxHeader
          componentName="Pergola Gutters"
          redBorderFields={redBorderFields}
          redFieldName={"Gutter Option"}
        />
        <div className="clickable-words">
          <div
            className={`clickable-word ${selectedPergolaGutterOption === "YES" ? "clicked" : ""}`}
            onClick={() => handlePergolaGutterOptionChange("YES")}
          >
            Yes
          </div>
          <div
            className={`clickable-word ${selectedPergolaGutterOption === "NO" ? "clicked" : ""}`}
            onClick={() => handlePergolaGutterOptionChange("NO")}
          >
            No
          </div>
        </div>
      </div>
      <div className="cleatsorbond"></div>
    </div>
  );
};

PergolaGutterSelection.propTypes = {
  handlePergolaGutterChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  selectedOperableOption: PropTypes.string,
};

export default PergolaGutterSelection;
