import React, { useState, useEffect, useCallback } from "react";
import PersonalInfoSelection from "../../../components/OrderingSystemComponents/UniversalComponents/PersonalInfoSelection";
import DimensionsSelection from "../../../components/OrderingSystemComponents/UniversalComponents/DimensionsSelection";
import LoadSelection from "../../../components/OrderingSystemComponents/UniversalComponents/LoadSelection";
import StyleChoices from "../../../components/OrderingSystemComponents/PavilionPageComponents/PavilionStyles";
import WallHeight from "../../../components/OrderingSystemComponents/UniversalComponents/WallHeight";
import PermitChoice from "../../../components/OrderingSystemComponents/PavilionPageComponents/PavilionPermitChoices";
import SubmitButton from "../../../components/OrderingSystemComponents/PavilionPageComponents/PavilionSubmitButton";
import RoofDensity from "../../../components/OrderingSystemComponents/UniversalComponents/RoofDensity";
import FanBeam from "../../../components/OrderingSystemComponents/UniversalComponents/FanBeam";
import SkyviewSelection from "../../../components/OrderingSystemComponents/PavilionPageComponents/SkyLights";
import CleatsOrBond from "../../../components/OrderingSystemComponents/UniversalComponents/CleatsOrBond";
import LEDLightSelection from "../../../components/OrderingSystemComponents/PavilionPageComponents/LEDLights";
import ValueSeries from "../../../components/OrderingSystemComponents/UniversalComponents/ValueSeries";
import RoofPanelColors from "../../../components/OrderingSystemComponents/UniversalComponents/RoofPanelColors";
import RoofSpanKey from "../../../components/OrderingSystemComponents/UniversalComponents/RoofSpanKey";
import StructureLayoutImages from "../../../components/OrderingSystemComponents/UniversalComponents/StructureLayout";
import ShingleRoof from "../../../components/OrderingSystemComponents/UniversalComponents/ShingleRoof";
import NoSkyLights from "../../../components/OrderingSystemComponents/PavilionPageComponents/NoSkyLights";
import BiHexSelection from "../../../components/OrderingSystemComponents/UniversalComponents/BiHexSelection";

const Pavilions = () => {
  const [selectedBiHexOption, setSelectedBiHexOption] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sender, setSender] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [zip, setZip] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [selectedWindLoadChange, setSelectedWindLoadChange] = useState("");
  const [selectedRoofLoadChange, setSelectedRoofLoadChange] = useState("");
  const [widthFeet, setWidthFeet] = useState("");
  const [widthInches, setWidthInches] = useState("");
  const [lengthFeet, setLengthFeet] = useState("");
  const [lengthInches, setLengthInches] = useState("");
  const [fieldsWithRedBorder, setFieldsWithRedBorder] = useState([]);
  const [pavstyle, setSelectedPavilionStyle] = useState("");
  const [roofstyle, setRoofStyle] = useState("");
  const [permitSelected, setPermitSelected] = useState("");
  const [gradeHeightFeet, setGradeHeightFeet] = useState("");
  const [gradeHeightInches, setGradeHeightInches] = useState("");
  const [selectedPermitOption, setSelectedPermitOption] = useState("");
  const [selectedKneeWallOption, setSelectedKneeWallOption] = useState("");
  const [selectedFoundationOption, setSelectedFoundationOption] = useState("");
  const [selectedMountOption, setSelectedMountOption] = useState("");
  const [stepDownInches, setStepDownInches] = useState("");
  const [stepDownFeet, setStepDownFeet] = useState("");
  const [
    selectedExistingFoundationOption,
    setSelectedExistingFoundationOption,
  ] = useState("");
  const [selectedNewFoundationOption, setSelectedNewFoundationOption] =
    useState("");
  const [selectedFasciaInches, setSelectedFasciaInches] = useState("");
  const [selectedKneeWallFeet, setSelectedKneeWallFeetChange] = useState("");
  const [selectedKneeWallInches, setSelectedKneeWallInchesChange] =
    useState("");
  const [selectedAttachedOption, setSelectedAttachedOption] = useState("");
  const [selectedExteriorOption, setHandleExteriorOptionChange] = useState("");
  const [selectedPostStyleOption, setPostStyleOptionChange] = useState("");
  const [selectedTrimOption, setHandleTrimChange] = useState("");
  const [selectedShingle, setHandleShingleChange] = useState("");
  const [selectedPostHeight, setHandlePostHeightOption] = useState("");
  const [selectedFanBeam, setHandleFanBeam] = useState("");
  const [selectedRoofDensity, setSelectedRoofDensity] = useState("");
  const [heightAtHouse, setHeightAtHouse] = useState({
    heightfeet: "",
    heightinches: "",
  });
  const [frontHeight, setFrontHeight] = useState({
    frontfeet: "",
    frontinches: "",
  });
  const [selectedPavilionLayoutOption, setPavilionLayoutOption] = useState("");
  const [selectedSkyviewChoice, setSelectedSkyviewChoice] = useState("");
  const [selectedLEDLightChoice, setSelectedLEDLightChoice] = useState("");
  const [selectedCleatsOrBond, setSelectedCleatsOrBond] = useState("");
  const [selectedValueSeries, setSelectedValueSeries] = useState("");
  const [selectedRailSideOption, setRailSideOption] = useState("");
  const [selectedRailAmountOption, setRailAmountOption] = useState({
    feet: "",
    inches: "",
  });
  const [selectedRoofPanelColorOption, setRoofPanelColorOption] = useState("");
  const [selectedStructuralBeamOption, setHandleStructuralBeamOption] =
    useState("");

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locations, setLocations] = useState({});
  const [newKey, setKey] = useState(0);
  const [tempFrontHeight, setTempFrontHeight] = useState({
    frontfeet: "",
    frontinches: "",
  });
  const [tempHeightAtHouse, setTempHeightAtHouse] = useState({
    heightfeet: "",
    heightinches: "",
  });
  const [shouldShowAlert, setShouldShowAlert] = useState(false);
  const [resetWallHeight, setResetWallHeight] = useState(false);
  const [currentCADImagesFiles, setCurrentCADImagesFiles] = useState("");
  const [drawingImages, setDrawingImages] = useState([]);

  const handleLocationChange = (event) => {
    var input = event.target.value;
    let updatedRedBorderFields;
    if (input.trim() && fieldsWithRedBorder.includes("location")) {
      updatedRedBorderFields = fieldsWithRedBorder.filter(
        (field) => field !== "location",
      );
    } else if (!input.trim() && !fieldsWithRedBorder.includes("location")) {
      updatedRedBorderFields = [...fieldsWithRedBorder, "location"];
    } else {
      updatedRedBorderFields = fieldsWithRedBorder;
    }

    setFieldsWithRedBorder(updatedRedBorderFields);
    setSelectedLocation(input);
  };

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setIsValidEmail(isValid);
    return isValid;
  };

  const handleStructuralBeamOption = (selected) => {
    setHandleStructuralBeamOption(selected);
  };

  const handleSelectedLEDLightChoice = (selected) => {
    setSelectedLEDLightChoice(selected);
  };

  const handleSelectedCleatsOrBond = (selected) => {
    setSelectedCleatsOrBond(selected);
  };

  const handleSelectedSkyviewChoice = (selected) => {
    setSelectedSkyviewChoice(selected);
  };

  const handleHeightChange = useCallback(({ heightAtHouse, frontHeight }) => {
    setHeightAtHouse(heightAtHouse);
    setFrontHeight(frontHeight);
  }, []);
  useEffect(() => {
    if (resetWallHeight || shouldShowAlert) {
      const newHeight = { heightfeet: "", heightinches: "" };
      const newFront = { frontfeet: "", frontinches: "" };

      handleHeightChange({ heightAtHouse: newHeight, frontHeight: newFront });
      setTempHeightAtHouse(newHeight);
      setTempFrontHeight(newFront);
      setResetWallHeight(false);
    }
  }, [
    resetWallHeight,
    shouldShowAlert,
    handleHeightChange,
    setTempHeightAtHouse,
    setTempFrontHeight,
    setResetWallHeight,
  ]);

  const handleRedBorderCheck = (missingFields) => {
    setFieldsWithRedBorder(missingFields);
  };

  const handleRoofDensity = (selectedRoofDensity) => {
    setSelectedRoofDensity(selectedRoofDensity);
  };

  const handleFanBeamOption = (selectedFanBeam) => {
    setHandleFanBeam(selectedFanBeam);
  };

  const handleAttachedOption = (attachedSelected) => {
    setSelectedAttachedOption(attachedSelected);
  };

  const handleFasciaInchesOption = (
    selectedFasciaInchesOption,
    selectedMountOption,
  ) => {
    if (selectedMountOption === "A") {
      setSelectedFasciaInches(selectedFasciaInchesOption);
    } else {
      setSelectedFasciaInches("");
    }
  };

  const handleKneeWallFeetChange = (selectedKneeWallFeetOption) => {
    setSelectedKneeWallFeetChange(selectedKneeWallFeetOption);
    if (selectedKneeWallOption === "no") {
      setSelectedKneeWallFeetChange("");
    }
  };

  const handleKneeWallInchesChange = (selectedKneeWallInchesOption) => {
    setSelectedKneeWallInchesChange(selectedKneeWallInchesOption);
    if (selectedKneeWallOption === "no") {
      setSelectedKneeWallInchesChange("");
    }
  };

  const handleFoundationChange = (selectedFoundation) => {
    setSelectedFoundationOption(selectedFoundation);
    if (selectedFoundation !== "existingconcrete") {
      setSelectedExistingFoundationOption("");
    }
    if (selectedFoundation !== "newconcrete") {
      setSelectedNewFoundationOption("");
    }
    if (
      selectedFoundation !== "existingwood" &&
      selectedFoundation !== "nontemowood"
    ) {
      setGradeHeightFeet("");
      setGradeHeightInches("");
    }
  };
  const handleMountChange = (selectedMount) => {
    setSelectedMountOption(selectedMount);
    setSelectedFasciaInches("");
  };
  const handleExistingFoundationChange = (selectedExistingFoundation) => {
    setSelectedExistingFoundationOption(selectedExistingFoundation);
  };
  const handleNewFoundationChange = (selectedNewFoundation) => {
    setSelectedNewFoundationOption(selectedNewFoundation);
  };
  const handleGradeHeightOption = (feet, inches) => {
    setGradeHeightFeet(feet);
    setGradeHeightInches(inches);
  };
  const handleStepDownChange = (feet, inches) => {
    setStepDownFeet(feet);
    setStepDownInches(inches);
  };

  const [, setKneeWallData] = useState({
    feet: "",
    inches: "",
  });

  const handleKneeWallChange = (selectedKneeWall) => {
    if (selectedKneeWall === "no") {
      setKneeWallData({
        feet: "",
        inches: "",
      });
    }
    setSelectedKneeWallOption(selectedKneeWall);
  };

  const handlePermitChange = (selectedPermit) => {
    setSelectedPermitOption(selectedPermit);
  };

  const handlePersonalInfoChange = (info) => {
    setFirstName(info.firstName);
    setLastName(info.lastName);
    setSender(info.sender);
    setEmail(info.email);
    setAddress(info.address);
    setCity(info.city);
    setStateProvince(info.stateProvince);
    setZip(info.zip);
    setPurchaseOrder(info.purchaseOrder);
  };

  const handleBiHexOptionChange = (selectedBiHex) => {
    setSelectedBiHexOption(selectedBiHex);
  };

  const handleWindLoadOption = (selectedWindLoad) => {
    setSelectedWindLoadChange(selectedWindLoad);
  };

  const handleRoofLoadOption = (selectedRoofLoad) => {
    setSelectedRoofLoadChange(selectedRoofLoad);
  };
  const handlePavilionStyleOptionChange = (selectedPavilionStyle) => {
    setSelectedPavilionStyle(selectedPavilionStyle);
    setKey((prevKey) => prevKey + 1);
    if (selectedPavilionStyle !== "TEMO Panel") {
      setRoofStyle("");
      setPostStyleOptionChange("");
      setHandleExteriorOptionChange("");
      setHandleStructuralBeamOption("");
      setHandlePostHeightOption("");
      setSelectedValueSeries("");
      if (selectedTrimOption === "BRONZE") {
        handleTrimChange("");
      }
    }
  };

  const handleDimensionChange = (feet, inches, type) => {
    if (type === "width") {
      setWidthFeet(feet);
      setWidthInches(inches);
    } else if (type === "length") {
      setLengthFeet(feet);
      setLengthInches(inches);
    }
  };

  const handleRoofStyleOptionChange = (selectedRoofStyle) => {
    setRoofStyle(selectedRoofStyle);
  };

  const handlePermitSelection = (selected) => {
    setPermitSelected(selected);
  };

  const handlePostStyleOptionChange = (selectedPostStyleOption) => {
    setPostStyleOptionChange(selectedPostStyleOption);
  };

  const handleExteriorOptionChange = (selectedExteriorOption) => {
    setHandleExteriorOptionChange(selectedExteriorOption);
  };

  const handleTrimChange = (selectedTrimOption) => {
    setHandleTrimChange(selectedTrimOption);
    if (selectedTrimOption !== "SANDSTONE" && selectedTrimOption !== "WHITE") {
      setSelectedValueSeries("");
    }
  };
  const handleShingleChange = (selectedShingle) => {
    setHandleShingleChange(selectedShingle);
    if (selectedShingle === "YES") {
      setSelectedSkyviewChoice("Skyview NO");
    }
  };

  const handlePostHeightOption = (feet, inches) => {
    setHandlePostHeightOption(feet, inches);
  };

  const handlePavilionLayoutOptionChange = (selected) => {
    setPavilionLayoutOption(selected);
  };

  const handleValueSeriesOption = (selected) => {
    setSelectedValueSeries(selected);
  };

  const handleRailSideOption = (selected) => {
    setRailSideOption(selected);
  };
  const handleRailAmountOption = (selected) => {
    setRailAmountOption(selected);
  };
  const handleRoofPanelColorOption = (selected) => {
    setRoofPanelColorOption(selected);
  };

  useEffect(() => {
    if (
      (Number(lengthFeet) > 15 || Number(lengthFeet) < 7) &&
      Number(lengthFeet) !== 0
    ) {
      handleSelectedSkyviewChoice("Skyview NO");
    }
  }, [lengthFeet, selectedSkyviewChoice]);

  useEffect(() => {
    if (selectedSkyviewChoice === "Skyview YES") {
      handleRoofDensity('4 1/4" 0.032 2 pound');
    }
  });

  return (
    <div className="pavilion">
      <div className="pergola-grid">
        <div className="style-choices">
          <StyleChoices
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handlePavilionStyleOptionChange={handlePavilionStyleOptionChange}
            pavstyle={pavstyle}
            handleRoofStyleOptionChange={handleRoofStyleOptionChange}
            onAttachedOptionChange={handleAttachedOption}
            permitSelected={permitSelected}
            handleTrimChange={handleTrimChange}
            handlePostStyleOptionChange={handlePostStyleOptionChange}
            handleExteriorOptionChange={handleExteriorOptionChange}
            handlePostHeightOption={handlePostHeightOption}
            handleShingleChange={handleShingleChange}
            selectedSkyviewChoice={selectedSkyviewChoice}
            handleStructuralBeamOption={handleStructuralBeamOption}
            selectedStructuralBeamOption={selectedStructuralBeamOption}
            selectedExteriorOption={selectedExteriorOption}
            selectedTrimOption={selectedTrimOption}
          />
          <BiHexSelection
            handleBiHexToolOptionChange={handleBiHexOptionChange}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
          />
        </div>
        <div className="personalinfo-choices">
          <PersonalInfoSelection
            onChange={handlePersonalInfoChange}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            validateEmail={validateEmail}
            isValidEmail={isValidEmail}
            locations={locations}
            setLocations={setLocations}
            handleLocationChange={handleLocationChange}
          />
        </div>
        <div className="dimensionthings">
          <div className="dimensions-load">
            <div className="dimension-choices">
              <DimensionsSelection
                handleDimensionChange={handleDimensionChange}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                pavstyle={pavstyle}
              />
            </div>
            <div className="load-choices">
              <LoadSelection
                handleRoofLoadOption={handleRoofLoadOption}
                handleWindLoadOption={handleWindLoadOption}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                selectedRoofLoadChange={selectedRoofLoadChange}
                selectedWindLoadChange={selectedWindLoadChange}
              />
            </div>
          </div>
          <div>
            <WallHeight
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              onHeightChange={handleHeightChange}
              roofstyle={roofstyle}
              heightAtHouse={heightAtHouse}
              setHeightAtHouse={setHeightAtHouse}
              frontHeight={frontHeight}
              setFrontHeight={setFrontHeight}
              newKey={newKey.toString()}
              resetWallHeight={resetWallHeight}
              setResetWallHeight={setResetWallHeight}
              setShouldShowAlert={setShouldShowAlert}
              shouldShowAlert={shouldShowAlert}
              tempFrontHeight={tempFrontHeight}
              tempHeightAtHouse={tempHeightAtHouse}
              setTempFrontHeight={setTempFrontHeight}
              setTempHeightAtHouse={setTempHeightAtHouse}
            />
          </div>
        </div>
        <div className="backWallPanels">
          {" "}
          <FanBeam
            handleFanBeamOption={handleFanBeamOption}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
          />
        </div>
        <div className="density-fanbeam">
          <div className="skyview">
            <ShingleRoof
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              redBorderFields={fieldsWithRedBorder}
              handleShingleChange={handleShingleChange}
              selectedSkyviewChoice={selectedSkyviewChoice}
              handleSelectedSkyviewChoice={handleSelectedSkyviewChoice}
            />
            {selectedRoofLoadChange !== "" &&
              lengthFeet !== "" &&
              lengthInches !== "" &&
              selectedAttachedOption !== "" &&
              pavstyle !== "" &&
              selectedSkyviewChoice !== "Skyview YES" && (
                <RoofDensity
                  handleRoofDensity={handleRoofDensity}
                  redBorderFields={fieldsWithRedBorder}
                  setFieldsWithRedBorder={setFieldsWithRedBorder}
                  selectedAttachedOption={selectedAttachedOption}
                  roofstyle={roofstyle}
                  lengthFeet={lengthFeet}
                  lengthInches={lengthInches}
                  selectedRoofLoadChange={selectedRoofLoadChange.toString()}
                  selectedShingle={selectedShingle}
                  pavstyle={pavstyle}
                />
              )}
            {selectedSkyviewChoice === "Skyview YES" && (
              <LEDLightSelection
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                handleSelectedLEDLightChoice={handleSelectedLEDLightChoice}
                selectedSkyviewChoice={selectedSkyviewChoice}
              />
            )}
          </div>
          <div className="skyview">
            {roofstyle !== "CATHEDRAL" &&
            7 <= Number(lengthFeet) &&
            Number(lengthFeet) <= 15 ? (
              <SkyviewSelection
                handleSelectedSkyviewChoice={handleSelectedSkyviewChoice}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                handleSelectedLEDLightChoice={handleSelectedLEDLightChoice}
                handleSelectedCleatsOrBond={handleSelectedCleatsOrBond}
                handleDimensionChange={handleDimensionChange}
                handleShingleChange={handleShingleChange}
                selectedShingle={selectedShingle}
                setSelectedSkyviewChoice={setSelectedSkyviewChoice}
                selectedSkyviewChoice={selectedSkyviewChoice}
              />
            ) : (
              Number(lengthFeet) !== 0 && (
                <NoSkyLights lengthFeet={lengthFeet} />
              )
            )}
            {selectedSkyviewChoice !== "Skyview YES" &&
              selectedShingle !== "YES" && (
                <CleatsOrBond
                  redBorderFields={fieldsWithRedBorder}
                  setFieldsWithRedBorder={setFieldsWithRedBorder}
                  handleSelectedCleatsOrBond={handleSelectedCleatsOrBond}
                  selectedSkyviewChoice={selectedSkyviewChoice}
                  selectedShingle={selectedShingle}
                />
              )}
            {(selectedShingle === "YES" ||
              selectedSkyviewChoice === "Skyview YES") && (
              <div className="cleatsorbond"></div>
            )}
            {Number(lengthFeet) === 0 && (
              <div className="cleatsorbond" style={{ marginLeft: "1%" }}></div>
            )}
          </div>
        </div>

        <div className="permit-choices">
          <PermitChoice
            onPermitSelection={handlePermitSelection}
            handlePermitChange={handlePermitChange}
            handleKneeWallChange={handleKneeWallChange}
            handleFoundationChange={handleFoundationChange}
            handleExistingFoundationChange={handleExistingFoundationChange}
            handleNewFoundationChange={handleNewFoundationChange}
            handleGradeHeightOption={handleGradeHeightOption}
            handleKneeWallFeetChange={handleKneeWallFeetChange}
            handleKneeWallInchesChange={handleKneeWallInchesChange}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            selectedAttachedOption={selectedAttachedOption}
            permitSelected={permitSelected}
            handleMountChange={handleMountChange}
            handleStepDownChange={handleStepDownChange}
            handleFasciaInchesOption={handleFasciaInchesOption}
            selectedMountOption={selectedMountOption}
            roofstyle={roofstyle}
          />
        </div>
        <div className="submit-choices">
          <SubmitButton
            selectedTrimOption={selectedTrimOption}
            selectedBiHexOption={selectedBiHexOption}
            selectedExteriorOption={selectedExteriorOption}
            selectedPostStyleOption={selectedPostStyleOption}
            selectedAttachedOption={selectedAttachedOption}
            selectedPermitOption={selectedPermitOption}
            selectedFoundationOption={selectedFoundationOption}
            selectedKneeWallOption={selectedKneeWallOption}
            selectedMountOption={selectedMountOption}
            personalInfo={{
              firstName,
              lastName,
              sender,
              email,
              address,
              city,
              stateProvince,
              zip,
              purchaseOrder,
            }}
            selectedExistingFoundationOption={selectedExistingFoundationOption}
            selectedNewFoundationOption={selectedNewFoundationOption}
            gradeHeightFeet={gradeHeightFeet}
            gradeHeightInches={gradeHeightInches}
            stepDownInches={stepDownInches}
            stepDownFeet={stepDownFeet}
            selectedWindLoadChange={selectedWindLoadChange.toString()}
            selectedRoofLoadChange={selectedRoofLoadChange.toString()}
            widthFeet={widthFeet}
            widthInches={widthInches}
            lengthFeet={lengthFeet}
            lengthInches={lengthInches}
            selectedPostHeight={selectedPostHeight}
            selectedKneeWallFeet={selectedKneeWallFeet}
            selectedKneeWallInches={selectedKneeWallInches}
            selectedFasciaInches={selectedFasciaInches}
            onRedBorderCheck={handleRedBorderCheck}
            selectedShingle={selectedShingle}
            selectedFanBeam={selectedFanBeam}
            selectedRoofDensity={selectedRoofDensity}
            pavstyle={pavstyle}
            heightAtHouse={heightAtHouse}
            frontHeight={frontHeight}
            roofstyle={roofstyle}
            selectedPavilionLayoutOption={selectedPavilionLayoutOption}
            selectedSkyviewChoice={selectedSkyviewChoice}
            selectedLEDLightChoice={selectedLEDLightChoice}
            selectedCleatsOrBond={selectedCleatsOrBond}
            selectedValueSeries={selectedValueSeries}
            selectedRailSideOption={selectedRailSideOption}
            selectedRailAmountOption={selectedRailAmountOption}
            selectedRoofPanelColorOption={selectedRoofPanelColorOption}
            selectedStructuralBeamOption={selectedStructuralBeamOption}
            isValidEmail={isValidEmail}
            selectedLocation={selectedLocation}
            locations={locations}
            setCurrentCADImagesFiles={setCurrentCADImagesFiles}
            currentCADImagesFiles={currentCADImagesFiles}
            drawingImages={drawingImages}
            setDrawingImages={setDrawingImages}
          />
        </div>
        {selectedAttachedOption === "attached" && (
          <StructureLayoutImages
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handlePavilionLayoutOptionChange={handlePavilionLayoutOptionChange}
            handleRailSideOption={handleRailSideOption}
            handleRailAmountOption={handleRailAmountOption}
          />
        )}
        <RoofPanelColors
          redBorderFields={fieldsWithRedBorder}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          handleRoofPanelColorOption={handleRoofPanelColorOption}
          selectedSkyviewChoice={selectedSkyviewChoice}
          selectedRoofDensity={selectedRoofDensity}
          selectedShingle={selectedShingle}
        />
        {pavstyle === "TEMO Panel" &&
          (selectedTrimOption === "SANDSTONE" ||
            selectedTrimOption === "WHITE") && (
            <div className="value-choices">
              <ValueSeries
                handleValueSeriesOption={handleValueSeriesOption}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
              />
            </div>
          )}
        <RoofSpanKey isScreenRoom={true} />
      </div>
    </div>
  );
};

export default Pavilions;
