// roofDensityUtils.js

export const feetInchConversion = (feet, inches) => {
  const totalInches = parseInt(feet) * 12 + parseInt(inches);
  return totalInches;
};

export const calculateRoofDensity = (
  selectedAttachedOption,
  selectedRoofLoadChange,
  totalInches,
  selectedShingle,
  pavstyle,
  isScreenRoom,
) => {
  console.log("selectedShingle", selectedShingle);
  var beamdeduction = isScreenRoom ? 1 : 2;
  var bearingdeduction =
    pavstyle !== "ST Thomas" && pavstyle !== "Santa FE"
      ? selectedAttachedOption === "FREE-STANDING"
        ? 24
        : 12
      : 0;
  var spanroof = totalInches - (bearingdeduction + beamdeduction);

  var option1;
  var option2;
  var option3 = true;
  if (selectedShingle === "NO") {
    option1 =
      spanroof <=
      {
        40: 9 * 12,
        35: 9 * 12 + 9,
        30: 10 * 12 + 6,
        25: 11 * 12 + 9,
        20: 13 * 12,
        15: 15 * 12 + 6,
        10: 18 * 12,
      }[selectedRoofLoadChange];
    option2 =
      spanroof <=
      {
        40: 10 * 12 + 6,
        35: 11 * 12 + 2,
        30: 11 * 12 + 10,
        25: 13 * 12 + 3,
        20: 14 * 12 + 8,
        15: 15 * 12 + 10,
        10: 17 * 12,
      }[selectedRoofLoadChange];
  }

  if (selectedShingle === "YES") {
    option1 = false;
    option2 =
      spanroof <=
      {
        40: 10 * 12 + 6 - beamdeduction,
        35: 11 * 12 + 2 - beamdeduction,
        30: 11 * 12 + 10 - beamdeduction,
        25: 13 * 12 + 3 - beamdeduction,
        20: 14 * 12 + 8 - beamdeduction,
        15: 15 * 12 + 10 - beamdeduction,
        10: 17 * 12 - beamdeduction,
      }[(Number(selectedRoofLoadChange) + 5).toString()];
  }

  return {
    option1,
    option2,
    option3,
  };
};
