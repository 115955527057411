import React, { useState } from "react";
import ExistingConcreteSlab from "./ExistingConcreteSlab";
import NewConcreteSlab from "./NewConcreteSlab";
import GradeHeight from "./GradeHeight";
import PropTypes from "prop-types";
import BoxHeader from "../BoxHeader";

const FoundationSelection = ({
  handleFoundationChange,
  handleExistingFoundationChange,
  handleNewFoundationChange,
  handleGradeHeightOption,
  redBorderFields,
  setFieldsWithRedBorder,
  setInitialFoundationOption,
  selectedAttachedOption,
  isInsideSunroom,
}) => {
  const [selectedFoundationOption, setSelectedFoundationOption] = useState("");
  const [selectedExistingConcrete, setSelectedExistingConcrete] = useState("");
  const [selectedNewConcrete, setSelectedNewConcrete] = useState("");
  const [selectedGradeHeightFeetOption, setSelectedGradeHeightFeetOption] =
    useState("");
  const [selectedGradeHeightInchesOption, setSelectedGradeHeightInchesOption] =
    useState("");

  const handleFoundationOptionChange = (event) => {
    const selectedoption = event.target.value;
    setSelectedFoundationOption(selectedoption);

    const updatedRedBorderFields = redBorderFields.includes("foundation option")
      ? selectedoption
        ? redBorderFields.filter((field) => field !== "foundation option")
        : redBorderFields
      : selectedoption
        ? redBorderFields
        : [...redBorderFields, "foundation option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleFoundationChange(selectedoption);
    setInitialFoundationOption(selectedoption);
    if (selectedoption === "existingconcrete") {
      handleGradeHeightOption("");
      handleNewFoundationChange("");
    }
    if (selectedoption === "newconcrete") {
      handleExistingFoundationChange("");
      handleGradeHeightOption("");
    }
    if (selectedoption === "existingwood" || selectedoption === "nontemowood") {
      handleNewFoundationChange("");
      handleExistingFoundationChange("");
    }
    if (selectedoption === "PP") {
      handleNewFoundationChange("");
      handleExistingFoundationChange("");
      handleGradeHeightOption("");
    }
    if (selectedAttachedOption === "FREE-STANDING") {
      handleNewFoundationChange("");
      handleExistingFoundationChange("");
      handleGradeHeightOption("");
    }
  };

  return (
    <div className="foundation-stuff">
      <div className="popupwindow foundation-choices">
        <BoxHeader
          componentName={"Foundation Choices"}
          redBorderFields={redBorderFields}
          redFieldName={"foundation option"}
        />
        <div>
          {selectedAttachedOption !== "FREE-STANDING" && (
            <div
              className={`clickable-word ${selectedFoundationOption === "existingwood" ? "clicked" : ""}`}
              onClick={() =>
                handleFoundationOptionChange({
                  target: { value: "existingwood" },
                })
              }
            >
              Existing Wood Deck
            </div>
          )}
          <br></br>
          <div
            className={`clickable-word ${selectedFoundationOption === "existingconcrete" ? "clicked" : ""}`}
            onClick={() =>
              handleFoundationOptionChange({
                target: { value: "existingconcrete" },
              })
            }
          >
            Existing Concrete Slab
          </div>
          <br></br>
          <div
            className={`clickable-word ${selectedFoundationOption === "newconcrete" ? "clicked" : ""}`}
            onClick={() =>
              handleFoundationOptionChange({ target: { value: "newconcrete" } })
            }
          >
            New Concrete Slab
          </div>
          <br></br>
          {!isInsideSunroom && (
            <div
              className={`clickable-word ${selectedFoundationOption === "PP" ? "clicked" : ""}`}
              onClick={() =>
                handleFoundationOptionChange({ target: { value: "PP" } })
              }
            >
              Posts on Piers
            </div>
          )}
          {isInsideSunroom && (
            <div
              className={`clickable-word ${selectedFoundationOption === "temodeck" ? "clicked" : ""}`}
              onClick={() =>
                handleFoundationOptionChange({ target: { value: "temodeck" } })
              }
            >
              TEMO Thermal Deck
            </div>
          )}
          <br></br>
          {selectedAttachedOption !== "FREE-STANDING" && (
            <div
              className={`clickable-word ${selectedFoundationOption === "nontemowood" ? "clicked" : ""}`}
              onClick={() =>
                handleFoundationOptionChange({
                  target: { value: "nontemowood" },
                })
              }
            >
              New Non-TEMO Wood Deck
            </div>
          )}
          <br></br>
        </div>
      </div>
      {selectedFoundationOption === "existingconcrete" && (
        <ExistingConcreteSlab
          handleExistingFoundationChange={handleExistingFoundationChange}
          selectedExistingConcrete={selectedExistingConcrete}
          redBorderFields={redBorderFields}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          setSelectedExistingConcrete={setSelectedExistingConcrete}
          selectedFoundationOption={selectedFoundationOption}
        />
      )}

      {selectedFoundationOption === "newconcrete" && (
        <NewConcreteSlab
          handleNewFoundationChange={handleNewFoundationChange}
          redBorderFields={redBorderFields}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          selectedNewConcrete={selectedNewConcrete}
          setSelectedNewConcrete={setSelectedNewConcrete}
          selectedFoundationOption={selectedFoundationOption}
        />
      )}
      {(selectedFoundationOption === "nontemowood" ||
        selectedFoundationOption === "existingwood" ||
        selectedFoundationOption === "temodeck") && (
        <GradeHeight
          handleGradeHeightOption={handleGradeHeightOption}
          redBorderFields={redBorderFields}
          setFieldsWithRedBorder={setFieldsWithRedBorder}
          selectedGradeHeightInchesOption={selectedGradeHeightInchesOption}
          selectedGradeHeightFeetOption={selectedGradeHeightFeetOption}
          setSelectedGradeHeightFeetOption={setSelectedGradeHeightFeetOption}
          setSelectedGradeHeightInchesOption={
            setSelectedGradeHeightInchesOption
          }
          selectedFoundationOption={selectedFoundationOption}
        />
      )}
    </div>
  );
};

FoundationSelection.propTypes = {
  handleFoundationChange: PropTypes.func,
  handleExistingFoundationChange: PropTypes.func,
  handleNewFoundationChange: PropTypes.func,
  handleGradeHeightOption: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  setInitialFoundationOption: PropTypes.func,
  selectedAttachedOption: PropTypes.string,
  isInsideSunroom: PropTypes.bool,
};

export default FoundationSelection;
