import React from "react";
import ColorDropdown from "./SunroomColorsComponents/ColorDropDown";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const SunroomColors = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleFrameOption,
  handleFasciaTrimOption,
  handleExteriorKpOption,
  handleInteriorKpOption,
  frameOption,
  fasciaTrimOption,
  exteriorKpOption,
  interiorKpOption,
  selectedRoomStyle,
  isScreenRoom,
  selectedValueSeries,
}) => {
  const bronze = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/bronze.jpg`;
  const white = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/white.jpg`;
  const sandstone = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/sandstone.jpg`;
  const images = !isScreenRoom
    ? [white, sandstone, bronze]
    : [white, sandstone];
  const messages = !isScreenRoom
    ? ["White", "Sandstone", "Bronze"]
    : ["White", "Sandstone"];

  const handleFrameChange = (event) => {
    const value = event.target.value;
    const idsToUpdate = ["frame", "fascia-trim", "exterior-kp", "interior-kp"];
    idsToUpdate.forEach((id) => {
      updateRedBorderFields(id, value);
    });

    handleFrameOption(value);
    handleFasciaTrimOption(value);
    handleExteriorKpOption(value);
    handleInteriorKpOption(value);
  };

  const handleFasciaTrimChange = (event) => {
    updateRedBorderFields(event.target.id, event.target.value);
    handleFasciaTrimOption(event.target.value);
  };

  const handleExteriorKpChange = (event) => {
    updateRedBorderFields(event.target.id, event.target.value);
    handleExteriorKpOption(event.target.value);
  };

  const handleInteriorKpChange = (event) => {
    updateRedBorderFields(event.target.id, event.target.value);
    handleInteriorKpOption(event.target.value);
  };

  const updateRedBorderFields = (id, value) => {
    setFieldsWithRedBorder((currentRedBorderFields) => {
      const isFieldPresent = currentRedBorderFields.includes(id);
      if (value && isFieldPresent) {
        return currentRedBorderFields.filter((field) => field !== id);
      } else if (!value && !isFieldPresent) {
        return [...currentRedBorderFields, id];
      }
      return currentRedBorderFields;
    });
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginBottom: "1%" }}>
      <BoxHeader
        componentName="Colors"
        images={images}
        imageMessages={messages}
      />
      <form>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ColorDropdown
            id="frame"
            onChange={handleFrameChange}
            options={
              !isScreenRoom
                ? ["white", "bronze", "sandstone"]
                : ["white", "sandstone"]
            }
            title="Frame Color"
            redBorderFields={redBorderFields}
            value={frameOption}
            otherTitle="F"
          />
          {selectedRoomStyle !== "Existing Roof" && (
            <ColorDropdown
              id="fascia-trim"
              onChange={handleFasciaTrimChange}
              options={
                !isScreenRoom && selectedValueSeries !== "YES"
                  ? ["white", "bronze", "sandstone"]
                  : ["white", "sandstone"]
              }
              title="Fascia Trim Color"
              redBorderFields={redBorderFields}
              value={fasciaTrimOption}
              otherTitle="FT"
            />
          )}
          {selectedRoomStyle === "Existing Roof" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                marginRight: "1%",
                height: "3.5vh",
                alignItems: "center",
                flexDirection: "row",
              }}
            ></div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ColorDropdown
            id="exterior-kp"
            onChange={handleExteriorKpChange}
            options={
              !isScreenRoom
                ? ["white", "bronze", "sandstone"]
                : ["white", "sandstone"]
            }
            title="Exterior KP Color"
            redBorderFields={redBorderFields}
            value={exteriorKpOption}
            otherTitle="EKP"
          />
          <ColorDropdown
            id="interior-kp"
            onChange={handleInteriorKpChange}
            options={
              !isScreenRoom
                ? ["white", "bronze", "sandstone"]
                : ["white", "sandstone"]
            }
            title="Interior KP Color"
            redBorderFields={redBorderFields}
            value={interiorKpOption}
            otherTitle="IKP"
          />
        </div>
      </form>
    </div>
  );
};

SunroomColors.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleFrameOption: PropTypes.func,
  handleFasciaTrimOption: PropTypes.func,
  handleExteriorKpOption: PropTypes.func,
  handleInteriorKpOption: PropTypes.func,
  frameOption: PropTypes.string,
  fasciaTrimOption: PropTypes.string,
  exteriorKpOption: PropTypes.string,
  interiorKpOption: PropTypes.string,
  selectedRoomStyle: PropTypes.string,
  isScreenRoom: PropTypes.bool,
  selectedSunRoomType: PropTypes.string,
  selectedValueSeries: PropTypes.string,
};

export default SunroomColors;
