import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const LEDLightSelection = ({
  handleSelectedLEDLightChoice,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedLEDOption, setSelectedLEDOption] = useState("");
  // const images = ["LED_Yes", "LED_No"];
  // const messages = ["LED YES", "LED NO"];

  const handleLEDOptionChange = (selected) => {
    setSelectedLEDOption(selected);
    const updatedRedBorderFields = redBorderFields.includes("LED YES or NO")
      ? selected
        ? redBorderFields.filter((field) => field !== "LED YES or NO")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "LED YES or NO"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedLEDLightChoice(selected);
  };

  return (
    <div className="popupwindow ledlightselection">
      <BoxHeader
        componentName="Skylight L.E.D.s"
        // images={images}
        // imageMessages={messages}
        redBorderFields={redBorderFields}
        redFieldName="LED YES or NO"
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedLEDOption === "LED YES" ? "clicked" : ""}`}
          onClick={() => handleLEDOptionChange("LED YES")}
        >
          YES
        </div>
        <div
          className={`clickable-word ${selectedLEDOption === "LED NO" ? "clicked" : ""}`}
          onClick={() => handleLEDOptionChange("LED NO")}
        >
          NO
        </div>
      </div>
    </div>
  );
};

LEDLightSelection.propTypes = {
  handleSelectedLEDLightChoice: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default LEDLightSelection;
