import { useState, useEffect, useCallback, useRef } from "react";

const useButtonScrollability = () => {
  const buttonRef = useRef(null);
  const [isButtonScrollable, setIsButtonScrollable] = useState(false);

  const checkButtonScrollability = useCallback(() => {
    const button = buttonRef.current;
    if (button) {
      const isScrollable = button.scrollWidth > button.offsetWidth;
      setIsButtonScrollable(isScrollable);
    }
  }, []);

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) return;

    const resizeObserver = new ResizeObserver(() => {
      checkButtonScrollability();
    });

    resizeObserver.observe(button);

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkButtonScrollability]);

  useEffect(() => {
    checkButtonScrollability();
  }, [checkButtonScrollability]);

  const setButtonRef = useCallback(
    (node) => {
      buttonRef.current = node;
      checkButtonScrollability();
    },
    [checkButtonScrollability],
  );

  return { setButtonRef, isButtonScrollable };
};

export default useButtonScrollability;
