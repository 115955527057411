import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import projectionCodes from "../2INGlassScreen/GlassProjectionCodes.json";
import studioCodes from "../2INGlassScreen/GlassFrontWallStudio.json";
import UniversalDrawingModal from "../../SunroomPageComponents/SunRoomWallsComponents/UniversalWalls/UniversalDrawingModal";

function ValueViewExistModal({
  setIsModalOpen,
  index,
  doorOption,
  doorWidth,
  onCodeSelect,
  setSelectedImages,
  selectedButtonIndex,
  setSelectedButtonIndex,
  wallLengthsFeet,
  isFront,
  setNewSelectedCodes,
  initialSelectedCodes,
  redBorderField,
}) {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [autoSelectedCodes, setAutoSelectedCodes] = useState([]);
  const [fetchedImages, setFetchedImages] = useState({});

  const handleButtonClick = () => {
    setIsModalOpen(false);
  };

  const handleRadioChange = (radioIndex, selectedCode) => {
    setSelectedMessage(radioIndex);
    onCodeSelect(
      selectedCode,
      initialSelectedCodes,
      index,
      redBorderField,
      setNewSelectedCodes,
    );
    const selectedImage = fetchedImages[selectedCode];
    setSelectedImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = selectedImage;
      return newImages;
    });
    setSelectedButtonIndex((previousButtons) => {
      const newIndices = [...previousButtons];
      newIndices[index] = radioIndex;
      return newIndices;
    });
  };

  useEffect(() => {
    if (autoSelectedCodes.length === 0) {
      let newLength = "";
      let codes = {};
      let adjustedLength = wallLengthsFeet[index];

      if ((index === 0 || index === 2) && wallLengthsFeet[index] >= 20) {
        adjustedLength = 20;
      } else if (index === 1 && wallLengthsFeet[index] >= 30) {
        adjustedLength = 30;
      }

      if (isFront) {
        newLength = "Front Wall Studio: " + adjustedLength + "'";
        codes = studioCodes;
      } else {
        newLength = "Projection: " + adjustedLength + "'";
        codes = projectionCodes;
      }

      let autoSelectedCodes = [];
      if (doorOption[index] === "No" || adjustedLength < 4) {
        if (codes[newLength] && codes[newLength]["Solid Fill Panel"]) {
          autoSelectedCodes.push(codes[newLength]["Solid Fill Panel"]);
        }
        if (codes[newLength] && codes[newLength]["All Glass"]) {
          autoSelectedCodes.push(codes[newLength]["All Glass"]);
        }
      } else if (doorOption[index] === "Yes") {
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["At Left Projection"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["At Left Projection"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Existing Home"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Existing Home"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Centered"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Centered"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["At Right Projection"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["At Right Projection"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Away From House"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Away From House"],
          );
        }
      }

      setAutoSelectedCodes(autoSelectedCodes);
    }
  }, [
    index,
    doorOption,
    doorWidth,
    autoSelectedCodes.length,
    autoSelectedCodes,
    wallLengthsFeet,
    isFront,
  ]);

  const fetchedImagesCache = useRef({});

  useEffect(() => {
    const createImagePaths = async () => {
      const newFetchedImages = { ...fetchedImagesCache.current };
      const flatSelectedCodes = autoSelectedCodes.flat();
      const codes = flatSelectedCodes.filter(
        (code) => code && !fetchedImagesCache.current[code],
      );

      if (codes.length > 0) {
        for (const code of codes) {
          const imageUrl = `https://temoappstorage.blob.core.windows.net/temoimages/GlassImages/${code}.jpg`;
          newFetchedImages[code] = imageUrl;
        }
      }

      fetchedImagesCache.current = newFetchedImages;
      setFetchedImages(newFetchedImages);
    };

    createImagePaths();
  }, [autoSelectedCodes, setFetchedImages]);

  return (
    <UniversalDrawingModal
      selectedMessage={selectedMessage}
      handleButtonClick={handleButtonClick}
      autoSelectedCodes={autoSelectedCodes}
      handleRadioChange={handleRadioChange}
      fetchedImages={fetchedImages}
      selectedButtonIndex={selectedButtonIndex}
      index={index}
    />
  );
}

ValueViewExistModal.propTypes = {
  setIsModalOpen: PropTypes.func,
  index: PropTypes.number,
  doorOption: PropTypes.array,
  doorWidth: PropTypes.array,
  setSelectedWallCodes: PropTypes.func,
  onCodeSelect: PropTypes.func,
  setSelectedImages: PropTypes.func,
  selectedImages: PropTypes.array,
  selectedButtonIndex: PropTypes.array,
  setSelectedButtonIndex: PropTypes.func,
  wallLengthsFeet: PropTypes.array,
  isFront: PropTypes.bool,
  setNewSelectedCodes: PropTypes.func,
  initialSelectedCodes: PropTypes.array,
  redBorderField: PropTypes.string,
};

export default ValueViewExistModal;
