import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../../UniversalComponents/BoxHeader";

function WallLength({
  wallLengthsFeet,
  wallLengthsInches,
  redBorderFields,
  index,
  handleWallLengthFeetChange,
  handleWallLengthInchesChange,
  setWallLengthsFeet,
  setWallLengthsInches,
  wallLengthFeetMessage,
  wallLengthInchesMessage,
  setLastWallLengthUpdate,
  setSelectedSwingDoor,
  setSelectedPatioDoor,
}) {
  const [inputValue, setInputValue] = useState(wallLengthsFeet[index]);
  const shouldUpdate = useRef(false);

  const handleBlur = (e) => {
    const value = e.target.value;
    handleWallLengthFeetChange(
      setWallLengthsFeet,
      wallLengthsFeet,
      wallLengthFeetMessage,
      index,
      value,
      setSelectedSwingDoor,
      setSelectedPatioDoor,
    );
    setLastWallLengthUpdate(Date.now());
    shouldUpdate.current = true;
  };

  const handleChange = (e) => {
    if (shouldUpdate.current) {
      const value = e.target.value;
      const updatedWallLengthsFeet = [...wallLengthsFeet];
      updatedWallLengthsFeet[index] = value;
      setWallLengthsFeet(updatedWallLengthsFeet);
      setInputValue(value);
      shouldUpdate.current = false;
    } else {
      setInputValue(e.target.value);
    }
  };

  return (
    <div style={{ width: "18.3vw" }}>
      <label
        className="popupwindow"
        style={{
          width: "100%",
          height: "8vh",
          color: "black",
          fontSize: "1.4vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BoxHeader componentName="Wall Length" />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span style={{ flex: 1, maxWidth: "20%" }}>Feet:</span>
          <input
            className={`${redBorderFields.includes(`${wallLengthFeetMessage}`) ? "redBackground" : ""}`}
            style={{ flex: 1, maxWidth: "25%" }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={inputValue}
            maxLength={3}
            pattern="\d*"
          />
          <span style={{ flex: 1, maxWidth: "20%" }}>Inches:</span>
          <input
            className={`${redBorderFields.includes(`${wallLengthInchesMessage}`) ? "redBackground" : ""}`}
            style={{ flex: 1, maxWidth: "25%" }}
            onChange={(e) =>
              handleWallLengthInchesChange(
                setWallLengthsInches,
                wallLengthsInches,
                wallLengthInchesMessage,
                index,
                e.target.value,
              )
            }
            value={wallLengthsInches[index]}
          />
        </div>
      </label>
    </div>
  );
}

WallLength.propTypes = {
  wallLengthsFeet: PropTypes.array,
  wallLengthsInches: PropTypes.array,
  redBorderFields: PropTypes.array,
  index: PropTypes.number,
  handleWallLengthFeetChange: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  setWallLengthsFeet: PropTypes.func,
  wallLengthMessage: PropTypes.string,
  wallLengthInchesMessage: PropTypes.string,
  setWallLengthsInches: PropTypes.func,
  wallLengthFeetMessage: PropTypes.string,
  setLastWallLengthUpdate: PropTypes.func,
  setSelectedSwingDoor: PropTypes.func,
  setSelectedPatioDoor: PropTypes.func,
};

export default WallLength;
