import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const BottomTransoms = ({
  bottomTransom,
  handleBottomTransomChange,
  redBorderFields,
}) => {
  return (
    <div className="popupwindow" style={{ width: "50%", height: "100%" }}>
      <BoxHeader
        componentName="Bottom Transom"
        defaultMessage='12" or 24" factory transoms that go at the bottom of the wall.<br/>Size is determined by Sunroom Type.<br/>Upper Deck - 24"<br/>Vista - 12"<br/>Solarium - 12"'
        redBorderFields={redBorderFields}
        redFieldName={"Bottom Transom"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${bottomTransom === "yes" ? "clicked" : ""}`}
          onClick={() => handleBottomTransomChange("yes")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${bottomTransom === "no" ? "clicked" : ""}`}
          onClick={() => handleBottomTransomChange("no")}
        >
          No
        </div>
      </div>
    </div>
  );
};

BottomTransoms.propTypes = {
  bottomTransom: PropTypes.string,
  handleBottomTransomChange: PropTypes.func,
  redBorderFields: PropTypes.array,
};

export default BottomTransoms;
