import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../../UniversalComponents/BoxHeader";

function StainlessSteelHinges({
  handleSSHingesOptionChange,
  redBorderFields,
  SSHinges,
}) {
  return (
    <div
      className="popupwindow"
      style={{ flex: 1, height: "103%", width: "100%" }}
    >
      <BoxHeader
        componentName="Stainless Steel Hinges"
        redBorderFields={redBorderFields}
        redFieldName={"SS Hinges"}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "50%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="clickable-words"
          style={{ flexWrap: "nowrap", width: "100%" }}
        >
          <div
            className={`clickable-word ${SSHinges === "YES" ? "clicked" : ""}`}
            onClick={() => handleSSHingesOptionChange("YES")}
          >
            Yes
          </div>
          &nbsp;
          <div
            className={`clickable-word ${SSHinges === "NO" ? "clicked" : ""}`}
            onClick={() => handleSSHingesOptionChange("NO")}
          >
            No
          </div>
        </div>
      </div>
    </div>
  );
}

StainlessSteelHinges.propTypes = {
  handleSSHingesOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  SSHinges: PropTypes.string,
};

export default StainlessSteelHinges;
