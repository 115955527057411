import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import projectionCodes from "./PremierProjectionCodes.json";
import cathedralCodes from "./PremierFrontWallCathedral.json";
import studioCodes from "./PremierFrontWallStudio.json";
import UniversalDrawingModal from "../UniversalWalls/UniversalDrawingModal";

function PremierViewDrawingModal({
  setIsModalOpen,
  index,
  doorOption,
  doorWidth,
  selectedRoomStyle,
  onCodeSelect,
  setSelectedImages,
  selectedButtonIndex,
  setSelectedButtonIndex,
  wallLengthsFeet,
  selectedPavilionLayoutOption,
  selectedBreezeWay,
  initialSelectedCodes,
  setNewSelectedCodes,
  redBorderField,
}) {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [autoSelectedCodes, setAutoSelectedCodes] = useState([]);
  const [fetchedImages, setFetchedImages] = useState({});

  const handleButtonClick = () => {
    setIsModalOpen(false);
  };

  const handleRadioChange = (radioIndex, selectedCode) => {
    setSelectedMessage(radioIndex);
    onCodeSelect(
      selectedCode,
      initialSelectedCodes,
      index,
      redBorderField,
      setNewSelectedCodes,
    );
    const selectedImage = fetchedImages[selectedCode];
    setSelectedImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = selectedImage;
      return newImages;
    });
    setSelectedButtonIndex((previousButtons) => {
      const newIndices = [...previousButtons];
      newIndices[index] = radioIndex;
      return newIndices;
    });
  };

  useEffect(() => {
    if (autoSelectedCodes.length === 0) {
      let newLength = "";
      let codes = {};
      let adjustedLength = wallLengthsFeet[index];

      if ((index === 0 || index === 2) && wallLengthsFeet[index] >= 20) {
        adjustedLength = 20;
      } else if (index === 1 && wallLengthsFeet[index] >= 30) {
        adjustedLength = 30;
      }

      if (selectedPavilionLayoutOption !== "3") {
        if ((selectedBreezeWay === "Yes" && index === 1) || index !== 1) {
          newLength = "Projection: " + adjustedLength + "'";
          codes = projectionCodes;
        } else if (selectedBreezeWay !== "Yes" && index === 1) {
          if (selectedRoomStyle === "CATHEDRAL") {
            newLength = "Front Wall Cathedral: " + adjustedLength + "'";
            codes = cathedralCodes;
          } else if (selectedRoomStyle !== "CATHEDRAL") {
            newLength = "Front Wall Studio: " + adjustedLength + "'";
            codes = studioCodes;
          }
        }
      } else if (selectedPavilionLayoutOption === "3") {
        if (selectedRoomStyle === "CATHEDRAL") {
          newLength = "Front Wall Cathedral: " + adjustedLength + "'";
          codes = cathedralCodes;
        } else if (selectedRoomStyle !== "CATHEDRAL") {
          newLength = "Front Wall Studio: " + adjustedLength + "'";
          codes = studioCodes;
        }
      }

      let autoSelectedCodes = [];
      if (doorOption[index] === "No" || adjustedLength <= 4) {
        if (codes[newLength] && codes[newLength]["Solid Fill Panel"]) {
          autoSelectedCodes.push(codes[newLength]["Solid Fill Panel"]);
        }
        if (codes[newLength] && codes[newLength]["All Glass"]) {
          autoSelectedCodes.push(codes[newLength]["All Glass"]);
        }
      } else if (doorOption[index] === "Yes") {
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["At Left Projection"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["At Left Projection"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Existing Home"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Existing Home"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Centered"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Centered"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["At Right Projection"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["At Right Projection"],
          );
        }
        if (
          codes[newLength] &&
          codes[newLength][doorWidth[index]] &&
          codes[newLength][doorWidth[index]]["Away From House"]
        ) {
          autoSelectedCodes.push(
            codes[newLength][doorWidth[index]]["Away From House"],
          );
        }
        if (codes[newLength] && codes[newLength]["Two Doors"]) {
          autoSelectedCodes.push(codes[newLength]["Two Doors"]);
        }
      }

      setAutoSelectedCodes(autoSelectedCodes);
    }
  }, [
    index,
    selectedRoomStyle,
    doorOption,
    doorWidth,
    autoSelectedCodes.length,
    autoSelectedCodes,
    wallLengthsFeet,
    selectedPavilionLayoutOption,
    selectedBreezeWay,
    setAutoSelectedCodes,
  ]);

  const fetchedImagesCache = useRef({});

  useEffect(() => {
    const createImagePaths = async () => {
      const newFetchedImages = { ...fetchedImagesCache.current };
      const flatSelectedCodes = autoSelectedCodes.flat();
      const codes = flatSelectedCodes.filter(
        (code) => code && !fetchedImagesCache.current[code],
      );

      if (codes.length > 0) {
        for (const code of codes) {
          const imageUrl = `https://temoappstorage.blob.core.windows.net/temoimages/PremierViewWallImages/${code}.jpg`;
          newFetchedImages[code] = imageUrl;
        }
      }

      fetchedImagesCache.current = newFetchedImages;
      setFetchedImages(newFetchedImages);
    };

    createImagePaths();
  }, [autoSelectedCodes, setFetchedImages]);

  return (
    <UniversalDrawingModal
      selectedMessage={selectedMessage}
      handleButtonClick={handleButtonClick}
      autoSelectedCodes={autoSelectedCodes}
      handleRadioChange={handleRadioChange}
      fetchedImages={fetchedImages}
      selectedButtonIndex={selectedButtonIndex}
      index={index}
    />
  );
}

PremierViewDrawingModal.propTypes = {
  setIsModalOpen: PropTypes.func,
  index: PropTypes.number,
  doorOption: PropTypes.array,
  doorWidth: PropTypes.array,
  selectedRoomStyle: PropTypes.string,
  setSelectedWallCodes: PropTypes.func,
  onCodeSelect: PropTypes.func,
  setSelectedImages: PropTypes.func,
  selectedImages: PropTypes.array,
  selectedButtonIndex: PropTypes.number,
  setSelectedButtonIndex: PropTypes.func,
  wallLengthsFeet: PropTypes.array,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  autoSelectedCodes: PropTypes.array,
  setAutoSelectedCodes: PropTypes.func,
  redBorderField: PropTypes.string,
  initialSelectedCodes: PropTypes.array,
  setNewSelectedCodes: PropTypes.func,
};

export default PremierViewDrawingModal;
