import React, { useState } from "react";
import PropTypes from "prop-types";
import ColorDropdown from "./SunroomColorsComponents/ColorDropDown";
import BoxHeader from "../UniversalComponents/BoxHeader";

const ACWindows = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleACLeftOption,
  handleACCenterOption,
  handleACRightOption,
}) => {
  const [values, setValues] = useState({
    leftProjValue: "",
    centerValue: "",
    rightProjValue: "",
  });
  const [noneChecked, setNoneChecked] = useState(false);

  const DROPDOWN_OPTIONS = [
    "At House",
    "Centered",
    "Away From House",
    "Do Not Need",
    "Other Location",
  ];

  const handleDropdownChange = (id, value) => {
    setNoneChecked(false);
    setValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    switch (id) {
      case "leftProjValue":
        handleACLeftOption(value);
        break;
      case "centerValue":
        handleACCenterOption(value);
        break;
      case "rightProjValue":
        handleACRightOption(value);
        break;
      default:
        break;
    }

    if (value) {
      setFieldsWithRedBorder((prev) => prev.filter((field) => field !== id));
    } else {
      setFieldsWithRedBorder((prev) => [...new Set([...prev, id])]);
    }
  };

  const handleNoneButtonClick = (e) => {
    const isChecked = e.target.checked;
    setNoneChecked(isChecked);
    const newValue = isChecked ? "Do Not Need" : "";

    setValues({
      leftProjValue: newValue,
      centerValue: newValue,
      rightProjValue: newValue,
    });

    handleACLeftOption(newValue);
    handleACCenterOption(newValue);
    handleACRightOption(newValue);

    if (isChecked) {
      setFieldsWithRedBorder((prev) =>
        prev.filter(
          (field) =>
            !["leftProjValue", "centerValue", "rightProjValue"].includes(field),
        ),
      );
    }
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginBottom: "1%" }}>
      <BoxHeader
        componentName="A/C Window"
        defaultMessage={
          "The sill height will be raised to 26.0 to accommodate an air conditioning unit or doggy door."
        }
      />
      <form>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ColorDropdown
            id="leftProjValue"
            onChange={(e) =>
              handleDropdownChange("leftProjValue", e.target.value)
            }
            options={DROPDOWN_OPTIONS}
            title="Left Proj Location"
            redBorderFields={redBorderFields}
            value={values.leftProjValue}
            otherTitle={"L"}
          />
          <ColorDropdown
            id="rightProjValue"
            onChange={(e) =>
              handleDropdownChange("rightProjValue", e.target.value)
            }
            options={DROPDOWN_OPTIONS}
            title="Right Proj Location"
            redBorderFields={redBorderFields}
            value={values.rightProjValue}
            otherTitle={"R"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <ColorDropdown
            id="centerValue"
            onChange={(e) =>
              handleDropdownChange("centerValue", e.target.value)
            }
            options={[
              "At Left Projection",
              "Centered",
              "At Right Projection",
              "Do Not Need",
              "Other Location",
            ]}
            title="Front Wall Location"
            redBorderFields={redBorderFields}
            value={values.centerValue}
            otherTitle={"F"}
          />
          <label
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "1.5vh",
              justifyContent: "center",
              width: "100%",
            }}
          >
            None
            <input
              type="checkbox"
              checked={noneChecked}
              onChange={handleNoneButtonClick}
              style={{ marginLeft: "7%" }}
            />
          </label>
        </div>
      </form>
    </div>
  );
};

ACWindows.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleACLeftOption: PropTypes.func,
  handleACCenterOption: PropTypes.func,
  handleACRightOption: PropTypes.func,
};

export default ACWindows;
