import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const RoomStyle = ({
  handleSelectedRoomStyle,
  redBorderFields,
  setFieldsWithRedBorder,
  selectedSunRoomType,
  selectedRoomStyle,
  setSelectedRoomStyle,
}) => {
  const Cathedral = `https://temoappstorage.blob.core.windows.net/temoimages/SunRoomImages/Cathedral.jpg`;
  const Studio = `https://temoappstorage.blob.core.windows.net/temoimages/SunRoomImages/Studio.jpg`;
  const Existing_Roof = `https://temoappstorage.blob.core.windows.net/temoimages/SunRoomImages/Existing_Roof.jpg`;
  const images = [Studio, Cathedral, Existing_Roof];

  const messages = ["Studio", "Cathedral", "Existing Roof"];

  const handleRoomStyleChange = (selected) => {
    setSelectedRoomStyle(selected);
    const updatedRedBorderFields = redBorderFields.includes("ROOM STYLE")
      ? selected
        ? redBorderFields.filter((field) => field !== "ROOM STYLE")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "ROOM STYLE"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedRoomStyle(selected);
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginBottom: "1%" }}>
      <BoxHeader
        componentName="Room Style"
        images={images}
        imageMessages={messages}
        redBorderFields={redBorderFields}
        redFieldName={"ROOM STYLE"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedRoomStyle === "STUDIO" ? "clicked" : ""}`}
          onClick={() => handleRoomStyleChange("STUDIO")}
        >
          Studio
        </div>
        <div
          className={`clickable-word ${selectedRoomStyle === "CATHEDRAL" ? "clicked" : ""}`}
          onClick={() => handleRoomStyleChange("CATHEDRAL")}
          style={{ marginRight: "1%" }}
        >
          Cathedral
        </div>
        {selectedSunRoomType !== "Solarium" && (
          <div
            className={`clickable-word ${selectedRoomStyle === "Existing Roof" ? "clicked" : ""}`}
            onClick={() => handleRoomStyleChange("Existing Roof")}
            style={{ marginRight: "1%" }}
          >
            Existing Roof
          </div>
        )}
      </div>
    </div>
  );
};

RoomStyle.propTypes = {
  handleSelectedRoomStyle: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
  selectedRoomStyle: PropTypes.string,
  setSelectedRoomStyle: PropTypes.func,
};

export default RoomStyle;
