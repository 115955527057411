import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const AngledYesNo = ({
  handleSelectedAngled,
  redBorderFields,
  setFieldsWithRedBorder,
  setSelectedAngled,
  selectedAngled,
}) => {
  const handleAngleOptionChange = (selected) => {
    setSelectedAngled(selected);
    const updatedRedBorderFields = redBorderFields.includes("Angled Option")
      ? selected
        ? redBorderFields.filter((field) => field !== "Angled Option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Angled Option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedAngled(selected);
  };

  return (
    <div
      className="popupwindow"
      style={{ flex: 1, width: "49%", marginBottom: "1%", marginLeft: "1%" }}
    >
      <BoxHeader
        componentName="45&deg; Corners"
        defaultMessage="Click yes if you have a room with angles walls.<br/>TEMO only does 45&deg; corners."
        redBorderFields={redBorderFields}
        redFieldName={"Angled Option"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedAngled === "Yes" ? "clicked" : ""}`}
          onClick={() => handleAngleOptionChange("Yes")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedAngled === "No" ? "clicked" : ""}`}
          onClick={() => handleAngleOptionChange("No")}
        >
          No
        </div>
      </div>
    </div>
  );
};

AngledYesNo.propTypes = {
  handleSelectedAngled: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  setSelectedAngled: PropTypes.func,
  selectedAngled: PropTypes.string,
};

export default AngledYesNo;
