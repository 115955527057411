import React, { useState } from "react";
import PropTypes from "prop-types";

const CustomDropdown = ({
  options,
  onChange,
  setSelectedOption,
  selectedOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (bays, dimensions) => {
    setSelectedOption(bays);
    setIsOpen(false);
    onChange(bays, dimensions);
  };

  return (
    <div className="custom-dropdown">
      <div
        className="custom-dropdown__selected"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption
          ? `${selectedOption}`
          : "Select how many bays do you want"}
        <span className={`arrow ${isOpen ? "up" : "down"}`}></span>
      </div>
      {isOpen && (
        <div className="custom-dropdown__options">
          {options.map((option, index) => (
            <div
              key={index}
              value={option.bays}
              className="custom-dropdown__option"
              onClick={() => handleOptionClick(option.bays, option.dimensions)}
            >
              {option.bays} {option.dimensions}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CustomDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
};

export default CustomDropdown;
