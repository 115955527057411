import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import americanzips from "./american.txt";
import canadianzips from "./canadian.txt";
import PropTypes from "prop-types";
import BoxHeader from "./BoxHeader";

const PersonalInfoSelection = ({
  onChange,
  redBorderFields,
  setFieldsWithRedBorder,
  validateEmail,
  handleLocationChange,
  locations,
  setLocations,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    sender: "",
    email: "",
    address: "",
    city: "",
    stateProvince: "",
    zip: "",
    purchaseOrder: "",
  });

  const [americanZipData, setAmericanZipData] = useState([]);
  const [canadianZipData, setCanadianZipData] = useState([]);

  const fetchZipData = async () => {
    try {
      const responseAmerican = await fetch(americanzips);
      const textAmerican = await responseAmerican.text();
      const linesAmerican = textAmerican.split("\n");
      const parsedDataAmerican = linesAmerican.map((line) => {
        const zip = line.slice(0, 5).trim();
        const state = line.slice(6, 9).trim();
        const city = line.slice(9).trim();
        return { zip, state, city };
      });
      setAmericanZipData(parsedDataAmerican);
    } catch (error) {
      console.error("Error fetching ZIP code data:", error);
    }
    try {
      const responseCanadian = await fetch(canadianzips);
      const textCanadian = await responseCanadian.text();
      const linesCanadian = textCanadian.split("\n");
      const parsedDataCanadian = linesCanadian.map((line) => {
        const zip = line.slice(0, 7).trim(); // Adjust the slice to include the complete postal code
        const state = line.slice(-3).trim(); // Extract the state from the last 2 characters
        const city = line.slice(7, -3).trim(); // Extract the city between zip and state

        return { zip, state, city };
      });
      setCanadianZipData(parsedDataCanadian);
    } catch (error) {
      console.error("Error fetching ZIP code data:", error);
    }
  };

  const { getAccessTokenSilently } = useAuth0();

  const fetchLocations = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      const response = await fetch("/api/DealerUserIDName/locations", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error("Error fetching locations data:", response.statusText);
        return [];
      }

      const data = await response.json();

      setLocations(data);
    } catch (error) {
      console.error("Error fetching locations data:", error);
      return [];
    }
  }, [getAccessTokenSilently, setLocations]);

  useEffect(() => {
    fetchLocations();
    fetchZipData();
  }, [fetchLocations]);

  const handleStateProvinceChange = (e) => {
    e.persist();
    const value = e.target.value.slice(0, 3);
    const regex = /^[A-Za-z]+$/;

    if (regex.test(value) || value === "") {
      handleChange("stateProvince", value);
    }
  };

  const handleZipChange = (e) => {
    const zipValue = e.target.value.toUpperCase().replace(/\s/g, ""); // Remove spaces and convert to uppercase
    const hasLetters = /[A-Z]/.test(zipValue); // Check if the zip contains letters

    let formattedZip = "";

    if (hasLetters) {
      formattedZip = zipValue
        .replace(/^([A-Z0-9]{0,3}) ?([A-Z0-9]{0,3})?/, "$1 $2")
        .replace(/^(.{0,3}) ?(.{0,3}).*/, "$1 $2")
        .trim(); // Format with letters to 'CCC CCC'
    } else {
      formattedZip = zipValue.replace(/^(\d{0,5})$/, "$1").slice(0, 5); // Format without letters, restrict to max 5 digits
    }

    handleChange("zip", formattedZip);

    const foundZipAmerican = americanZipData.find(
      (item) => item.zip.replace(/\s/g, "") === zipValue,
    );
    const foundZipCanadian = canadianZipData.find(
      (item) => item.zip.replace(/\s/g, "") === zipValue,
    );

    if (foundZipAmerican) {
      setFormData((prevData) => ({
        ...prevData,
        stateProvince: foundZipAmerican.state,
        city: foundZipAmerican.city,
      }));
      const updatedRedBorderFields = redBorderFields.filter(
        (field) => field !== "stateProvince" && field !== "city",
      );
      setFieldsWithRedBorder(updatedRedBorderFields);
    } else if (foundZipCanadian) {
      setFormData((prevData) => ({
        ...prevData,
        stateProvince: foundZipCanadian.state,
        city: foundZipCanadian.city,
      }));
      const updatedRedBorderFields = redBorderFields.filter(
        (field) => field !== "stateProvince" && field !== "city",
      );
      setFieldsWithRedBorder(updatedRedBorderFields);
    } else {
      // Handle case where zip code is not found in either American or Canadian data
      setFormData((prevData) => ({
        ...prevData,
        stateProvince: "",
        city: "",
      }));
    }
  };

  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    const isFieldEmpty = value.trim() === "";
    const isFieldRequired = document
      .getElementById(fieldName)
      .hasAttribute("required");

    if (isFieldRequired && isFieldEmpty) {
      if (!redBorderFields.includes(fieldName)) {
        const updatedRedBorderFields = [...redBorderFields, fieldName];
        setFieldsWithRedBorder(updatedRedBorderFields);
      }
    } else {
      const updatedRedBorderFields = redBorderFields.filter(
        (field) => field !== fieldName,
      );
      setFieldsWithRedBorder(updatedRedBorderFields);
    }
  };

  const handleSenderChange = (e) => {
    const senderValue = e.target.value;
    if (senderValue.includes("@")) {
      alert("The Sender cannot contain the @ symbol.");
      setFormData((prevData) => ({
        ...prevData,
        sender: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        sender: senderValue,
      }));
    }
    let fieldName = e.target.id;
    const isFieldEmpty = senderValue.trim() === "";
    const isFieldRequired = document
      .getElementById(fieldName)
      .hasAttribute("required");

    if (isFieldRequired && isFieldEmpty) {
      if (!redBorderFields.includes(fieldName)) {
        const updatedRedBorderFields = [...redBorderFields, fieldName];
        setFieldsWithRedBorder(updatedRedBorderFields);
      }
    } else {
      const updatedRedBorderFields = redBorderFields.filter(
        (field) => field !== fieldName,
      );
      setFieldsWithRedBorder(updatedRedBorderFields);
    }
  };

  // Function to handle email input changes
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      email: inputEmail,
    }));
  };

  // Function to validate email when the input loses focus
  const handleEmailBlur = () => {
    const isValid = validateEmail(formData.email); // Assuming formData is your state variable holding the div data
    if (isValid) {
      setFieldsWithRedBorder((prev) => prev.filter((item) => item !== "email"));
    } else {
      setFieldsWithRedBorder((prev) => [...new Set([...prev, "email"])]);
    }
  };

  useEffect(() => {
    const sendDataToParent = () => {
      const data = {
        ...formData,
      };

      if (formData.firstName === "") {
        data.firstName = "";
      }
      if (formData.purchaseOrder === "") {
        data.purchaseOrder = "";
      }

      onChange(data);
    };

    sendDataToParent();
  }, [formData, onChange]);

  return (
    <div>
      <div>
        <div id="personalids">
          <div className="popupwindow personalinfo-stuff">
            <BoxHeader
              newStyle={{ justifyContent: "center" }}
              componentName="Home Owner Information"
            />
            <div
              className="clickable-words fontsizeforinputs"
              style={{ flexWrap: "nowrap" }}
            >
              <div className="personalinputwidths">
                <span>First&nbsp;Name:&nbsp;</span>
                <input
                  label="First Name"
                  id="firstName"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("firstName")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.firstName}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                />
              </div>
              <div className="personalinputwidths">
                <span>
                  <span className="redasterisk">&#10033;</span>
                  Last&nbsp;Name:&nbsp;
                </span>
                <input
                  label="Last Name"
                  id="lastName"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("lastName")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.lastName}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  required
                />
              </div>
            </div>
            <div
              className="clickable-words fontsizeforinputs"
              style={{ flexWrap: "nowrap" }}
            >
              <div className="personalinputwidths ">
                <span>
                  <span className="redasterisk">&#10033;</span>
                  Address:&nbsp;
                </span>
                <input
                  id="address"
                  label="Address"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("address")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  autoComplete="off"
                  required
                />
              </div>
              <div className="personalinputwidths">
                <span>
                  <span className="redasterisk">&#10033;</span>
                  Zip:&nbsp;
                </span>
                <input
                  label="Zip"
                  id="zip"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("zip")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.zip}
                  onChange={handleZipChange}
                  required
                />
              </div>
            </div>
            <div
              className="clickable-words fontsizeforinputs"
              style={{ flexWrap: "nowrap" }}
            >
              <div className="personalinputwidths">
                <span>
                  <span className="redasterisk">&#10033;</span>
                  City:&nbsp;
                </span>
                <input
                  label="City"
                  id="city"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("city")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.city}
                  onChange={(e) => handleChange("city", e.target.value)}
                  required
                />
              </div>
              <div className="personalinputwidths">
                <span className="redasterisk">&#10033;</span>State/Prov:&nbsp;
                <input
                  label="State/Province"
                  id="stateProvince"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("stateProvince")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.stateProvince}
                  onChange={handleStateProvinceChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="popupwindow personalinfo-stuff">
            <BoxHeader
              componentName="Sender Information"
              defaultMessage={
                "Sender: This refers to the person filling out the ordering forms."
              }
            />
            <div
              className="clickable-words fontsizeforinputs"
              style={{ flexWrap: "nowrap" }}
            >
              <div className="personalinputwidths">
                <span>
                  <span className="redasterisk">&#10033;</span>
                  Email:&nbsp;
                </span>
                <input
                  label="Email"
                  id="email"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("email")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  autoComplete="on"
                  required
                />
              </div>
              <div className="personalinputwidths">
                <span>
                  <span className="redasterisk">&#10033;</span>
                  Sender:&nbsp;
                </span>
                <input
                  label="Sender"
                  id="sender"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("sender")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.sender}
                  type="text"
                  onChange={handleSenderChange}
                  required
                />
              </div>
            </div>
            <div
              className="clickable-words fontsizeforinputs"
              style={{ flexWrap: "nowrap" }}
            >
              {locations && Object.keys(locations).length > 0 ? (
                <div className="personalinputwidths">
                  <span className="personalinputwidths">
                    <span className="redasterisk">&#10033;</span>
                    Location:&nbsp;
                  </span>
                  <select
                    onChange={handleLocationChange}
                    className={
                      "personalinputbox " +
                      (redBorderFields.includes("location")
                        ? "redBackground"
                        : "whiteBackground")
                    }
                  >
                    <option value="">Select</option>
                    {Object.keys(locations).map((key) => (
                      <option key={key} value={locations[key]}>
                        {locations[key]}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="personalinputwidths">
                  <span className="personalinputwidths"></span>
                </div>
              )}
              <div className="personalinputwidths">
                <span className="personalinputwidths">
                  P.O.&nbsp;Number:&nbsp;
                </span>
                <input
                  label="Purchase Order"
                  id="purchaseOrder"
                  className={
                    "personalinputbox " +
                    (redBorderFields.includes("purchaseOrder")
                      ? "redBackground"
                      : "whiteBackground")
                  }
                  value={formData.purchaseOrder}
                  onChange={(e) =>
                    handleChange("purchaseOrder", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PersonalInfoSelection.propTypes = {
  onChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  validateEmail: PropTypes.func,
  handleLocationChange: PropTypes.func,
  selectedLocation: PropTypes.string,
  locations: PropTypes.object,
  setLocations: PropTypes.func,
};

export default PersonalInfoSelection;
