import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../../UniversalComponents/BoxHeader";

const GlassRows = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleGlassRows,
  lengthFeet,
  lengthInches,
}) => {
  const [options, setOptions] = useState([]);

  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    const updatedRedBorderFields = redBorderFields.includes("Glass Rows")
      ? selected
        ? redBorderFields.filter((field) => field !== "Glass Rows")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Glass Rows"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleGlassRows(selected);
  };

  const feetInchConversion = (feet, inches) => {
    const totalInches = parseInt(feet) * 12 + parseInt(inches);
    return totalInches;
  };

  const calculatePanels = (totalInches) => {
    const spanroof = totalInches;
    const numberOfOptions = Math.floor(spanroof / 46);
    const optionsArray = Array.from(
      { length: numberOfOptions },
      (_, i) => i + 1,
    );

    setOptions(optionsArray);
  };

  useEffect(() => {
    var totalInches = feetInchConversion(lengthFeet, lengthInches);
    calculatePanels(totalInches);
  }, [lengthFeet, lengthInches]);
  return (
    <div className="popupwindow roofdensity" style={{ marginRight: "1%" }}>
      <BoxHeader
        componentName="Glass Panel Rows"
        defaultMessage={
          "Temo glass panels are 3 inches thick. Each row has a minimum size of 3 feet and 10 inches (46 inches)."
        }
        redBorderFields={redBorderFields}
        redFieldName={"Glass Rows"}
      />
      <span>
        <select
          id="roofdensity"
          className="input-box "
          style={{ width: "95%" }}
          onChange={handleDropdownChange}
        >
          <option value="">Select how many panel rows you want</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option} panel{option !== 1 ? "s" : ""}
            </option>
          ))}
        </select>
      </span>
    </div>
  );
};

GlassRows.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleGlassRows: PropTypes.func,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
};

export default GlassRows;
