import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import NumericInputWithButtons from "../NumericInput";
import BoxHeader from "../BoxHeader";

const FrontWallHeight = ({
  redBorderFields,
  roofstyle,
  setFieldsWithRedBorder,
  selectedAttachedOption,
  selectedSunRoomType,
  selectedTopTransomOption,
  onHeightChange,
  newKey,
  heightAtHouse,
  frontHeight,
  tempFrontHeight,
  setTempFrontHeight,
  isScreenRoom,
  isSunRoom,
}) => {
  const [frontmin, setFrontmin] = useState(0);
  const [sunroommessage, setSunroommessage] = useState("");
  const handleSunroomMessage = useCallback((message) => {
    setSunroommessage(message);
  }, []);
  const handleTempFrontChange = useCallback(
    (input) => {
      setTempFrontHeight(input);
    },
    [setTempFrontHeight],
  );
  const handleSetFrontMin = useCallback(
    (min) => {
      setFrontmin(min);
    },
    [setFrontmin],
  );

  const frontmax = !isSunRoom
    ? 14
    : !isScreenRoom
      ? roofstyle !== "Existing Roof"
        ? 11
        : 12
      : roofstyle !== "Existing Roof"
        ? 9
        : 10;

  const calculateFrontMinLimit = useCallback(
    (selectedSunRoomType, selectedTopTransomOption) => {
      let minLimit = 5;
      switch (selectedSunRoomType) {
        case "Solarium":
        case "Vista View":
          if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = 8;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = 9;
          } else {
            minLimit = 7;
          }
          break;
        case "Premier View":
          if (
            selectedTopTransomOption === "TOP TRANSOMS-10" ||
            selectedTopTransomOption === "TOP TRANSOMS-12"
          ) {
            minLimit = 8;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = 9;
          } else {
            minLimit = 7;
          }
          break;
        case "Upper Deck":
          if (
            selectedTopTransomOption === "TOP TRANSOMS-6" ||
            selectedTopTransomOption === "TOP TRANSOMS-10"
          ) {
            minLimit = 8;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = 9;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = 10;
          } else {
            minLimit = 7;
          }
          break;
        case "Value View":
        case "Vinyl View":
          minLimit = 7;
          break;
        default:
          minLimit = 5;
      }
      return minLimit;
    },
    [],
  );

  const handleFrontHeightChange = useCallback(
    (e) => {
      let inputName = e.target.name;
      let inputValue = e.target.value.replace(/[^\d.]/g, "");
      let numericInput = parseFloat(inputValue);
      let isWithinRange;
      let minLimit;
      let maxLimit = frontmax;

      setTimeout(() => {
        if (inputName === "frontfeet") {
          minLimit = calculateFrontMinLimit(
            selectedSunRoomType,
            selectedTopTransomOption,
          );

          if (!isNaN(numericInput)) {
            numericInput = Math.max(minLimit, Math.min(numericInput, maxLimit));
            inputValue = numericInput.toString();
            isWithinRange =
              numericInput >= minLimit && numericInput <= maxLimit;
          } else {
            isWithinRange = false;
            inputValue = "";
          }
        }
        setTempFrontHeight((prevHeight) => ({
          ...prevHeight,
          [inputName]: inputValue,
        }));
        const fieldName =
          "Front Height " +
          inputName.charAt(0).toUpperCase() +
          inputName.slice(1);

        const updatedRedBorderFields = isWithinRange
          ? redBorderFields.filter((field) => field !== fieldName)
          : redBorderFields.includes(fieldName)
            ? redBorderFields
            : [...redBorderFields, fieldName];

        setFieldsWithRedBorder(updatedRedBorderFields);
      }, 0);
    },

    [
      calculateFrontMinLimit,
      frontmax,
      redBorderFields,
      selectedSunRoomType,
      selectedTopTransomOption,
      setFieldsWithRedBorder,
      setTempFrontHeight,
    ],
  );

  const calculateFrontMinInchLimit = useCallback(
    (selectedSunRoomType, selectedTopTransomOption) => {
      let minLimit = 0;
      if (selectedSunRoomType === "Upper Deck") {
        if (tempFrontHeight.frontfeet === "7") {
          if (
            selectedTopTransomOption === "none" ||
            selectedTopTransomOption === ""
          ) {
            minLimit = 10;
          }
        } else if (tempFrontHeight.frontfeet === "8") {
          if (selectedTopTransomOption === "TOP TRANSOMS-6") {
            minLimit = 6;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-10") {
            minLimit = 10;
          }
        }
      } else if (
        selectedSunRoomType === "Vista View" ||
        selectedSunRoomType === "Solarium"
      ) {
        if (tempFrontHeight.frontfeet === "7") {
          if (selectedTopTransomOption === "TOP TRANSOMS-6") {
            minLimit = 6;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-10") {
            minLimit = 10;
          }
        } else if (tempFrontHeight.frontfeet === "8") {
          if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = 1;
          }
        }
      } else if (selectedSunRoomType === "Premier View") {
        if (tempFrontHeight.frontfeet === "7") {
          if (selectedTopTransomOption === "none") {
            minLimit = 3;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-6") {
            minLimit = 11;
          }
        } else if (tempFrontHeight.frontfeet === "8") {
          if (selectedTopTransomOption === "TOP TRANSOMS-10") {
            minLimit = 3;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = 6;
          }
        } else if (tempFrontHeight.frontfeet === "9") {
          if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = 6;
          }
        }
      } else if (
        selectedSunRoomType === "Vinyl View" ||
        selectedSunRoomType === "Value View"
      ) {
        if (tempFrontHeight.frontfeet === "7") {
          minLimit = 3;
        }
      } else {
        minLimit = 0;
      }
      return minLimit;
    },
    [tempFrontHeight.frontfeet],
  );

  const handleFrontHeightInchesBlur = useCallback(
    (e) => {
      let inputName = e.target.name;
      let inputValue = e.target.value;
      let minLimit = 0;
      let maxLimit = 11;
      minLimit = calculateFrontMinInchLimit(
        selectedSunRoomType,
        selectedTopTransomOption,
      );

      let numericInput = parseFloat(inputValue);
      let isWithinRange;

      if (!isNaN(numericInput)) {
        numericInput = Math.max(minLimit, Math.min(numericInput, maxLimit));
        inputValue = numericInput.toString();
        isWithinRange = numericInput >= minLimit && numericInput <= maxLimit;
      } else {
        isWithinRange = false;
        inputValue = "";
      }

      const fieldName =
        "Front Height " +
        inputName.charAt(0).toUpperCase() +
        inputName.slice(1);

      const updatedRedBorderFields = isWithinRange
        ? redBorderFields.filter((field) => field !== fieldName)
        : redBorderFields.includes(fieldName)
          ? redBorderFields
          : [...redBorderFields, fieldName];

      setFieldsWithRedBorder(updatedRedBorderFields);

      setTempFrontHeight((prevHeight) => ({
        ...prevHeight,
        [inputName]: inputValue,
      }));
    },
    [
      calculateFrontMinInchLimit,
      redBorderFields,
      selectedSunRoomType,
      selectedTopTransomOption,
      setFieldsWithRedBorder,
      setTempFrontHeight,
    ],
  );

  useEffect(() => {
    if (
      roofstyle === "Existing Roof" &&
      tempFrontHeight.frontfeet === frontmax.toString() &&
      tempFrontHeight.frontinches !== "0"
    ) {
      let newheightAtFront = {
        frontfeet: frontmax.toString(),
        frontinches: "0",
      };
      if (
        frontHeight.frontfeet !== newheightAtFront.frontfeet ||
        frontHeight.frontinches !== newheightAtFront.frontinches
      ) {
        handleTempFrontChange(newheightAtFront);
        onHeightChange({
          heightAtHouse: heightAtHouse,
          frontHeight: newheightAtFront,
        });
      }
    } else if (
      tempFrontHeight.frontfeet !== undefined &&
      tempFrontHeight.frontinches !== undefined
    ) {
      if (
        tempFrontHeight.frontfeet !== "" ||
        tempFrontHeight.frontinches !== ""
      ) {
        let newheightAtFront = {
          frontfeet:
            tempFrontHeight.frontfeet !== "" ? tempFrontHeight.frontfeet : "",
          frontinches:
            tempFrontHeight.frontinches !== ""
              ? tempFrontHeight.frontinches
              : "",
        };
        if (
          frontHeight.frontfeet !== newheightAtFront.frontfeet ||
          frontHeight.frontinches !== newheightAtFront.frontinches
        ) {
          onHeightChange({
            heightAtHouse: heightAtHouse,
            frontHeight: newheightAtFront,
          });
        }
      }
    }
  }, [
    frontHeight,
    frontmax,
    handleTempFrontChange,
    heightAtHouse,
    onHeightChange,
    roofstyle,
    setTempFrontHeight,
    tempFrontHeight,
  ]);

  useEffect(() => {
    let min = calculateFrontMinLimit(
      selectedSunRoomType,
      selectedTopTransomOption,
    );
    if (frontmin !== min) {
      handleSetFrontMin(min);
    }
  }, [
    selectedSunRoomType,
    selectedTopTransomOption,
    roofstyle,
    calculateFrontMinLimit,
    frontmin,
    handleSetFrontMin,
  ]);

  useEffect(() => {
    if (isSunRoom && roofstyle !== "CATHEDRAL") {
      if (
        sunroommessage !==
        `Height at front of ${selectedSunRoomType}.<br/>At front there is a minimum height of ${frontmin}' ${calculateFrontMinInchLimit(selectedSunRoomType, selectedTopTransomOption)}"<br/> and a maximum height of ${frontmax}' 11".`
      ) {
        handleSunroomMessage(
          `Height at front of ${selectedSunRoomType}.<br/>At front there is a minimum height of ${frontmin}' ${calculateFrontMinInchLimit(selectedSunRoomType, selectedTopTransomOption)}"<br/> and a maximum height of ${frontmax}' 11".`,
        );
      }
    }
    if (isSunRoom && roofstyle === "CATHEDRAL") {
      if (
        sunroommessage !==
        `Height at front of ${selectedSunRoomType}.<br/>At front there is a minimum height of ${frontmin}' ${calculateFrontMinInchLimit(selectedSunRoomType, selectedTopTransomOption)}"<br/> and a maximum height of ${frontmax}' 11".`
      ) {
        handleSunroomMessage(
          `Height at side of ${selectedSunRoomType}.<br/>At front there is a minimum height of ${frontmin}' ${calculateFrontMinInchLimit(selectedSunRoomType, selectedTopTransomOption)}"<br/> and a maximum height of ${frontmax}' 11".`,
        );
      }
    }
  }, [
    calculateFrontMinInchLimit,
    frontmax,
    frontmin,
    handleSunroomMessage,
    isSunRoom,
    roofstyle,
    selectedSunRoomType,
    selectedTopTransomOption,
    sunroommessage,
  ]);

  return (
    <div className="popupwindow heightatfront">
      <>
        {roofstyle !== "CATHEDRAL" && !isSunRoom && (
          <>
            {selectedAttachedOption !== "FREE-STANDING" && (
              <BoxHeader
                componentName="Height at Front"
                defaultMessage="Height at front of Pavilion.<br/>At front there is a minimum height of 5 feet<br/> and a maximum height of 14 feet."
              />
            )}
            {selectedAttachedOption === "FREE-STANDING" && (
              <BoxHeader
                componentName={"Shortest Height"}
                defaultMessage="Height at shortest side of pavilion.<br/>At shortest height there is a minimum height of 5 feet<br/> and maximum height of 14 feet."
              />
            )}
          </>
        )}
        {roofstyle !== "CATHEDRAL" && isSunRoom && (
          <BoxHeader
            componentName="Height at Front"
            defaultMessage={sunroommessage}
          />
        )}
        {roofstyle === "CATHEDRAL" && !isSunRoom && (
          <BoxHeader
            componentName="Side Height"
            defaultMessage="Height at side of pavilion.<br/> At side height there is a minimum height of 5 feet<br/> and maximum height of 14 feet."
          />
        )}
        {roofstyle === "CATHEDRAL" && isSunRoom && (
          <BoxHeader
            componentName="Side Height"
            defaultMessage={sunroommessage}
          />
        )}
      </>
      <div className="fontsizeforinputs">
        <span className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="redasterisk">&#10033;</span>
            Feet:
          </span>
          <NumericInputWithButtons
            id="frontHeightFeet"
            min={frontmin}
            key={Number(newKey)}
            max={frontmax}
            step={1}
            value={
              tempFrontHeight.frontfeet !== ""
                ? Number(tempFrontHeight.frontfeet)
                : undefined
            }
            onChange={(valueAsNumber) => {
              handleFrontHeightChange({
                target: {
                  name: "frontfeet",
                  value: valueAsNumber ? valueAsNumber.toString() : "",
                },
              });
            }}
            className={
              redBorderFields.includes("Front Height Frontfeet")
                ? "redBackground"
                : "whiteBackground"
            }
          />
        </span>
        <span className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="redasterisk">&#10033;</span>
            Inch:&nbsp;
          </span>
          <input
            id="frontHeightInches"
            name="frontinches"
            value={tempFrontHeight.frontinches}
            onChange={(e) => handleFrontHeightChange(e)}
            onBlur={(e) => handleFrontHeightInchesBlur(e)}
            className={
              redBorderFields.includes("Front Height Frontinches")
                ? "redBackground"
                : "whiteBackground"
            }
            style={{ width: "25%" }}
            disabled={
              roofstyle === "Existing Roof" &&
              tempFrontHeight.frontfeet === frontmax.toString()
            }
          />
        </span>
      </div>
    </div>
  );
};

FrontWallHeight.propTypes = {
  redBorderFields: PropTypes.array,
  roofstyle: PropTypes.string,
  onHeightChange: PropTypes.func,
  setFieldsWithRedBorder: PropTypes.func,
  selectedAttachedOption: PropTypes.string,
  selectedSunRoomType: PropTypes.string,
  selectedTopTransomOption: PropTypes.string,
  frontHeight: PropTypes.object,
  setFrontHeight: PropTypes.func,
  newKey: PropTypes.string,
  resetWallHeight: PropTypes.bool,
  setResetWallHeight: PropTypes.func,
  heightAtHouse: PropTypes.object,
  tempFrontHeight: PropTypes.object,
  setTempFrontHeight: PropTypes.func,
  isScreenRoom: PropTypes.bool,
  isSunRoom: PropTypes.bool,
};

export default FrontWallHeight;
