import React, { useState } from "react";
import StructuralBeamSelection from "./StructBeam";
import PropTypes from "prop-types";
import BoxHeader from "./BoxHeader";

const PostStyleSelection = ({
  handlePostStyleOptionChange,
  handlePostHeightOption,
  redBorderFields,
  setFieldsWithRedBorder,
  handleStructuralBeamOption,
  pavstyle,
  handleExteriorOptionChange,
  selectedExteriorOption,
}) => {
  const [inchesPostValue, setInchesPostValue] = useState("");
  const [selectedPostOption, setSelectedPostOption] = useState("");
  const messages = ["Roma-Fluted 4x4", "Florentine 6x6"];
  const Roma = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/Roma.jpg`;
  const Florentine = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/Florentine.jpg`;
  const images = [Roma, Florentine];

  const handlePostOptionChange = (selectedOption) => {
    setSelectedPostOption(selectedOption);
    handlePostStyleOptionChange(selectedOption);
    const updatedRedBorderFields = redBorderFields.includes("Post Style")
      ? selectedOption
        ? redBorderFields.filter((field) => field !== "Post Style")
        : redBorderFields
      : selectedOption
        ? redBorderFields
        : [...redBorderFields, "Post Style"];

    setFieldsWithRedBorder(updatedRedBorderFields);

    if (
      selectedOption === "NO COLUMNS NEEDED" ||
      selectedOption === "NO COLUMNS OR BEAMS NEEDED"
    ) {
      setInchesPostValue("");
      handlePostHeightOption("");
      handleStructuralBeamOption("");
    }
  };

  const handlePostInchesChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    const updatedRedBorderFields = redBorderFields.includes("post height")
      ? input
        ? redBorderFields.filter((field) => field !== "post height")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "post height"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    setInchesPostValue(input);
    handlePostHeightOption(input);
  };

  return (
    <div>
      <div className="poststyle-struct-height">
        <div className="popupwindow poststyle-choices">
          <BoxHeader
            redBorderFields={redBorderFields}
            componentName={"Post Style"}
            redFieldName={"Post Style"}
            imageMessages={messages}
            images={images}
          />
          <div>
            <div className="clickable-words">
              <div
                className={`clickable-word ${selectedPostOption === "ROMA" ? "clicked" : ""}`}
                onClick={() => handlePostOptionChange("ROMA")}
              >
                Roma-Fluted
              </div>
              <div
                className={`clickable-word ${selectedPostOption === "FLORENTINE" ? "clicked" : ""}`}
                onClick={() => handlePostOptionChange("FLORENTINE")}
              >
                Florentine
              </div>
            </div>
            <div className="clickable-words">
              <div
                className={`clickable-word ${selectedPostOption === "NO COLUMNS NEEDED" ? "clicked" : ""}`}
                onClick={() => handlePostOptionChange("NO COLUMNS NEEDED")}
              >
                No Posts
              </div>
              <div
                className={`clickable-word ${selectedPostOption === "NO COLUMNS OR BEAMS NEEDED" ? "clicked" : ""}`}
                onClick={() =>
                  handlePostOptionChange("NO COLUMNS OR BEAMS NEEDED")
                }
              >
                No&nbsp;Posts&nbsp;or&nbsp;Beams
              </div>
            </div>
          </div>
        </div>
        <div className="trim-louvers">
          {pavstyle !== "ST Thomas" &&
            selectedPostOption !== "NO COLUMNS OR BEAMS NEEDED" &&
            pavstyle !== "Santa FE" && (
              <StructuralBeamSelection
                redBorderFields={redBorderFields}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                handleStructuralBeamOption={handleStructuralBeamOption}
                selectedPostOption={selectedPostOption}
                handleExteriorOptionChange={handleExteriorOptionChange}
                selectedExteriorOption={selectedExteriorOption}
              />
            )}
          {(selectedPostOption === "FLORENTINE" ||
            selectedPostOption === "ROMA") && (
            <div className="popupwindow louvercolor-choices">
              <form
                id="postinches"
                className="structural-choices"
                onSubmit={(e) => e.preventDefault()} // Add this line
              >
                <BoxHeader
                  componentName="Post Height"
                  defaultMessage={
                    "We need the Height of the Roma-Fluted or Florentine Post, in inches."
                  }
                />
                <span className="fontsizeforinputsrow">
                  <span className="redasterisk">&#10033;</span>Inches:&nbsp;
                  <input
                    id="postheight"
                    className={
                      "input-box " +
                      (redBorderFields.includes("post height")
                        ? "redBackground"
                        : "whiteBackground")
                    }
                    value={inchesPostValue}
                    onChange={handlePostInchesChange}
                    maxLength={4}
                    pattern="\d*"
                  />
                </span>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PostStyleSelection.propTypes = {
  handlePostStyleOptionChange: PropTypes.func,
  handlePostHeightOption: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleStructuralBeamOption: PropTypes.func,
  pavstyle: PropTypes.string,
  handleExteriorOptionChange: PropTypes.func,
  selectedExteriorOption: PropTypes.string,
};

export default PostStyleSelection;
