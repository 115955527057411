import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const PavilionStyle = ({
  handlePavilionStyleOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedPavilionOption, setSelectedPavilionOption] = useState("");
  const TEMO_Panel = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/TEMO_Panel.JPG`;
  const ST_Thomas = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/STTHOMAS.jpg`;
  const Santa_FE = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/SANTAFE.jpg`;
  const images = [TEMO_Panel, ST_Thomas, Santa_FE];
  const messages = ["TEMO Panel", "ST Thomas", "Santa FE"];

  const handlePavilionOptionChange = (selected) => {
    const optionChange = selected;
    const updatedRedBorderFields = redBorderFields.includes("Pavilion Style")
      ? selected
        ? redBorderFields.filter((field) => field !== "Pavilion Style")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Pavilion Style"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    setSelectedPavilionOption(optionChange);
    handlePavilionStyleOptionChange(optionChange);
  };

  return (
    <div className="popupwindow pavilionstyle-choices">
      <BoxHeader
        componentName="Pavilion Style"
        redBorderFields={redBorderFields}
        redFieldName={"Pavilion Style"}
        images={images}
        imageMessages={messages}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedPavilionOption === "TEMO Panel" ? "clicked" : ""}`}
          onClick={() => handlePavilionOptionChange("TEMO Panel")}
        >
          TEMO Panel
        </div>
        <div
          className={`clickable-word ${selectedPavilionOption === "ST Thomas" ? "clicked" : ""}`}
          onClick={() => handlePavilionOptionChange("ST Thomas")}
        >
          ST Thomas
        </div>
        &nbsp;
        <div
          className={`clickable-word ${selectedPavilionOption === "Santa FE" ? "clicked" : ""}`}
          onClick={() => handlePavilionOptionChange("Santa FE")}
        >
          Santa FE
        </div>
      </div>
    </div>
  );
};

PavilionStyle.propTypes = {
  handlePavilionStyleOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default PavilionStyle;
