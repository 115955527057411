import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const BottomKPSelection = ({
  handleBottomKPChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [bottomKPWidthInchesOption, setBottomKPWidthInchesOption] =
    useState("");
  const [bottomKPWidthFeetOption, setBottomKPWidthFeetOption] = useState("");

  const handleBottomKPInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, "");

    const numericInput = parseFloat(input);
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11.999";
      } else {
        input = numericInput.toString();
      }
    }

    // If feet is 4, lock inches at 0
    if (bottomKPWidthFeetOption === "4") {
      input = "0";
    }

    setBottomKPWidthInchesOption(input);

    const updatedRedBorderFields = redBorderFields.includes("bottom kp inches")
      ? input
        ? redBorderFields.filter((field) => field !== "bottom kp inches")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "bottom kp inches"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleBottomKPChange(bottomKPWidthFeetOption, input, "width");
  };

  const handleBottomKPWidthChange = (event) => {
    let input = event.target.value.replace(/\D/g, "").slice(0, 3);
    const numericInput = parseInt(input, 10);

    if (!isNaN(numericInput) && numericInput > 4) {
      input = "4";
    }

    setBottomKPWidthFeetOption(input);

    // If feet is 4, lock inches at 0
    if (input === "4") {
      setBottomKPWidthInchesOption("0");
    }

    const updatedRedBorderFields = redBorderFields.includes("bottom kp feet")
      ? input
        ? redBorderFields.filter((field) => field !== "bottom kp feet")
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "bottom kp feet"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleBottomKPChange(input, bottomKPWidthInchesOption, "width");
  };

  useEffect(() => {
    handleBottomKPChange(
      bottomKPWidthFeetOption,
      bottomKPWidthInchesOption,
      "width",
    );
  }, [
    bottomKPWidthFeetOption,
    bottomKPWidthInchesOption,
    handleBottomKPChange,
  ]);

  return (
    <div style={{ flex: 1, marginLeft: "1%" }}>
      <div className="popupwindow">
        <BoxHeader
          componentName="Bottom KP"
          defaultMessage="Kick Panel on the bottom of the walls."
        />
        <div id="roofwidth" className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Feet:&nbsp;
            </span>
            <input
              id="widthfeet"
              className={
                redBorderFields.includes("bottom kp feet")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={bottomKPWidthFeetOption}
              onChange={handleBottomKPWidthChange}
              maxLength={3}
              pattern="\d*"
              style={{ width: "50%" }}
            />
          </span>
          <span className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Inch:&nbsp;
            </span>
            <input
              id="widthinches"
              className={
                redBorderFields.includes("bottom kp inches")
                  ? "redBackground"
                  : "whiteBackground"
              }
              value={bottomKPWidthInchesOption}
              onChange={handleBottomKPInchesChange}
              style={{ width: "50%" }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

BottomKPSelection.propTypes = {
  handleBottomKPChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default BottomKPSelection;
