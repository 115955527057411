import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../BoxHeader";

const RailBreezeway = ({
  redBorderFields,
  selectedBreezeWay,
  handleBreezeWayChange,
}) => {
  return (
    <div className="popupwindow hangingrailside">
      <BoxHeader
        componentName="Is it a Breeze Way?"
        redBorderFields={redBorderFields}
        redFieldName={"breeze way"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedBreezeWay === "Yes" ? "clicked" : ""}`}
          onClick={() => handleBreezeWayChange("Yes")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedBreezeWay === "No" ? "clicked" : ""}`}
          onClick={() => handleBreezeWayChange("No")}
        >
          No
        </div>
      </div>
    </div>
  );
};

RailBreezeway.propTypes = {
  redBorderFields: PropTypes.array,
  selectedBreezeWay: PropTypes.string,
  handleBreezeWayChange: PropTypes.func,
};

export default RailBreezeway;
