import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "./BoxHeader";

const FanBeam = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleFanBeamOption,
}) => {
  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    const updatedRedBorderFields = redBorderFields.includes("fan beam")
      ? selected
        ? redBorderFields.filter((field) => field !== "fan beam")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "fan beam"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleFanBeamOption(selected);
  };

  return (
    <div className="popupwindow fanbeam">
      <form>
        <BoxHeader
          componentName="Fan Beam"
          redBorderFields={redBorderFields}
          redFieldName="fan beam"
          defaultMessage="Temo does not provide fans or wiring.<br/>Temo does provide an aluminum beam inside a roof panel for the wiring."
        />
        <span className="fontsizeforinputs">
          <select
            id="fanbeam"
            className="input-box "
            style={{ width: "40%" }}
            onChange={handleDropdownChange}
          >
            <option value={""}>Select</option>
            {[...Array(11).keys()].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </span>
      </form>
    </div>
  );
};

FanBeam.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleFanBeamOption: PropTypes.func,
};

export default FanBeam;
