import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../../UniversalComponents/BoxHeader";

function DoorWidth({
  redBorderFields,
  index,
  doorWidth,
  handleDoorWidthChange,
  selectedPavilionLayoutOption,
  selectedBreezeWay,
  wallLengthsFeet,
  selectedRoomStyle,
  selectedSunRoomType,
  setNewDoorWidth,
  redFieldMessage,
  setSelectedSwingDoor,
  setSelectedPatioDoor,
}) {
  return (
    <label
      className="popupwindow"
      style={{
        width: "9vw",
        alignSelf: "center",
        height: "8vh",
        color: "black",
        fontSize: "1.4vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BoxHeader
        componentName="Door Width"
        redBorderFields={redBorderFields}
        redFieldName={redFieldMessage}
      />
      <select
        style={{
          cursor: "pointer",
          width: "80%",
          fontSize: "1.4vh",
          alignSelf: "center",
          border: "1px solid black",
        }}
        value={doorWidth[index]}
        onChange={(e) =>
          handleDoorWidthChange(
            e.target.value,
            index,
            doorWidth,
            setNewDoorWidth,
            redFieldMessage,
            setSelectedSwingDoor,
            setSelectedPatioDoor,
          )
        }
      >
        <option value="">Select...</option>
        <option value="3' Door">3&apos; Swing Door</option>

        {wallLengthsFeet[index] >= 6 && (
          <option value="5' Patio Door">5&apos; Patio Door</option>
        )}
        {wallLengthsFeet[index] >= 7 && (
          <option value="6' Patio Door">6&apos; Patio Door</option>
        )}
        {wallLengthsFeet[index] >= 9 && (
          <option value="8' Patio Door">8&apos; Patio Door</option>
        )}
        {index === 1 &&
          wallLengthsFeet[index] === 11 &&
          selectedRoomStyle !== "CATHEDRAL" &&
          selectedBreezeWay !== "Yes" &&
          selectedPavilionLayoutOption !== "3" &&
          selectedSunRoomType !== "Premier View" && (
            <option value="Two Door">Double Door</option>
          )}
        {index === 0 &&
          wallLengthsFeet[index] === 11 &&
          selectedRoomStyle !== "CATHEDRAL" &&
          selectedBreezeWay !== "Yes" &&
          selectedPavilionLayoutOption === "3" &&
          selectedSunRoomType !== "Premier View" && (
            <option value="Two Door">Double Door</option>
          )}
      </select>
    </label>
  );
}

DoorWidth.propTypes = {
  redBorderFields: PropTypes.array,
  index: PropTypes.number,
  doorWidth: PropTypes.array,
  handleDoorWidthChange: PropTypes.func,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  wallLengthsFeet: PropTypes.array,
  selectedRoomStyle: PropTypes.string,
  selectedSunRoomType: PropTypes.string,
  setNewDoorWidth: PropTypes.func,
  redFieldMessage: PropTypes.string,
  setSelectedSwingDoor: PropTypes.func,
  setSelectedPatioDoor: PropTypes.func,
};

export default DoorWidth;
