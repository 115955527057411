import React from "react";
import PropTypes from "prop-types";
import useButtonScrollability from "./Scrollable";

const ScrollableButton = ({ children, className, newStyle, ...props }) => {
  const { setButtonRef, isButtonScrollable } = useButtonScrollability();

  return (
    <button
      ref={setButtonRef}
      {...props}
      className={className}
      style={{
        overflow: "hidden",
        width: "16.5vw",
        ...newStyle,
      }}
    >
      <span className={`${isButtonScrollable ? "newScrollableForHeader" : ""}`}>
        {children}
      </span>
    </button>
  );
};

ScrollableButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  newStyle: PropTypes.object,
};

export default ScrollableButton;
