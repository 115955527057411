import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import NumericInputWithButtons from "../NumericInput";
import BoxHeader from "../BoxHeader";

const HeightAtHouse = ({
  redBorderFields,
  roofstyle,
  setFieldsWithRedBorder,
  selectedAttachedOption,
  selectedSunRoomType,
  selectedTopTransomOption,
  onHeightChange,
  newKey,
  frontHeight,
  heightAtHouse,
  tempHeightAtHouse,
  setTempHeightAtHouse,
  isScreenRoom,
  isSunRoom,
}) => {
  const [heightmin, setHeightmin] = useState(0);
  const [sunroommessage, setSunroommessage] = useState("");

  const handleSunroomMessage = useCallback((message) => {
    setSunroommessage(message);
  }, []);
  const handleTempHeightAtHouse = useCallback(
    (input) => {
      setTempHeightAtHouse(input);
    },
    [setTempHeightAtHouse],
  );
  const handleSetHeightMin = useCallback(
    (min) => {
      setHeightmin(min);
    },
    [setHeightmin],
  );

  const heightmax = !isSunRoom ? 15 : !isScreenRoom ? 12 : 10;

  const calculateHeightMinLimit = useCallback(
    (selectedSunRoomType, selectedTopTransomOption) => {
      let minLimit = 5;
      switch (selectedSunRoomType) {
        case "Solarium":
        case "Vista View":
          if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = 8;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = 9;
          } else {
            minLimit = 7;
          }
          break;
        case "Premier View":
          if (
            (selectedTopTransomOption === "TOP TRANSOMS-6" &&
              roofstyle !== "Existing Roof") ||
            selectedTopTransomOption === "TOP TRANSOMS-10" ||
            selectedTopTransomOption === "TOP TRANSOMS-12"
          ) {
            minLimit = 8;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = 9;
          } else {
            minLimit = 7;
          }
          break;
        case "Upper Deck":
          if (
            selectedTopTransomOption === "TOP TRANSOMS-6" ||
            selectedTopTransomOption === "TOP TRANSOMS-10"
          ) {
            minLimit = 8;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = 9;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = 10;
          } else {
            minLimit = 7;
          }
          break;
        case "Value View":
        case "Vinyl View":
          minLimit = 7;
          break;
        default:
          minLimit = 5;
      }
      return minLimit;
    },
    [roofstyle],
  );

  const handleHeightAtHouseChange = useCallback(
    (e) => {
      let inputName = e.target.name;
      let inputValue = e.target.value.replace(/[^\d.]/g, "");
      let numericInput = parseFloat(inputValue);
      let isWithinRange = true;
      let minLimit;
      let maxLimit = heightmax;
      setTimeout(() => {
        if (inputName === "heightfeet") {
          minLimit = calculateHeightMinLimit(
            selectedSunRoomType,
            selectedTopTransomOption,
            roofstyle,
          );

          if (!isNaN(numericInput)) {
            numericInput = Math.max(minLimit, Math.min(numericInput, maxLimit));
            inputValue = numericInput.toString();
            isWithinRange =
              numericInput >= minLimit && numericInput <= maxLimit;
          } else {
            isWithinRange = false;
            inputValue = "";
          }
        }

        setTempHeightAtHouse((prevHeight) => ({
          ...prevHeight,
          [inputName]: inputValue,
        }));
        const fieldName =
          "Height at House " +
          inputName.charAt(0).toUpperCase() +
          inputName.slice(1);
        const updatedRedBorderFields = isWithinRange
          ? redBorderFields.filter((field) => field !== fieldName)
          : redBorderFields.includes(fieldName)
            ? redBorderFields
            : [...redBorderFields, fieldName];

        setFieldsWithRedBorder(updatedRedBorderFields);
      }, 0);
    },
    [
      heightmax,
      setTempHeightAtHouse,
      redBorderFields,
      setFieldsWithRedBorder,
      calculateHeightMinLimit,
      selectedSunRoomType,
      selectedTopTransomOption,
      roofstyle,
    ],
  );

  const calculateHeightMinInchLimit = useCallback(
    (selectedSunRoomType, selectedTopTransomOption) => {
      let minLimit = 0;
      if (selectedSunRoomType === "Upper Deck") {
        if (tempHeightAtHouse.heightfeet === "7") {
          if (
            selectedTopTransomOption === "none" ||
            selectedTopTransomOption === ""
          ) {
            minLimit = roofstyle === "Existing Roof" ? 10 : 11;
          }
        } else if (tempHeightAtHouse.heightfeet === "8") {
          if (selectedTopTransomOption === "TOP TRANSOMS-6") {
            minLimit = roofstyle === "Existing Roof" ? 6 : 7;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-10") {
            minLimit = roofstyle === "Existing Roof" ? 10 : 11;
          }
        } else if (tempHeightAtHouse.heightfeet === "9") {
          if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = roofstyle === "Existing Roof" ? 0 : 1;
          }
        } else if (tempHeightAtHouse.heightfeet === "10") {
          if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = roofstyle === "Existing Roof" ? 0 : 1;
          }
        }
      } else if (
        selectedSunRoomType === "Vista View" ||
        selectedSunRoomType === "Solarium"
      ) {
        if (tempHeightAtHouse.heightfeet === "7") {
          if (selectedTopTransomOption === "none") {
            minLimit = roofstyle === "Existing Roof" ? 0 : 1;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-6") {
            minLimit = roofstyle === "Existing Roof" ? 6 : 7;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-10") {
            minLimit = roofstyle === "Existing Roof" ? 10 : 11;
          }
        } else if (tempHeightAtHouse.heightfeet === "8") {
          if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = roofstyle === "Existing Roof" ? 1 : 2;
          }
        } else if (tempHeightAtHouse.heightfeet === "9") {
          if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = roofstyle === "Existing Roof" ? 0 : 1;
          }
        }
      } else if (selectedSunRoomType === "Premier View") {
        if (tempHeightAtHouse.heightfeet === "7") {
          if (selectedTopTransomOption === "none") {
            minLimit = roofstyle === "Existing Roof" ? 0 : 4;
          }
          if (
            roofstyle === "Existing Roof" &&
            selectedTopTransomOption === "TOP TRANSOMS-6"
          ) {
            minLimit = 11;
          }
        } else if (tempHeightAtHouse.heightfeet === "8") {
          if (
            roofstyle !== "Existing Roof" &&
            selectedTopTransomOption === "TOP TRANSOMS-6"
          ) {
            minLimit = 0;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-10") {
            minLimit = roofstyle === "Existing Roof" ? 3 : 4;
          } else if (selectedTopTransomOption === "TOP TRANSOMS-12") {
            minLimit = roofstyle === "Existing Roof" ? 6 : 7;
          }
        } else if (tempHeightAtHouse.heightfeet === "9") {
          if (selectedTopTransomOption === "TOP TRANSOMS-24") {
            minLimit = roofstyle === "Existing Roof" ? 6 : 7;
          }
        }
      } else if (
        selectedSunRoomType === "Vinyl View" ||
        selectedSunRoomType === "Value View"
      ) {
        if (tempHeightAtHouse.heightfeet === "7") {
          minLimit = roofstyle === "Existing Roof" ? 3 : 4;
        }
      } else {
        minLimit = 0;
      }
      return minLimit;
    },
    [roofstyle, tempHeightAtHouse.heightfeet],
  );

  const handleHeightAtHouseInchesBlur = useCallback(
    (e) => {
      const inputName = e.target.name;
      let inputValue = e.target.value;
      let minLimit = 0;
      let maxLimit = 11;
      minLimit = calculateHeightMinInchLimit(
        selectedSunRoomType,
        selectedTopTransomOption,
      );
      let numericInput = parseFloat(inputValue);
      let isWithinRange;

      if (!isNaN(numericInput)) {
        numericInput = Math.max(minLimit, Math.min(numericInput, maxLimit));
        inputValue = numericInput.toString();
        isWithinRange = numericInput >= minLimit && numericInput <= maxLimit;
      } else {
        isWithinRange = false;
        inputValue = "";
      }

      const fieldName =
        "Height at House " +
        inputName.charAt(0).toUpperCase() +
        inputName.slice(1);

      const updatedRedBorderFields = isWithinRange
        ? redBorderFields.filter((field) => field !== fieldName)
        : redBorderFields.includes(fieldName)
          ? redBorderFields
          : [...redBorderFields, fieldName];

      setFieldsWithRedBorder(updatedRedBorderFields);

      setTempHeightAtHouse((prevHeight) => ({
        ...prevHeight,
        [inputName]: inputValue,
      }));
    },
    [
      calculateHeightMinInchLimit,
      redBorderFields,
      selectedSunRoomType,
      selectedTopTransomOption,
      setFieldsWithRedBorder,
      setTempHeightAtHouse,
    ],
  );

  useEffect(() => {
    if (
      tempHeightAtHouse.heightfeet === heightmax.toString() &&
      tempHeightAtHouse.heightinches !== "0"
    ) {
      let newheightAtHouse = {
        heightfeet: heightmax.toString(),
        heightinches: "0",
      };
      if (
        heightAtHouse.heightfeet !== newheightAtHouse.heightfeet ||
        heightAtHouse.heightinches !== newheightAtHouse.heightinches
      ) {
        handleTempHeightAtHouse(newheightAtHouse);
        onHeightChange({
          heightAtHouse: newheightAtHouse,
          frontHeight: frontHeight,
        });
      }
    } else if (
      tempHeightAtHouse.heightfeet !== undefined &&
      tempHeightAtHouse.heightinches !== undefined
    ) {
      if (
        tempHeightAtHouse.heightfeet !== "" ||
        tempHeightAtHouse.heightinches !== ""
      ) {
        let newheightAtHouse = {
          heightfeet:
            tempHeightAtHouse.heightfeet !== ""
              ? tempHeightAtHouse.heightfeet
              : "",
          heightinches:
            tempHeightAtHouse.heightinches !== ""
              ? tempHeightAtHouse.heightinches
              : "",
        };
        if (
          heightAtHouse.heightfeet !== newheightAtHouse.heightfeet ||
          heightAtHouse.heightinches !== newheightAtHouse.heightinches
        ) {
          onHeightChange({
            heightAtHouse: newheightAtHouse,
            frontHeight: frontHeight,
          });
        }
      }
    }
  }, [
    frontHeight,
    heightmax,
    heightAtHouse,
    onHeightChange,
    setTempHeightAtHouse,
    tempHeightAtHouse,
    handleTempHeightAtHouse,
  ]);

  useEffect(() => {
    let min = calculateHeightMinLimit(
      selectedSunRoomType,
      selectedTopTransomOption,
      roofstyle,
    );
    if (heightmin !== min) {
      handleSetHeightMin(min);
    }
  }, [
    selectedSunRoomType,
    selectedTopTransomOption,
    roofstyle,
    heightmin,
    calculateHeightMinLimit,
    handleSetHeightMin,
  ]);

  useEffect(() => {
    if (isSunRoom && roofstyle !== "CATHEDRAL") {
      let sunroomtype = selectedSunRoomType;
      if (selectedSunRoomType === "") {
        sunroomtype = "Vista View";
      }
      if (
        sunroommessage !==
        `Height at house of ${sunroomtype}.<br/>At front there is a minimum height of ${heightmin}' ${calculateHeightMinInchLimit(selectedSunRoomType, selectedTopTransomOption)}"<br/> and a maximum height of ${heightmax}' 0".`
      ) {
        handleSunroomMessage(
          `Height at house of ${sunroomtype}.<br/>At front there is a minimum height of ${heightmin}' ${calculateHeightMinInchLimit(selectedSunRoomType, selectedTopTransomOption)}"<br/> and a maximum height of ${heightmax}' 0".`,
        );
      }
    }
    if (isSunRoom && roofstyle === "CATHEDRAL") {
      let sunroomtype = selectedSunRoomType;
      if (selectedSunRoomType === "") {
        sunroomtype = "Vista View";
      }
      if (
        sunroommessage !==
        `Height at house of ${sunroomtype}.<br/>At front there is a minimum height of ${heightmin}' ${calculateHeightMinInchLimit(selectedSunRoomType, selectedTopTransomOption)}"<br/> and a maximum height of ${heightmax}' 0".`
      ) {
        handleSunroomMessage(
          `Height at peak of ${sunroomtype}.<br/>At front there is a minimum height of ${heightmin}' ${calculateHeightMinInchLimit(selectedSunRoomType, selectedTopTransomOption)}"<br/> and a maximum height of ${heightmax}' 0".`,
        );
      }
    }
  }, [
    isSunRoom,
    roofstyle,
    selectedSunRoomType,
    heightmin,
    calculateHeightMinInchLimit,
    selectedTopTransomOption,
    heightmax,
    sunroommessage,
    handleSunroomMessage,
  ]);

  return (
    <div className="popupwindow heightathouse">
      <>
        {roofstyle !== "CATHEDRAL" && !isSunRoom && (
          <>
            {selectedAttachedOption !== "FREE-STANDING" && (
              <BoxHeader
                componentName="Height at House"
                defaultMessage="Height at house side of Pavilion.<br/>At house there is a minimum height of 6 feet<br/> and maximum height of 15 feet."
              />
            )}
            {selectedAttachedOption === "FREE-STANDING" && (
              <BoxHeader
                componentName="Tallest Height"
                defaultMessage="Height at tallest side of Pavilion.<br/>At tallest side there is a minimum height of 6 feet<br/> and a maximum height of 15 feet."
              />
            )}
          </>
        )}
        {isSunRoom && roofstyle !== "CATHEDRAL" && (
          <BoxHeader
            componentName="Height at House"
            defaultMessage={sunroommessage}
          />
        )}
        {roofstyle === "CATHEDRAL" && !isSunRoom && (
          <BoxHeader
            componentName={"Peak Height"}
            defaultMessage={
              "Height at front peak of Pavilion.<br/>At front peak minimum height of 6 feet and maximum height of 15 feet."
            }
          />
        )}
        {isSunRoom && roofstyle === "CATHEDRAL" && (
          <BoxHeader
            componentName="Height at Peak"
            defaultMessage={sunroommessage}
          />
        )}
      </>
      <div className="fontsizeforinputs">
        <span className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="redasterisk">&#10033;</span>
            Feet:
          </span>
          <NumericInputWithButtons
            id="heightAtHouseFeet"
            key={newKey}
            min={heightmin}
            max={heightmax}
            step={1}
            value={
              tempHeightAtHouse.heightfeet.toString() !== ""
                ? Number(tempHeightAtHouse.heightfeet)
                : undefined
            }
            onChange={(valueAsNumber) => {
              handleHeightAtHouseChange({
                target: {
                  name: "heightfeet",
                  value: valueAsNumber ? valueAsNumber.toString() : "",
                },
              });
            }}
            className={
              redBorderFields.includes("Height at House Heightfeet")
                ? "redBackground"
                : "whiteBackground"
            }
          />
        </span>
        <span className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="redasterisk">&#10033;</span>
            Inch:&nbsp;
          </span>
          <input
            id="heightAtHouseInches"
            name="heightinches"
            value={tempHeightAtHouse.heightinches}
            onChange={(e) => handleHeightAtHouseChange(e)}
            className={
              redBorderFields.includes("Height at House Heightinches")
                ? "redBackground"
                : "whiteBackground"
            }
            style={{ width: "25%", color: "black" }}
            disabled={tempHeightAtHouse.heightfeet === heightmax.toString()}
            onBlur={(e) => handleHeightAtHouseInchesBlur(e)}
          />
        </span>
      </div>
    </div>
  );
};

HeightAtHouse.propTypes = {
  redBorderFields: PropTypes.array,
  roofstyle: PropTypes.string,
  onHeightChange: PropTypes.func,
  setFieldsWithRedBorder: PropTypes.func,
  selectedAttachedOption: PropTypes.string,
  selectedSunRoomType: PropTypes.string,
  selectedTopTransomOption: PropTypes.string,
  heightAtHouse: PropTypes.object,
  setHeightAtHouse: PropTypes.func,
  newKey: PropTypes.string,
  resetWallHeight: PropTypes.bool,
  setResetWallHeight: PropTypes.func,
  frontHeight: PropTypes.object,
  tempHeightAtHouse: PropTypes.object,
  setTempHeightAtHouse: PropTypes.func,
  isScreenRoom: PropTypes.bool,
  isSunRoom: PropTypes.bool,
};

export default HeightAtHouse;
