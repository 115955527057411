import React /*, { useEffect, useState, useContext }*/ from "react";
// import { useNavigate } from "react-router-dom";
// import SparePart from "../../../components/OrderingSystemComponents/SparePartsComponents/SparePart";
import PropTypes from "prop-types";
// import { CartContext } from "../../../components/OrderingSystemComponents/SparePartsComponents/CartContext";

const SpareParts = () => {
  // const [parts, setParts] = useState([]);
  // const { cart, setCart } = useContext(CartContext); // Use CartContext
  // const [query, setQuery] = useState("");
  // const [results, setResults] = useState([]);
  // const [page, setPage] = useState(1);

  // const handleSearch = async (e) => {
  //   setQuery(e.target.value);
  //   setPage(1); // Reset to first page on new search
  //   const response = await fetch(
  //     `/api/spareparts/search?query=${e.target.value}&page=1&pageSize=20`,
  //   );
  //   const data = await response.json();
  //   setResults(data);
  // };

  // useEffect(() => {
  //   fetch("/api/SQLThings/SpareParts")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const partsData = data.map((part) => ({
  //         name: typeof part.PartCode === "string" ? part.PartCode : "",
  //         price: typeof part.ItsPrice === "string" ? part.ItsPrice : "",
  //         image: typeof part.PartImage === "string" ? part.PartImage : "",
  //         category: typeof part.Uom === "string" ? part.Uom : "",
  //       }));
  //       setParts(partsData);
  //     });
  // }, []);

  // const handleNextPage = async () => {
  //   const nextPage = page + 1;
  //   setPage(nextPage);
  //   const response = await fetch(
  //     `/api/spareparts/search?query=${query}&page=${nextPage}&pageSize=20`,
  //   );
  //   const data = await response.json();
  //   setResults([...results, ...data]);
  // };

  // const handlePreviousPage = async () => {
  //   if (page > 1) {
  //     const prevPage = page - 1;
  //     setPage(prevPage);
  //     const response = await fetch(
  //       `/api/spareparts/search?query=${query}&page=${prevPage}&pageSize=20`,
  //     );
  //     const data = await response.json();
  //     setResults(data);
  //   }
  // };

  // const uniqueCategories = [
  //   ...new Set(
  //     parts.map((part) =>
  //       part.category !== null ? part.category : "No Category Available",
  //     ),
  //   ),
  // ];

  // // Filter the parts based on the selected filter and category

  // const handleAddToCart = (part) => {
  //   const existingPart = cart.find((item) => item.name === part.name);
  //   if (existingPart) {
  //     setCart(
  //       cart.map((item) =>
  //         item.name === part.name
  //           ? { ...item, quantity: item.quantity + 1 }
  //           : item,
  //       ),
  //     );
  //   } else {
  //     setCart([...cart, { ...part, quantity: 1 }]);
  //   }
  // };

  // const handleIncreaseQuantity = (part) => {
  //   setCart(
  //     cart.map((item) =>
  //       item.name === part.name
  //         ? { ...item, quantity: item.quantity + 1 }
  //         : item,
  //     ),
  //   );
  // };

  // const handleDecreaseQuantity = (part) => {
  //   setCart(
  //     cart
  //       .map((item) =>
  //         item.name === part.name
  //           ? { ...item, quantity: item.quantity > 1 ? item.quantity - 1 : 0 }
  //           : item,
  //       )
  //       .filter((item) => item.quantity > 0),
  //   );
  // };

  // const navigate = useNavigate();

  // const handleCheckout = () => {
  //   navigate("/cart");
  // };

  return (
    // <div className="pergola" style={{ overflow: "hidden" }}>
    //   <div
    //     style={{
    //       marginTop: "10vh",
    //       display: "grid",
    //       gridTemplateColumns: "1fr 1fr 1fr",
    //     }}
    //   >
    //     <h1 style={{ gridColumn: "2" }}>Spare Parts</h1>
    //     <button onClick={handleCheckout}>Checkout</button>
    //   </div>
    //   <div
    //     style={{
    //       height: "80vh",
    //       display: "grid",
    //       gridTemplateColumns: "1fr 9fr",
    //       overflow: "hidden",
    //     }}
    //   >
    //     <div
    //       style={{
    //         overflowY: "auto",
    //         overflowX: "hidden",
    //         height: "100%",
    //         width: "100%",
    //         backgroundColor: "white",
    //       }}
    //     >
    //       <input
    //         type="text"
    //         placeholder="Search parts..."
    //         value={query}
    //         onChange={handleSearch}
    //       />
    //       <div style={{ display: "flex", flexDirection: "column" }}>
    //         <button
    //           style={{
    //             border: "none",
    //             color: "green",
    //             borderBottom: "1px solid black",
    //           }}
    //         >
    //           All
    //         </button>
    //         {uniqueCategories.map((category, i) => (
    //           <button
    //             style={{
    //               border: "none",
    //               color: "green",
    //               borderBottom: "1px solid black",
    //             }}
    //             key={i}
    //           >
    //             {category}
    //           </button>
    //         ))}
    //       </div>
    //     </div>
    //     <div
    //       style={{
    //         display: "grid",
    //         gridTemplateColumns: "1fr 1fr 1fr 1fr",
    //         gridAutoRows: results.length > 4 ? "1fr" : "",
    //         gridTemplateRows: results.length <= 4 ? "1fr 1fr" : "",
    //         overflow: "auto",
    //       }}
    //     >
    //       {(query ? results : parts).map((part, i) => {
    //         const cartItem = cart.find((item) => item.name === part.name);
    //         return (
    //           <SparePart
    //             key={part.name !== "" ? part.name : "No Name Available"}
    //             partName={part.name !== "" ? part.name : "No Name Available"}
    //             partPrice={
    //               part.price !== "" ? part.price : "No Price Available"
    //             }
    //             partImage={part.image || ""}
    //             index={i}
    //             handleAddToCart={() => handleAddToCart(part)}
    //             part={part}
    //             quantity={cartItem ? cartItem.quantity : 0}
    //             handleIncreaseQuantity={() => handleIncreaseQuantity(part)}
    //             handleDecreaseQuantity={() => handleDecreaseQuantity(part)}
    //           />
    //         );
    //       })}
    //     </div>
    //   </div>
    //   {query && (
    //     <div>
    //       <button onClick={handlePreviousPage} disabled={page === 1}>
    //         Previous
    //       </button>
    //       <button onClick={handleNextPage}>Next</button>
    //     </div>
    //   )}
    // </div>
    <div>
      <div>
        <h1>Under Construction</h1>
      </div>
    </div>
  );
};

SpareParts.propTypes = {
  location: PropTypes.object,
};

export default SpareParts;
