import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const UniversalDrawingModal = ({
  selectedMessage,
  handleButtonClick,
  autoSelectedCodes,
  handleRadioChange,
  fetchedImages,
  selectedButtonIndex,
  index,
}) => {
  let radioButtonIndex = 0;

  const splitCodes = autoSelectedCodes.flatMap((subArray) =>
    subArray.flatMap((code) =>
      typeof code === "string" ? code.split(",") : [],
    ),
  );

  const combinedArray = splitCodes.map((code) => ({
    code,
    image: fetchedImages[code],
  }));

  const sortedCombinedArray = combinedArray.sort((a, b) => {
    const [baseA, suffixA] = a.code.match(/(\d+)([A-Z]*)/).slice();
    const [baseB, suffixB] = b.code.match(/(\d+)([A-Z]*)/).slice();

    const lastCharA = suffixA.slice(-1);
    const lastCharB = suffixB.slice(-1);

    const isLastCharANumber = !isNaN(Number(lastCharA));
    const isLastCharBNumber = !isNaN(Number(lastCharB));

    if (isLastCharANumber && !isLastCharBNumber) return -1;
    if (!isLastCharANumber && isLastCharBNumber) return 1;

    if (baseA === baseB) {
      return suffixA.localeCompare(suffixB);
    }
    return baseA - baseB;
  });

  const sortedCodes = sortedCombinedArray.map((item) => item.code);
  const sortedImages = sortedCombinedArray.map((item) => item.image);

  const groupedCodes = [];
  const groupedImages = [];
  for (let i = 0; i < sortedCodes.length; i += 3) {
    groupedCodes.push(sortedCodes.slice(i, i + 3));
    groupedImages.push(sortedImages.slice(i, i + 3));
  }

  return ReactDOM.createPortal(
    <div className="modal" style={{ zIndex: "500009" }}>
      <div
        className="modal-content"
        style={{
          width: "90%",
          height: "90%",
          boxSizing: "border-box",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {selectedMessage === null && (
          <button
            className="navbutton"
            style={{
              position: "absolute",
              top: "1%",
              right: "1%",
              cursor: "pointer",
              zIndex: "500010",
            }}
            onClick={handleButtonClick}
          >
            X
          </button>
        )}
        {selectedMessage !== null && (
          <button
            className="navbutton"
            style={{
              position: "absolute",
              top: "1%",
              right: "1%",
              cursor: "pointer",
              zIndex: "500010",
            }}
            onClick={handleButtonClick}
          >
            Done
          </button>
        )}
        <div
          className="image-container2"
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: sortedCodes.length > 3 ? "30%" : "10vh",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {groupedCodes.map((row, rowIndex) => (
            <div
              key={rowIndex}
              style={{ display: "flex", flexDirection: "row" }}
            >
              {row.map((code, radioIndex) => {
                const currentRadioButtonIndex = radioButtonIndex;
                radioButtonIndex++;
                return (
                  <button
                    key={`${rowIndex}-${radioIndex}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "fit-content",
                      height: "fit-content",
                      border: "none",
                      background: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={() =>
                      handleRadioChange(currentRadioButtonIndex, code)
                    }
                  >
                    <div style={{ position: "relative" }}>
                      {currentRadioButtonIndex ===
                        selectedButtonIndex[index] && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(173, 216, 230, 0.5)",
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                        src={groupedImages[rowIndex][radioIndex]}
                        alt={code}
                      />
                    </div>
                    <br></br>
                    <p>{code}</p>
                  </button>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.getElementById("modal-root"),
  );
};

UniversalDrawingModal.propTypes = {
  selectedMessage: PropTypes.any,
  handleButtonClick: PropTypes.func.isRequired,
  autoSelectedCodes: PropTypes.array.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  fetchedImages: PropTypes.object.isRequired,
  selectedButtonIndex: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

export default UniversalDrawingModal;
