import React from "react";
import PropTypes from "prop-types";
import InfoIcon from "./TemplateComponents/InfoIcon";
import ImageButton from "./TemplateComponents/ImageButton";
import useButtonScrollability from "./TemplateComponents/Scrollable";
const BoxHeader = ({
  redBorderFields,
  redFieldName,
  defaultMessage,
  images,
  imageMessages,
  componentName,
}) => {
  const { setButtonRef, isButtonScrollable } = useButtonScrollability();

  return (
    <h6
      className={
        "headers " +
        (redBorderFields && redBorderFields.includes(redFieldName)
          ? "redBackground"
          : "greenBackground")
      }
    >
      <span
        className="header-grid-location"
        style={!images && !defaultMessage ? { justifyContent: "center" } : {}}
      >
        <span
          style={{
            width: "90%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          ref={setButtonRef}
        >
          <span className={isButtonScrollable ? "newScrollableForHeader" : ""}>
            <span className="redasterisk">&#10033;</span>
            {componentName}
          </span>
        </span>
        {defaultMessage && <InfoIcon defaultMessage={defaultMessage} />}
        {images && <ImageButton images={images} messages={imageMessages} />}
      </span>
    </h6>
  );
};

BoxHeader.propTypes = {
  redBorderFields: PropTypes.array,
  redFieldName: PropTypes.string,
  defaultMessage: PropTypes.string,
  images: PropTypes.array,
  imageMessages: PropTypes.array,
  componentName: PropTypes.string,
};

export default BoxHeader;
