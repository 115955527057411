import React from "react";
import InfoIcon from "../../OrderingSystemComponents/UniversalComponents/TemplateComponents/InfoIcon";
import ImageButton from "../../OrderingSystemComponents/UniversalComponents/TemplateComponents/ImageButton";
import ImageButtonImage from "../../OrderingSystemComponents/UniversalComponents/TemplateComponents/view-image-photo-icon.png";

const PlacingOrderPage = () => (
  <div className="placing-order-container">
    <h1>Placing an Order</h1>
    <div className="placing-order-section">
      <h2>Make an Order</h2>
      <p>
        When you click “Make an order”, you will choose the type of TEMO product
        you wish to order. Currently, you can select from:
      </p>
    </div>
    <div className="placing-order-section">
      <h3>3 5/8&quot; Sunroom Choices</h3>
      <p>Vista View, Premier View, Upper Deck, and Solarium.</p>
    </div>
    <div className="placing-order-section">
      <h3>2&quot; Sunroom Choices</h3>
      <p>Value View, Vinyl View, and Linear Screen.</p>
    </div>
    <div className="placing-order-section">
      <h3>Pergola Choices</h3>
      <p>Operable, Fixed, and Motor City.</p>
    </div>
    <div className="placing-order-section">
      <h3>Pavilion Choices</h3>
      <p>St Thomas, Santa Fe, and TEMO Panel.</p>
    </div>
    <div
      className="placing-order-section"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      Please hover the Tool Tip here:&nbsp;
      <span style={{ zIndex: "5000", width: "fit-content" }}>
        <InfoIcon
          defaultMessage={
            "This will hold any relative information in the box it sits in across the website."
          }
        />
      </span>
    </div>
    <div
      className="placing-order-section"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      Please click this here:&nbsp;
      <span style={{ zIndex: "5000", width: "fit-content" }}>
        <ImageButton
          images={[ImageButtonImage]}
          messages={[
            "When you click the button you just clicked,<br /> it will open a window with images for the section it resides in.",
          ]}
        />
      </span>
    </div>
  </div>
);

export default PlacingOrderPage;
