import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const SkyviewSelection = ({
  handleSelectedSkyviewChoice,
  redBorderFields,
  setFieldsWithRedBorder,
  handleSelectedLEDLightChoice,
  handleSelectedCleatsOrBond,
  handleDimensionChange,
  handleShingleChange,
  selectedShingle,
  setSelectedSkyviewChoice,
  selectedSkyviewChoice,
}) => {
  const Skyview = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/SkyView.JPG`;
  const SkyviewNo = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/NoSkyview.jpg`;
  const images = [Skyview, SkyviewNo];
  const messages = ["Skyview", "No Skyview"];
  const [skyviewonce, setSkyviewonce] = useState(true);

  const handleSkyviewOptionChange = (selected) => {
    setSelectedSkyviewChoice(selected);
    const updatedRedBorderFields = redBorderFields.includes("Skyview YES or NO")
      ? selected
        ? redBorderFields.filter((field) => field !== "Skyview YES or NO")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Skyview YES or NO"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedSkyviewChoice(selected);
    if (selected === "Skyview YES") {
      handleSelectedCleatsOrBond("");
      handleDimensionChange("");
      handleShingleChange("NO");
    }
    if (selected === "Skyview NO") {
      handleSelectedLEDLightChoice("");
      handleDimensionChange("");
    }
  };

  useEffect(() => {
    if (selectedShingle === "YES") {
      setSelectedSkyviewChoice("Skyview NO");
      handleSelectedSkyviewChoice("Skyview NO");
    } else if (selectedSkyviewChoice === "Skyview YES") {
      handleShingleChange("NO");
    }
  }, [
    handleShingleChange,
    handleSelectedSkyviewChoice,
    selectedShingle,
    selectedSkyviewChoice,
    setSelectedSkyviewChoice,
  ]);

  useEffect(() => {
    if (skyviewonce === true) {
      setSelectedSkyviewChoice("");
      handleSelectedSkyviewChoice("");
      setSkyviewonce(false);
    }
  }, [handleSelectedSkyviewChoice, skyviewonce, setSelectedSkyviewChoice]);

  return (
    <div className="popupwindow skylightselection">
      <BoxHeader
        componentName="Skyview"
        redBorderFields={redBorderFields}
        redFieldName={"Skyview YES or NO"}
        images={images}
        imageMessages={messages}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedSkyviewChoice === "Skyview YES" ? "clicked" : ""}`}
          onClick={() => handleSkyviewOptionChange("Skyview YES")}
        >
          YES
        </div>
        <div
          className={`clickable-word ${selectedSkyviewChoice === "Skyview NO" ? "clicked" : ""}`}
          onClick={() => handleSkyviewOptionChange("Skyview NO")}
        >
          NO
        </div>
      </div>
    </div>
  );
};

SkyviewSelection.propTypes = {
  handleSelectedSkyviewChoice: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleSelectedLEDLightChoice: PropTypes.func,
  handleSelectedCleatsOrBond: PropTypes.func,
  handleDimensionChange: PropTypes.func,
  handleShingleChange: PropTypes.func,
  selectedShingle: PropTypes.string,
  setSelectedSkyviewChoice: PropTypes.func,
  selectedSkyviewChoice: PropTypes.string,
};

export default SkyviewSelection;
