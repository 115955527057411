import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "./BoxHeader";

const ValueSeries = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleValueSeriesOption,
  fasciaTrimOption,
  setFasciaTrimOption,
}) => {
  const [selectedValueSeries, setValueSeries] = useState("");

  const handleValueSeries = (selected) => {
    setValueSeries(selected);
    const updatedRedBorderFields = redBorderFields.includes("Value Series")
      ? selected
        ? redBorderFields.filter((field) => field !== "Value Series")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Value Series"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleValueSeriesOption(selected);
    if (selected === "YES" && fasciaTrimOption === "bronze") {
      setFasciaTrimOption("");
    }
  };

  return (
    <div className="popupwindow value-roof">
      <BoxHeader
        componentName="Value Series"
        redBorderFields={redBorderFields}
        redFieldName="Value Series"
        defaultMessage={
          "Value View Fascia means NO Gutter or Downspout Kit.<br/>You will recieve Value View Fascia in place of gutter.<br/>If Yes is selected Fascia Trim Color cannot be Bronze."
        }
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedValueSeries === "YES" ? "clicked" : ""}`}
          onClick={() => handleValueSeries("YES")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedValueSeries === "NO" ? "clicked" : ""}`}
          onClick={() => handleValueSeries("NO")}
        >
          No
        </div>
      </div>
    </div>
  );
};

ValueSeries.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleValueSeriesOption: PropTypes.func,
  fasciaTrimOption: PropTypes.string,
  setFasciaTrimOption: PropTypes.func,
};

export default ValueSeries;
