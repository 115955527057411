import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const TopTransoms = ({
  selectedTopTransomOption,
  handleTopTransomOptionChange,
  redBorderFields,
}) => {
  return (
    <div className="popupwindow" style={{ width: "100%" }}>
      <BoxHeader
        componentName="Top Transom"
        defaultMessage="Rectangular factory transoms that go at the top of your wall."
        redBorderFields={redBorderFields}
        redFieldName={"Top Transom Option"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedTopTransomOption === "TOP TRANSOMS-6" ? "clicked" : ""}`}
          onClick={() => handleTopTransomOptionChange("TOP TRANSOMS-6")}
        >
          6in
        </div>
        <div
          className={`clickable-word ${selectedTopTransomOption === "TOP TRANSOMS-10" ? "clicked" : ""}`}
          onClick={() => handleTopTransomOptionChange("TOP TRANSOMS-10")}
        >
          10in
        </div>
        &nbsp;
        <div
          className={`clickable-word ${selectedTopTransomOption === "TOP TRANSOMS-12" ? "clicked" : ""}`}
          onClick={() => handleTopTransomOptionChange("TOP TRANSOMS-12")}
        >
          12in
        </div>
        &nbsp;
        <div
          className={`clickable-word ${selectedTopTransomOption === "TOP TRANSOMS-24" ? "clicked" : ""}`}
          onClick={() => handleTopTransomOptionChange("TOP TRANSOMS-24")}
        >
          24in
        </div>
        <div
          className={`clickable-word ${selectedTopTransomOption === "none" ? "clicked" : ""}`}
          style={{ marginLeft: "1%" }}
          onClick={() => handleTopTransomOptionChange("none")}
        >
          None
        </div>
      </div>
    </div>
  );
};

TopTransoms.propTypes = {
  selectedTopTransomOption: PropTypes.string,
  handleTopTransomOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
};

export default TopTransoms;
