import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import WallLength from "../UniversalWalls/WallLength";
import DoorOption from "../UniversalWalls/DoorOption";
import ExistDoorWidth from "./ExistDoorWidth";
import SwingDoorOrientation from "../UniversalWalls/SwingDoorOrientation";
import PatioDoorOrientation from "../UniversalWalls/PatioDoorOrientation";
import StandardExistModal from "./StandardExistModal";
import PremierViewExistDrawingModal from "./PremierViewExistDrawingModal";
import ValueViewExistModal from "../../../ScreenRoomsPageComponents/2INExistRoof/GlassExistModal";
import VinylViewExistModal from "../../../ScreenRoomsPageComponents/2INExistRoof/VinylExistModal";
import LineardoorLocations from "../../../ScreenRoomsPageComponents/DoorLocation";

const SingleWallSelection = ({
  setOpenSingleDrawing,
  dimension,
  wallLengthsFeet,
  wallLengthsInches,
  redBorderFields,
  index,
  handleWallLengthFeetChange,
  handleWallLengthInchesChange,
  setWallLengthsFeet,
  setWallLengthsInches,
  wallLengthMessage,
  wallLengthInchesMessage,
  handleDoorOptionChange,
  doorOption,
  setDoorOption,
  doorWidth,
  setDoorWidth,
  doorOptionMessage,
  lastWallLengthUpdate,
  setLastWallLengthUpdate,
  handleDoorWidthChange,
  setNewDoorWidth,
  redFieldMessage,
  isFront,
  selectedSunRoomType,
  selectedSwingDoorOrientation,
  selectedPatioDoorOrientation,
  setSelectedPatioDoorOrientation,
  setSelectedSwingDoorOrientation,
  setFieldsWithRedBorder,
  redPatioFieldMessage,
  redSwingFieldMessage,
  redWallFieldMessage,
  setNewSelectedCodes,
  initialSelectedCodes,
  setSelectedImages,
  selectedButtonIndex,
  setSelectedButtonIndex,
  newIndex,
  selectedImages,
  handleCodeSelect,
  isAngled,
  isScreenRoom,
  setSelectedLocations,
  selectedLocations,
  handleDoorLocationChange,
  selectedBreezeWay,
  selectedPavilionLayoutOption,
  handleSelectedPatioDoorOrientation,
  currentImage,
  handleSwingDoorOrientation,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleViewDrawingsClick = () => {
    setIsModalOpen(true);
  };
  const handleButtonClick = () => {
    setOpenSingleDrawing(false);
  };
  const [showSwingDoorOrientation, setshowSwingDoorOrientation] =
    useState(false);
  const [showPatioDoorOrientation, setShowPatioDoorOrientation] =
    useState(false);

  const handleShowSwingDoorOrientationClick = () => {
    setshowSwingDoorOrientation(true);
  };
  const handleShowPatioDoorOrientationClick = () => {
    setShowPatioDoorOrientation(true);
  };

  function getButtonClassName(index, doorWidth, redBorderFields) {
    const baseClass = "navbutton";
    let conditionClass = "";
    if (doorWidth[index] === "3' Door") {
      conditionClass = redBorderFields.includes(`${redSwingFieldMessage}`)
        ? "redBackground"
        : "greenBackground";
    } else {
      conditionClass = redBorderFields.includes(`${redPatioFieldMessage}`)
        ? "redBackground"
        : "greenBackground";
    }
    return `${baseClass} ${conditionClass}`;
  }

  useEffect(() => {
    if (
      wallLengthsFeet[index] < 4 &&
      selectedSunRoomType !== "Premier View" &&
      wallLengthsFeet[index] !== ""
    ) {
      doorOption[index] = "No";
    }
    if (
      wallLengthsFeet[index] < 5 &&
      selectedSunRoomType === "Premier View" &&
      wallLengthsFeet[index] !== ""
    ) {
      doorOption[index] = "No";
    }
  }, [doorOption, index, selectedSunRoomType, wallLengthsFeet]);

  return ReactDOM.createPortal(
    <>
      <div
        style={{
          zIndex: 2000,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      />
      <div className="walls-under-modal-content">
        <button
          className="navbutton"
          style={{
            position: "absolute",
            top: "1%",
            right: "1%",
            cursor: "pointer",
            zIndex: 200,
          }}
          onClick={handleButtonClick}
        >
          X
        </button>
        {selectedSunRoomType !== "Linear Screen" && (
          <div
            className="popupwindow"
            style={{
              width: "fit-content",
              height: "fit-content",
              marginBottom: "3%",
              marginTop: "3%",
              backgroundColor: "transparent",
            }}
          >
            <img
              src={selectedImages[index]}
              alt="Selected"
              style={{
                height: `${dimension * 0.35}px`,
                maxWidth: `${dimension * 0.35}px`,
                maxHeight: "16vw",
                backgroundColor: "transparent",
              }}
            />
          </div>
        )}

        {!isAngled && (
          <WallLength
            wallLengthsFeet={wallLengthsFeet}
            wallLengthsInches={wallLengthsInches}
            redBorderFields={redBorderFields}
            index={index}
            handleWallLengthFeetChange={handleWallLengthFeetChange}
            handleWallLengthInchesChange={handleWallLengthInchesChange}
            setWallLengthsFeet={setWallLengthsFeet}
            setWallLengthsInches={setWallLengthsInches}
            wallLengthFeetMessage={wallLengthMessage}
            lastWallLengthUpdate={lastWallLengthUpdate}
            setLastWallLengthUpdate={setLastWallLengthUpdate}
            wallLengthInchesMessage={wallLengthInchesMessage}
            setSelectedPatioDoor={setSelectedPatioDoorOrientation}
            setSelectedSwingDoor={setSelectedSwingDoorOrientation}
          />
        )}
        {isAngled && (
          <div>
            Wall Length : {wallLengthsFeet[index]}
            {"'"}
            {wallLengthsInches[index]}
            {'"'}
          </div>
        )}
        <div
          style={{
            backgroundColor: "transparent",
            width: "50%",
            marginTop: "2%",
            marginBottom: "15%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "repeat(2, 1fr)",
            height: "20vh",
            justifyItems: "center",
            columnGap: "5%",
            rowGap: "5%",
          }}
        >
          {(selectedSunRoomType !== "Premier View"
            ? wallLengthsFeet[index] >= 4
            : wallLengthsFeet[index] >= 5) && (
            <DoorOption
              handleDoorOptionChange={handleDoorOptionChange}
              redBorderFields={redBorderFields}
              doorOption={doorOption}
              index={index}
              setDoorOption={setDoorOption}
              doorWidth={doorWidth}
              setDoorWidth={setDoorWidth}
              doorOptionMessage={doorOptionMessage}
              setSelectedSwingDoorOrientation={setSelectedSwingDoorOrientation}
              setSelectedPatioDoorOrientation={setSelectedPatioDoorOrientation}
            />
          )}
          {selectedSunRoomType === "Linear Screen" &&
            doorOption[index] === "Yes" && (
              <LineardoorLocations
                redBorderFields={redBorderFields}
                index={index}
                selectedLocations={selectedLocations}
                redFieldMessage={redWallFieldMessage}
                setSelectedLocations={setSelectedLocations}
                handleDoorLocationChange={handleDoorLocationChange}
                selectedBreezeWay={selectedBreezeWay}
                selectedPavilionLayoutOption={selectedPavilionLayoutOption}
                isFront={isFront}
              />
            )}
          {doorOption[index] === "Yes" && !isScreenRoom && (
            <ExistDoorWidth
              redBorderFields={redBorderFields}
              index={index}
              doorWidth={doorWidth}
              wallLengthsFeet={wallLengthsFeet}
              handleDoorWidthChange={handleDoorWidthChange}
              setNewDoorWidth={setNewDoorWidth}
              redFieldMessage={redFieldMessage}
              selectedSunRoomType={selectedSunRoomType}
              setSelectedSwingDoor={setSelectedSwingDoorOrientation}
              setSelectedPatioDoor={setSelectedPatioDoorOrientation}
            />
          )}
          {((doorOption[index] === "Yes" &&
            doorWidth[index] !== "" &&
            (doorWidth[index] === "3' Door" ||
              doorWidth[index] === "3.5' Door") &&
            !showSwingDoorOrientation) ||
            (!isScreenRoom &&
              doorWidth[index] &&
              doorWidth[index] !== "3' Door" &&
              doorWidth[index] !== "3.5' Door" &&
              !showPatioDoorOrientation)) && (
            <button
              className={getButtonClassName(index, doorWidth, redBorderFields)}
              style={{
                fontSize: "1.4vh",
                height: "8vh",
                width: "9vw",
                padding: "5%",
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={
                doorWidth[index] === "3' Door" ||
                doorWidth[index] === "3.5' Door"
                  ? handleShowSwingDoorOrientationClick
                  : handleShowPatioDoorOrientationClick
              }
            >
              {(doorWidth[index] === "3' Door" ||
              doorWidth[index] === "3.5' Door"
                ? !selectedSwingDoorOrientation[index]
                : !selectedPatioDoorOrientation[index]) && (
                <span>
                  Door Orientation <br />
                  Options
                </span>
              )}
              {(doorWidth[index] === "3' Door" ||
              doorWidth[index] === "3.5' Door"
                ? selectedSwingDoorOrientation[index]
                : selectedPatioDoorOrientation[index]) && (
                <span>
                  You Selected <br />
                  {doorWidth[index] === "3' Door" ||
                  doorWidth[index] === "3.5' Door"
                    ? selectedSwingDoorOrientation[index]
                    : selectedPatioDoorOrientation[index]}
                  <br />
                </span>
              )}
            </button>
          )}
          {wallLengthsFeet[index] &&
            wallLengthsInches[index] &&
            ((wallLengthsFeet[index] < 4 &&
              selectedSunRoomType !== "Premier View") ||
              (wallLengthsFeet[index] < 5 &&
                selectedSunRoomType === "Premier View") ||
              (doorOption[index] &&
                (doorOption[index] === "No" || doorWidth[index] !== ""))) &&
            selectedSunRoomType !== "Linear Screen" && (
              <button
                className={`navbutton ${redBorderFields.includes(`${redWallFieldMessage}`) ? "redBackground" : "greenBackground"}`}
                style={{
                  fontSize: "1.4vh",
                  height: "8vh",
                  width: "9vw",
                  padding: "5%",
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleViewDrawingsClick()}
              >
                View&nbsp;Drawings
              </button>
            )}
          {isModalOpen &&
            (selectedSunRoomType === "Upper Deck" ||
              selectedSunRoomType === "Vista View") && (
              <StandardExistModal
                setIsModalOpen={setIsModalOpen}
                index={index}
                doorOption={doorOption}
                doorWidth={doorWidth}
                onCodeSelect={handleCodeSelect}
                setSelectedImages={setSelectedImages}
                selectedButtonIndex={selectedButtonIndex}
                setSelectedButtonIndex={setSelectedButtonIndex}
                wallLengthsFeet={wallLengthsFeet}
                isFront={isFront}
                setNewSelectedCodes={setNewSelectedCodes}
                initialSelectedCodes={initialSelectedCodes}
                redBorderField={redWallFieldMessage}
              />
            )}

          {isModalOpen && selectedSunRoomType === "Premier View" && (
            <PremierViewExistDrawingModal
              setIsModalOpen={setIsModalOpen}
              index={index}
              doorOption={doorOption}
              doorWidth={doorWidth}
              onCodeSelect={handleCodeSelect}
              setSelectedImages={setSelectedImages}
              selectedButtonIndex={selectedButtonIndex}
              setSelectedButtonIndex={setSelectedButtonIndex}
              wallLengthsFeet={wallLengthsFeet}
              isFront={isFront}
              setNewSelectedCodes={setNewSelectedCodes}
              initialSelectedCodes={initialSelectedCodes}
              redBorderField={redWallFieldMessage}
            />
          )}
          {isModalOpen && selectedSunRoomType === "Value View" && (
            <ValueViewExistModal
              setIsModalOpen={setIsModalOpen}
              index={index}
              doorOption={doorOption}
              doorWidth={doorWidth}
              onCodeSelect={handleCodeSelect}
              setSelectedImages={setSelectedImages}
              selectedButtonIndex={selectedButtonIndex}
              setSelectedButtonIndex={setSelectedButtonIndex}
              wallLengthsFeet={wallLengthsFeet}
              isFront={isFront}
              setNewSelectedCodes={setNewSelectedCodes}
              initialSelectedCodes={initialSelectedCodes}
              redBorderField={redWallFieldMessage}
            />
          )}
          {isModalOpen && selectedSunRoomType === "Vinyl View" && (
            <VinylViewExistModal
              setIsModalOpen={setIsModalOpen}
              index={index}
              doorOption={doorOption}
              doorWidth={doorWidth}
              onCodeSelect={handleCodeSelect}
              setSelectedImages={setSelectedImages}
              selectedButtonIndex={selectedButtonIndex}
              setSelectedButtonIndex={setSelectedButtonIndex}
              wallLengthsFeet={wallLengthsFeet}
              isFront={isFront}
              setNewSelectedCodes={setNewSelectedCodes}
              initialSelectedCodes={initialSelectedCodes}
              redBorderField={redWallFieldMessage}
            />
          )}
          {showSwingDoorOrientation && (
            <SwingDoorOrientation
              setSelectedSwingDoorOrientation={setSelectedSwingDoorOrientation}
              index={index}
              setshowSwingDoorOrientation={setshowSwingDoorOrientation}
              selectedSwingDoorOrientation={selectedSwingDoorOrientation}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              newIndex={newIndex}
              redFieldMessage={redSwingFieldMessage}
              handleSwingDoorOrientation={handleSwingDoorOrientation}
            />
          )}
          {showPatioDoorOrientation && (
            <PatioDoorOrientation
              index={index}
              setShowPatioDoorOrientation={setShowPatioDoorOrientation}
              selectedPatioDoorOrientation={selectedPatioDoorOrientation}
              setSelectedPatioDoorOrientation={setSelectedPatioDoorOrientation}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
              redFieldMessage={redPatioFieldMessage}
              handleSelectedPatioDoorOrientation={
                handleSelectedPatioDoorOrientation
              }
              currentImage={currentImage}
            />
          )}
        </div>
      </div>
    </>,
    document.getElementById("modal-root"),
  );
};

SingleWallSelection.propTypes = {
  setOpenSingleDrawing: PropTypes.func,
  dimension: PropTypes.number,
  wallLengthsFeet: PropTypes.array,
  wallLengthsInches: PropTypes.array,
  redBorderFields: PropTypes.array,
  index: PropTypes.number,
  lastWallLengthUpdate: PropTypes.number,
  setLastWallLengthUpdate: PropTypes.func,
  handleWallLengthFeetChange: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  setWallLengthsFeet: PropTypes.func,
  setWallLengthsInches: PropTypes.func,
  wallLengthMessage: PropTypes.string,
  handleDoorOptionChange: PropTypes.func,
  doorOption: PropTypes.array,
  setDoorOption: PropTypes.func,
  doorWidth: PropTypes.array,
  setDoorWidth: PropTypes.func,
  doorOptionMessage: PropTypes.string,
  handleDoorWidthChange: PropTypes.func,
  setNewDoorWidth: PropTypes.func,
  redFieldMessage: PropTypes.string,
  wallLengthInchesMessage: PropTypes.string,
  isFront: PropTypes.bool,
  selectedSunRoomType: PropTypes.string,
  setFieldsWithRedBorder: PropTypes.func,
  selectedSwingDoorOrientation: PropTypes.array,
  selectedPatioDoorOrientation: PropTypes.array,
  setSelectedPatioDoorOrientation: PropTypes.func,
  setSelectedSwingDoorOrientation: PropTypes.func,
  redPatioFieldMessage: PropTypes.string,
  redSwingFieldMessage: PropTypes.string,
  redWallFieldMessage: PropTypes.string,
  selectedCodes: PropTypes.array,
  setSelectedCodes: PropTypes.func,
  setSelectedImages: PropTypes.func,
  selectedButtonIndex: PropTypes.array,
  setSelectedButtonIndex: PropTypes.func,
  newIndex: PropTypes.number,
};

export default SingleWallSelection;
