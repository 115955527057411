import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const ScreenRoom = ({
  handleScreenRoomChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [screenRoomOption, SetScreenRoomOption] = useState("");

  const handleScreenRoomOptionChange = (pickedoption) => {
    SetScreenRoomOption(pickedoption);
    const updatedRedBorderFields = redBorderFields.includes("Screen Room")
      ? pickedoption
        ? redBorderFields.filter((field) => field !== "Screen Room")
        : redBorderFields
      : pickedoption
        ? redBorderFields
        : [...redBorderFields, "Screen Room"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleScreenRoomChange(pickedoption);
  };

  return (
    <div className="popupwindow bihex-stuff" style={{ marginTop: "1%" }}>
      <BoxHeader
        componentName="Screen Room"
        defaultMessage="If you say yes you will <b><u>NOT</u></b> recieve sash."
        redBorderFields={redBorderFields}
        redFieldName={"Screen Room"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${screenRoomOption === "Yes" ? "clicked" : ""}`}
          onClick={() => handleScreenRoomOptionChange("Yes")}
        >
          Yes
        </div>
        &nbsp;
        <div
          className={`clickable-word ${screenRoomOption === "No" ? "clicked" : ""}`}
          onClick={() => handleScreenRoomOptionChange("No")}
        >
          No
        </div>
      </div>
    </div>
  );
};

ScreenRoom.propTypes = {
  handleScreenRoomChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default ScreenRoom;
