import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const SunRoomType = ({
  handleSelectedSunRoomType,
  redBorderFields,
  setFieldsWithRedBorder,
  isScreenRoom,
  selectedSunRoomType,
}) => {
  const handleSunroomTypeChange = (selected) => {
    const updatedRedBorderFields = redBorderFields.includes("Sunroom Type")
      ? selected
        ? redBorderFields.filter((field) => field !== "Sunroom Type")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Sunroom Type"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedSunRoomType(selected);
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginBottom: "1%" }}>
      <BoxHeader
        componentName={"Sunroom Type"}
        defaultMessage={
          !isScreenRoom
            ? "Vista View and Solarium: 18.5 - 81.5<br/>&emsp;&emsp;&emsp;Sill Height 18.5<br/><tab/>&emsp;&emsp;&emsp;Header Height 81.5</br>Premier View: 6.0 - 86.375<br/>&emsp;&emsp;&emsp;Sill Height 6.0<br/><tab/>&emsp;&emsp;&emsp;Header Height 86.375<br/>Upper Deck: 30.5 - 93.5<br/>&emsp;&emsp;&emsp;Sill Height 30.5<br/><tab/>&emsp;&emsp;&emsp;Header Height 93.5</br>"
            : "2” Sunrooms are 14.0 - 86.0"
        }
        redBorderFields={redBorderFields}
        redFieldName={"Sunroom Type"}
      />

      {!isScreenRoom && (
        <>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedSunRoomType === "Upper Deck" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Upper Deck")}
            >
              Upper Deck
            </div>
            <div
              className={`clickable-word ${selectedSunRoomType === "Vista View" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Vista View")}
            >
              Vista View
            </div>
          </div>

          <div>
            <div
              className={`clickable-word ${selectedSunRoomType === "Solarium" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Solarium")}
            >
              Solarium
            </div>
            <div
              className={`clickable-word ${selectedSunRoomType === "Premier View" ? "clicked" : ""}`}
              style={{ marginLeft: "1%" }}
              onClick={() => handleSunroomTypeChange("Premier View")}
            >
              Premier View
            </div>
          </div>
        </>
      )}
      {isScreenRoom && (
        <>
          <div className="clickable-words">
            <div
              className={`clickable-word ${selectedSunRoomType === "Value View" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Value View")}
            >
              Value View
            </div>
            <div
              className={`clickable-word ${selectedSunRoomType === "Vinyl View" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Vinyl View")}
            >
              Vinyl View
            </div>
          </div>
          <div>
            <div
              className={`clickable-word ${selectedSunRoomType === "Linear Screen" ? "clicked" : ""}`}
              onClick={() => handleSunroomTypeChange("Linear Screen")}
            >
              Linear Screen
            </div>
          </div>
        </>
      )}
    </div>
  );
};

SunRoomType.propTypes = {
  handleSelectedSunRoomType: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  isSunroom: PropTypes.bool,
  isScreenRoom: PropTypes.bool,
  selectedSunRoomType: PropTypes.string,
};

export default SunRoomType;
