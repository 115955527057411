import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "./BoxHeader";

const CleatsOrBond = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleSelectedCleatsOrBond,
  selectedShingle,
}) => {
  const [selectedCleatOption, setSelectedCleatOption] = useState("");

  const handleCleatBondOptionChange = (selected) => {
    setSelectedCleatOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "Cleats or Bond Tape",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "Cleats or Bond Tape")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Cleats or Bond Tape"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedCleatsOrBond(selected);
    if (selectedShingle === "YES") {
      handleSelectedCleatsOrBond("");
    }
  };

  return (
    <div className="popupwindow cleatsorbond">
      <BoxHeader
        componentName="Architectural or Bond Tape"
        redBorderFields={redBorderFields}
        redFieldName="Cleats or Bond Tape"
        defaultMessage="Architectural comes with cleats. <br/>The base cleats cover the roof panel seams. They have a cleat cover.<br/>Bond tape is sent for seams instead of cleats. Bond tape is <u>ALWAYS</u> white. <br/>The Bond Tape is used to cover the roof seams, in place of the cleats. It is wide and very sticky."
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedCleatOption === "CLEATS" ? "clicked" : ""}`}
          onClick={() => handleCleatBondOptionChange("CLEATS")}
          style={{ fontSize: "1.4vh" }}
        >
          Architect.
        </div>
        <div
          className={`clickable-word ${selectedCleatOption === "BOND-TAPE" ? "clicked" : ""}`}
          onClick={() => handleCleatBondOptionChange("BOND-TAPE")}
          style={{ fontSize: "1.4vh" }}
        >
          Bond&nbsp;Tape
        </div>
      </div>
    </div>
  );
};

CleatsOrBond.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleSelectedCleatsOrBond: PropTypes.func,
  selectedShingle: PropTypes.string,
};

export default CleatsOrBond;
