import React, { useState } from "react";
import PropTypes from "prop-types";
import TopTransoms from "./Transoms/TopTransoms";
import BottomTransoms from "./Transoms/BottomTransoms";
import TopCustomReady from "./Transoms/TopCustomReady";

const Transoms = ({
  handleSelectedBotTransom,
  redBorderFields,
  setFieldsWithRedBorder,
  handleBotTransomOption,
  handleTopTransomOption,
  selectedSunRoomType,
  handleSelectedTopCustomReady,
  selectedTopTransomOption,
  setSelectedTopTransomOption,
  isScreenRoom,
}) => {
  const [bottomTransom, setBottomTransom] = useState("");
  const [selectedTCR, setSelectedTCR] = useState("");

  const handleBottomTransomChange = (selected) => {
    setBottomTransom(selected);
    handleSelectedBotTransom(selected);

    const updatedRedBorderFields = redBorderFields.includes("Bottom Transom")
      ? selected
        ? redBorderFields.filter((field) => field !== "Bottom Transom")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Bottom Transom"];

    if (selected === "yes" && selectedSunRoomType === "Upper Deck") {
      handleBotTransomOptionChange("24");
    } else if (
      selected === "yes" &&
      (selectedSunRoomType === "Vista View" ||
        selectedSunRoomType === "Solarium")
    ) {
      handleBotTransomOptionChange("12");
    } else {
      handleBotTransomOptionChange("");
    }

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleTCRChange = (selected) => {
    setSelectedTCR(selected);
    handleSelectedTopCustomReady(selected);

    const updatedRedBorderFields = redBorderFields.includes("TCR")
      ? selected
        ? redBorderFields.filter((field) => field !== "TCR")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "TCR"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  const handleTopTransomOptionChange = (selected) => {
    setSelectedTopTransomOption(selected);
    handleTopTransomOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "Top Transom Option",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "Top Transom Option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Top Transom Option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };
  const handleBotTransomOptionChange = (selected) => {
    handleBotTransomOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "Bot Transom Option",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "Bot Transom Option")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Bot Transom Option"];

    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  return (
    <>
      {isScreenRoom && (
        <TopCustomReady
          style={{
            flex: 1,
            width: "50%",
            marginBottom: "1%",
          }}
          selectedTCR={selectedTCR}
          handleTCRChange={handleTCRChange}
          redBorderFields={redBorderFields}
        />
      )}
      {!isScreenRoom && (
        <div
          className="transoms"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "1%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <TopTransoms
              selectedTopTransomOption={selectedTopTransomOption}
              handleTopTransomOptionChange={handleTopTransomOptionChange}
              redBorderFields={redBorderFields}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "1%",
            }}
          >
            {selectedSunRoomType !== "Premier View" && (
              <BottomTransoms
                bottomTransom={bottomTransom}
                handleBottomTransomChange={handleBottomTransomChange}
                redBorderFields={redBorderFields}
              />
            )}
            <TopCustomReady
              style={{
                flex: 1,
                width: selectedSunRoomType !== "Premier View" ? "50%" : "100%",
                marginLeft: selectedSunRoomType !== "Premier View" ? "1%" : 0,
              }}
              selectedTCR={selectedTCR}
              handleTCRChange={handleTCRChange}
              redBorderFields={redBorderFields}
            />
          </div>
        </div>
      )}
    </>
  );
};

Transoms.propTypes = {
  handleSelectedTopTransom: PropTypes.func,
  handleSelectedBotTransom: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleBotTransomOption: PropTypes.func,
  handleTopTransomOption: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
  handleSelectedTopCustomReady: PropTypes.func,
  selectedTopTransomOption: PropTypes.string,
  setSelectedTopTransomOption: PropTypes.func,
  isScreenRoom: PropTypes.bool,
};

export default Transoms;
