import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../BoxHeader";

const RailAmountInput = ({
  railAmount,
  handleRailAmountChange,
  redBorderFields,
}) => {
  return (
    <div className="popupwindow hangingrailside">
      <BoxHeader componentName="Extra Hanging Rail Needed" />
      <div className="fontsizeforinputsrow">
        <span className="fontsizeforinputsrow">
          <span className="redasterisk">&#10033;</span>
          Feet:&nbsp;
        </span>
        <input
          id="pavLayoutFeet"
          name="feet"
          value={railAmount.feet}
          onChange={handleRailAmountChange}
          className={
            redBorderFields.includes("rail amount Feet")
              ? "redBackground"
              : "whiteBackground"
          }
          style={{ width: "20%" }}
          onKeyDown={(e) => {
            if (e.key.length === 1 && !/[0-9]/.test(e.key)) e.preventDefault();
          }}
        />
        <span className="fontsizeforinputsrow">
          &nbsp;
          <span className="redasterisk">&#10033;</span>
          Inches:&nbsp;
        </span>
        <input
          id="pavLayoutInches"
          name="inches"
          value={railAmount.inches}
          onChange={handleRailAmountChange}
          className={
            redBorderFields.includes("rail amount Inches")
              ? "redBackground"
              : "whiteBackground"
          }
          style={{ width: "20%" }}
        />
      </div>
    </div>
  );
};

RailAmountInput.propTypes = {
  railAmount: PropTypes.object,
  handleRailAmountChange: PropTypes.func,
  redBorderFields: PropTypes.array,
};

export default RailAmountInput;
