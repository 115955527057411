import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const ExteriorStyleSelection = ({
  handleExteriorStyleOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
  pavstyle,
}) => {
  const [selectedExteriorOption, setSelectedExteriorOption] = useState("");
  const Corbels = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/corbels.jpg`;
  const Rafter_Flat_End_Caps = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/endcap.jpg`;
  const images = [Corbels, Rafter_Flat_End_Caps];
  const messages = ["Corbels", "Rafter Flat End Caps"];
  if (pavstyle !== "TEMO Panel") {
    handleExteriorStyleOptionChange("");
    setSelectedExteriorOption("");
  }

  const handleExteriorOptionChange = (selected) => {
    let optionChange = selected;
    let updatedRedBorderFields = redBorderFields.includes("Exterior Style")
      ? selected
        ? redBorderFields.filter((field) => field !== "Exterior Style")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Exterior Style"];

    setSelectedExteriorOption(optionChange);
    handleExteriorStyleOptionChange(optionChange);
    setFieldsWithRedBorder(updatedRedBorderFields);
  };

  return (
    <div className="popupwindow exteriorstyle-choices">
      <BoxHeader
        componentName="Exterior Style Choices"
        images={images}
        imageMessages={messages}
        redBorderFields={redBorderFields}
        redFieldName={"Exterior Style"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedExteriorOption === "CORBELS" ? "clicked" : ""}`}
          onClick={() => handleExteriorOptionChange("CORBELS")}
        >
          Corbels
        </div>
        <div
          className={`clickable-word ${selectedExteriorOption === "FLAT END CAPS" ? "clicked" : ""}`}
          onClick={() => handleExteriorOptionChange("FLAT END CAPS")}
        >
          Rafter Flat End Caps
        </div>
      </div>
    </div>
  );
};

ExteriorStyleSelection.propTypes = {
  handleExteriorStyleOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  pavstyle: PropTypes.string,
  selectedBeamOption: PropTypes.string,
};

export default ExteriorStyleSelection;
