import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const NoSkyLights = ({ lengthFeet, selectedRoomStyle, selectedAngled }) => {
  const [greaterorlessthan, setGreaterorlessthan] = useState("");

  useEffect(() => {
    if (lengthFeet < 7) {
      setGreaterorlessthan(">= 7");
    }
    if (lengthFeet > 15) {
      setGreaterorlessthan("<= 15");
    }
  }, [lengthFeet]);

  return (
    <div className="popupwindow skylightselection">
      <BoxHeader componentName="Skyview Option Not Allowed" />
      <div className="clickable-words">
        {greaterorlessthan &&
          selectedAngled !== "Yes" &&
          selectedRoomStyle === "STUDIO" && (
            <div className="clickable-word">
              Projection must be {greaterorlessthan}
            </div>
          )}
        {selectedRoomStyle !== "STUDIO" && (
          <div className="clickable-word">Skyview Only Allowed With Studio</div>
        )}
        {selectedAngled === "Yes" && selectedRoomStyle === "STUDIO" && (
          <div className="clickable-word">
            Skyview Not Allowed With Angled Roof
          </div>
        )}
      </div>
    </div>
  );
};

NoSkyLights.propTypes = {
  lengthFeet: PropTypes.number,
  selectedRoomStyle: PropTypes.string,
  selectedAngled: PropTypes.string,
};

export default NoSkyLights;
