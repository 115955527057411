import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../../UniversalComponents/BoxHeader";

const LeftAngle = ({
  redBorderFields,
  leftAngleFeet,
  leftAngleInches,
  handleLeftAngleFeetChange,
  handleLeftAngleInchesChange,
  leftSideMissingWallLength,
  setLeftMissingWallLength,
  setLeftSideMissingWallDiv,
  selectedAngleOption,
}) => {
  const VerticalLine = `https://temoappstorage.blob.core.windows.net/temoimages/LincolnLogPhotos/Vertical-Line.jpg`;
  const handleABSquaredLine = useCallback(
    (value) => {
      const generateLeftMissingWallLength = (total, selectedAngledOption) => {
        let newTotal = Number(total);
        return Array.from({ length: newTotal }, (_, index) => {
          let gridRow;
          let gridColumn;
          let height = "100%";
          switch (selectedAngledOption.toString()) {
            case "2":
              gridColumn = "15 / span 1";
              gridRow = "34 / span 6";
              height = "100%";
              break;
            case "3":
              gridColumn = "15 / span 1";
              gridRow = "34 / span 6";
              height = "100%";
              break;
            case "4":
              gridColumn = "17 / span 1";
              gridRow = "34 / span 7";
              height = "100%";
              break;
            case "5":
              gridColumn = "15 / span 1";
              gridRow = "29 / span 7";
              height = "100%";
              break;
            case "6":
              gridColumn = "15 / span 1";
              gridRow = "30 / span 7";
              height = "100%";
              break;
            default:
              gridRow = "34 / span 7";
              height = "100%";
              gridColumn = "15 / span 1";
              break;
          }
          return {
            gridColumn: gridColumn,
            gridRow: gridRow,
            backgroundImage: VerticalLine,
            cursorPointer: false,
            width: "100%",
            height: height,
            id: `left-${index}`,
          };
        });
      };
      const numericValue = parseInt(value, 10);
      if (numericValue >= 0 && numericValue <= 4) {
        setLeftMissingWallLength(
          generateLeftMissingWallLength(numericValue, selectedAngleOption),
        );
      } else {
        setLeftMissingWallLength([]);
      }
    },
    [VerticalLine, setLeftMissingWallLength, selectedAngleOption],
  );

  useEffect(() => {
    handleABSquaredLine(1);
  }, [handleABSquaredLine]);

  useEffect(() => {
    setLeftSideMissingWallDiv([...leftSideMissingWallLength]);
  }, [leftSideMissingWallLength, setLeftSideMissingWallDiv]);

  return (
    <div className="popupwindow">
      <BoxHeader componentName={"Distance to Front Wall"} defaultMessage={""} />
      <div className="fontsizeforinputsrow" id="rooflength">
        <span className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="redasterisk">&#10033;</span>
            Feet:&nbsp;
          </span>
          <input
            id="lengthfeet"
            className={
              redBorderFields.includes("Left Angle Length Feet")
                ? "redBackground"
                : "whiteBackground"
            }
            value={leftAngleFeet}
            onChange={handleLeftAngleFeetChange}
            maxLength={3}
            pattern="\d*"
            style={{ width: "50%" }}
          />
        </span>
        <span className="fontsizeforinputsrow">
          <span className="fontsizeforinputsrow">
            <span className="redasterisk">&#10033;</span>
            Inch:&nbsp;
          </span>
          <input
            id="lengthinches"
            className={
              redBorderFields.includes("Left Angle Length Inches")
                ? "redBackground"
                : "whiteBackground"
            }
            value={leftAngleInches}
            onChange={handleLeftAngleInchesChange}
            style={{ width: "50%" }}
          />
        </span>
      </div>
    </div>
  );
};

LeftAngle.propTypes = {
  redBorderFields: PropTypes.array,
  leftAngleFeet: PropTypes.string,
  leftAngleInches: PropTypes.string,
  handleLeftAngleFeetChange: PropTypes.func,
  handleLeftAngleInchesChange: PropTypes.func,
  leftSideMissingWallLength: PropTypes.array,
  setLeftMissingWallLength: PropTypes.func,
  setLeftSideMissingWallDiv: PropTypes.func,
  selectedAngleOption: PropTypes.number,
};

export default LeftAngle;
