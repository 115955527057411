import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const TopCustomReady = ({
  selectedTCR,
  handleTCRChange,
  redBorderFields,
  style,
}) => {
  return (
    <div className="popupwindow" style={{ ...style }}>
      <BoxHeader
        componentName="Top Custom Ready"
        defaultMessage="Refers to custom trapezoidal glass.<br/>Dealer must supply glass."
        redBorderFields={redBorderFields}
        redFieldName={"TCR"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedTCR === "yes" ? "clicked" : ""}`}
          onClick={() => handleTCRChange("yes")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedTCR === "no" ? "clicked" : ""}`}
          onClick={() => handleTCRChange("no")}
        >
          No
        </div>
      </div>
    </div>
  );
};

TopCustomReady.propTypes = {
  selectedTCR: PropTypes.string,
  handleTCRChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  selectedSunRoomType: PropTypes.string,
  style: PropTypes.object,
};

export default TopCustomReady;
