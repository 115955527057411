import React from "react";
import PropTypes from "prop-types";
import BoxHeader from "../BoxHeader";

const RailSideSelector = ({
  redBorderFields,
  selectedSide,
  handleRailSideChange,
}) => {
  return (
    <div className="popupwindow hangingrailside">
      <BoxHeader
        componentName="Projection Side"
        redBorderFields={redBorderFields}
        redFieldName="rail side"
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedSide === "LEFT" ? "clicked" : ""}`}
          onClick={() => handleRailSideChange("LEFT")}
        >
          Left
        </div>
        <div
          className={`clickable-word ${selectedSide === "RIGHT" ? "clicked" : ""}`}
          onClick={() => handleRailSideChange("RIGHT")}
        >
          Right
        </div>
      </div>
    </div>
  );
};

RailSideSelector.propTypes = {
  redBorderFields: PropTypes.array,
  selectedSide: PropTypes.string,
  handleRailSideChange: PropTypes.func,
};

export default RailSideSelector;
