import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../BoxHeader";

const GradeHeight = ({
  handleGradeHeightOption,
  redBorderFields,
  setFieldsWithRedBorder,
  setSelectedGradeHeightFeetOption,
  setSelectedGradeHeightInchesOption,
}) => {
  const [inchesGradeHeightOption, setInchesGradeHeightOption] = useState("");
  const [feetGradeHeightValue, setFeetGradeHeightValue] = useState("");

  const handleGradeHeightInchesChange = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9]/g, ""); // Updated to remove periods

    const numericInput = parseInt(input, 10); // Parse input as integer
    const isValidNumber = !isNaN(numericInput) && numericInput < 12;

    if (input !== "" && !isValidNumber) {
      if (numericInput >= 12) {
        input = "11"; // Updated to set to the max integer value
      } else {
        input = numericInput.toString();
      }
    }

    setInchesGradeHeightOption(input);

    const updatedRedBorderFields = redBorderFields.includes(
      "Please input inches grade height",
    )
      ? input
        ? redBorderFields.filter(
            (field) => field !== "Please input inches grade height",
          )
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "Please input inches grade height"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleGradeHeightOption(feetGradeHeightValue, input);
    setSelectedGradeHeightInchesOption(input);
  };

  const handleGradeHeightFeetChange = (event) => {
    const input = event.target.value.replace(/\D/g, "").slice(0, 3);
    setFeetGradeHeightValue(input);
    const updatedRedBorderFields = redBorderFields.includes(
      "Please input feet grade height",
    )
      ? input
        ? redBorderFields.filter(
            (field) => field !== "Please input feet grade height",
          )
        : redBorderFields
      : input
        ? redBorderFields
        : [...redBorderFields, "Please input feet grade height"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleGradeHeightOption(input, inchesGradeHeightOption);
    setSelectedGradeHeightFeetOption(input);
  };

  return (
    <div className="popupwindow gradeheight-stuff">
      <form id="GradeHeight" onSubmit={(e) => e.preventDefault()}>
        <BoxHeader
          componentName="Grade Height"
          defaultMessage="Grade Height at Front Wall of deck structures only.<br/>How far off the ground is deck at front wall?"
        />
        <div className="fontsizeforinputsrow">
          <div className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Feet:&nbsp;
            </span>
            <input
              id="gradeheightfeet"
              className={
                "load-selector-boxes " +
                (redBorderFields.includes("Please input feet grade height")
                  ? "redBackground"
                  : "whiteBackground")
              }
              value={feetGradeHeightValue}
              onChange={handleGradeHeightFeetChange}
              maxLength={3}
              pattern="\d*"
            />
          </div>
          <div className="fontsizeforinputsrow">
            <span className="fontsizeforinputsrow">
              <span className="redasterisk">&#10033;</span>
              Inch:&nbsp;
            </span>
            <input
              id="gradeheightinches"
              className={
                "load-selector-boxes " +
                (redBorderFields.includes("Please input inches grade height")
                  ? "redBackground"
                  : "whiteBackground")
              }
              value={inchesGradeHeightOption}
              onChange={handleGradeHeightInchesChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

GradeHeight.propTypes = {
  handleGradeHeightOption: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  setSelectedGradeHeightFeetOption: PropTypes.func,
  setSelectedGradeHeightInchesOption: PropTypes.func,
};

export default GradeHeight;
