import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import useButtonScrollability from "./TemplateComponents/Scrollable";
import parse from "html-react-parser";

const RoofSpanKey = ({ isScreenRoom }) => {
  const { setButtonRef, isButtonScrollable } = useButtonScrollability();
  const { setButtonRef: setTextRef, isButtonScrollable: isTextScrollable } =
    useButtonScrollability();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dataSets = useMemo(() => {
    const baseDataSets = [
      {
        panelThickness: '4&nbsp;1/4"&nbsp;Roof',
        aluminumThickness: '0.032"',
        foamDensity: "1 pound",
        maxSpans: !isScreenRoom
          ? [
              "16'-10\"",
              "15'-8\"",
              "14'-6\"",
              "13'-1\"",
              "11'-10\"",
              "11'-0\"",
              "10'-4\"",
            ]
          : [
              "16'-11\"",
              "15'-9\"",
              "14'-7\"",
              "13'-2\"",
              "11'-11\"",
              "11'-1\"",
              "10'-3\"",
            ],
      },
      {
        panelThickness: '4&nbsp;1/4"&nbsp;Roof',
        aluminumThickness: '0.024"',
        foamDensity: "1 pound",
        maxSpans: !isScreenRoom
          ? [
              "17'-10\"",
              "15'-4\"",
              "12'-10\"",
              "11'-7\"",
              "10'-4\"",
              "9'-7\"",
              "8'-10\"",
            ]
          : [
              "17'-11\"",
              "15'-5\"",
              "12'-11\"",
              "11'-8\"",
              "10'-5\"",
              "9'-8\"",
              "8'-11\"",
            ],
      },
      {
        panelThickness: '4&nbsp;1/4"&nbsp;Roof',
        aluminumThickness: '0.032"',
        foamDensity: "2 pound",
        maxSpans: !isScreenRoom
          ? [
              "19'-10\"",
              "18'-11\"",
              "18'-1\"",
              "16'-8\"",
              "15'-4\"",
              "14'-2\"",
              "12'-11\"",
            ]
          : [
              "19'-11\"",
              "19'-0\"",
              "18'-2\"",
              "16'-9\"",
              "15'-5\"",
              "14'-3\"",
              "13'-0\"",
            ],
      },
    ];

    if (!isScreenRoom) {
      baseDataSets.push({
        panelThickness: '3"&nbsp;Roof&nbsp;(Solarium)',
        aluminumThickness: '0.032"',
        foamDensity: "2 pound",
        maxSpans: [
          "17'-0\"",
          "13'-6\"",
          "11'-10\"",
          "10'-6\"",
          "10'-0\"",
          "9'-3\"",
          "8'-6\"",
        ],
      });
    }

    return baseDataSets;
  }, [isScreenRoom]);

  const snowLoads = Array.from({ length: 7 }, (_, i) => 10 + i * 5);
  const solariumSnowLoads = Array.from({ length: 7 }, (_, i) => 10 + i * 10);

  const handleButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseClick = () => {
    setIsModalVisible(false);
  };

  return (
    <div
      style={{
        gridColumn: "3",
        gridRow: "1",
        display: "flex",
        justifySelf: "start",
        alignSelf: "end",
        paddingTop: "1%",
        width: "7vw",
        height: "fit-content",
        marginLeft: "-3vw",
      }}
    >
      <div style={{ cursor: "pointer", width: "6vw" }}>
        <button
          onClick={handleButtonClick}
          ref={setButtonRef}
          className="navbutton greenbackground"
          style={{
            width: "6vw",
            overflow: "hidden",
            textAlign: "center",
            fontSize: windowWidth > 1700 ? "1.7vh" : "1.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            className={`${isButtonScrollable ? " newScrollableForHeader" : ""}`}
          >
            Roof&nbsp;Span
            <br />
            Chart
          </span>
        </button>
      </div>

      {isModalVisible && (
        <div className="modal">
          <div
            className="popupwindow"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              paddingBottom: "1%",
              width: "90vw",
              height: "fit-content",
            }}
          >
            <h2
              className="greenBackground"
              style={{
                marginBottom: "2vh",
                width: "100%",
                height: "100%",
                padding: "1%",
              }}
            >
              Roof Span Chart
            </h2>
            <div
              style={{
                width: "fit-content",
                height: "fit-content",
                border: "solid white 2px",
                position: "absolute",
                top: "1%",
                right: "1%",
                padding: "2px",
              }}
            >
              <button
                type="button"
                className="showpdfbuttons"
                style={{ cursor: "pointer", width: "auto", height: "auto" }}
                onClick={handleCloseClick}
              >
                X
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  alignItems: "center",
                }}
              >
                {dataSets.slice(0, 2).map((dataSet, index) => (
                  <table
                    key={index}
                    style={{
                      marginBottom: "20px",
                      width: "90%",
                      borderBottom: "1px solid black",
                      borderTop: "1px solid black",
                    }}
                  >
                    <thead className="theadThStyle" style={{ width: "100%" }}>
                      <tr style={{ width: "100%" }}>
                        <th
                          className="thStyle"
                          style={{
                            fontSize: "1.5vh",
                            color: "black",
                            borderLeft: "1px solid black",
                          }}
                        >
                          Panel Thickness
                        </th>
                        <th
                          className="thStyle"
                          style={{ fontSize: "1.5vh", color: "black" }}
                        >
                          Aluminum Thickness
                        </th>
                        <th
                          className="thStyle"
                          style={{ fontSize: "1.5vh", color: "black" }}
                        >
                          Foam Density
                        </th>
                        <th
                          className="thStyle"
                          style={{ fontSize: "1.5vh", color: "black" }}
                        >
                          Snow Load
                        </th>
                        <th
                          className="thStyle"
                          style={{ fontSize: "1.5vh", color: "black" }}
                        >
                          Maximum Span
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ width: "100%" }}>
                      {snowLoads.map((snowLoad, i) => (
                        <tr key={i} style={{ width: "100%" }}>
                          <td style={{ fontSize: "1.5vh" }}>
                            {parse(dataSet.panelThickness)}
                          </td>
                          <td style={{ fontSize: "1.5vh" }}>
                            {dataSet.aluminumThickness}
                          </td>
                          <td style={{ fontSize: "1.5vh" }}>
                            {dataSet.foamDensity}
                          </td>
                          <td style={{ fontSize: "1.5vh" }}>{snowLoad}</td>
                          <td style={{ fontSize: "1.5vh" }}>
                            {dataSet.maxSpans[i]}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  alignItems: "center",
                }}
              >
                {dataSets.slice(2).map((dataSet, index) => (
                  <table
                    key={index}
                    style={{
                      marginBottom: "20px",
                      width: "90%",
                      borderBottom: "1px solid black",
                      borderTop: "1px solid black",
                    }}
                  >
                    <thead className="theadThStyle" style={{ width: "100%" }}>
                      <tr style={{ width: "100%" }}>
                        <th
                          className="thStyle"
                          style={{
                            fontSize: "1.5vh",
                            color: "black",
                            borderLeft: "1px solid black",
                          }}
                        >
                          Panel Thickness
                        </th>
                        <th
                          className="thStyle"
                          style={{ fontSize: "1.5vh", color: "black" }}
                        >
                          Aluminum Thickness
                        </th>
                        <th
                          className="thStyle"
                          style={{ fontSize: "1.5vh", color: "black" }}
                        >
                          Foam Density
                        </th>
                        <th
                          className="thStyle"
                          style={{ fontSize: "1.5vh", color: "black" }}
                        >
                          Snow Load
                        </th>
                        <th
                          className="thStyle"
                          style={{ fontSize: "1.5vh", color: "black" }}
                        >
                          Maximum Span
                        </th>
                      </tr>
                    </thead>
                    {index === 0 && (
                      <tbody style={{ width: "100%" }}>
                        {snowLoads.map((snowLoad, i) => (
                          <tr key={i} style={{ width: "100%" }}>
                            <td style={{ fontSize: "1.5vh" }}>
                              {parse(dataSet.panelThickness)}
                            </td>
                            <td style={{ fontSize: "1.5vh" }}>
                              {dataSet.aluminumThickness}
                            </td>
                            <td style={{ fontSize: "1.5vh" }}>
                              {dataSet.foamDensity}
                            </td>
                            <td style={{ fontSize: "1.5vh" }}>{snowLoad}</td>
                            <td style={{ fontSize: "1.5vh" }}>
                              {dataSet.maxSpans[i]}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                    {index !== 0 && (
                      <tbody style={{ width: "100%" }}>
                        {solariumSnowLoads.map((snowLoad, i) => (
                          <tr key={i} style={{ width: "100%" }}>
                            <td
                              ref={setTextRef}
                              style={{
                                fontSize: "1.5vh",
                                position: "relative",
                              }}
                            >
                              <span
                                className={
                                  isTextScrollable
                                    ? "newScrollableForHeader"
                                    : ""
                                }
                              >
                                {parse(dataSet.panelThickness)}
                              </span>
                            </td>
                            <td style={{ fontSize: "1.5vh" }}>
                              {dataSet.aluminumThickness}
                            </td>
                            <td style={{ fontSize: "1.5vh" }}>
                              {dataSet.foamDensity}
                            </td>
                            <td style={{ fontSize: "1.5vh" }}>{snowLoad}</td>
                            <td style={{ fontSize: "1.5vh" }}>
                              {dataSet.maxSpans[i]}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

RoofSpanKey.propTypes = {
  isScreenRoom: PropTypes.bool,
};

export default RoofSpanKey;
