import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NumericInputWithButtons from "./NumericInput";
import BoxHeader from "./BoxHeader";

const LoadSelection = ({
  handleWindLoadOption,
  handleRoofLoadOption,
  redBorderFields,
  setFieldsWithRedBorder,
  selectedRoofLoadChange,
  selectedWindLoadChange,
  selectedRoomStyle,
}) => {
  const [tempWindLoad, setTempWindLoad] = useState(null);
  const [tempRoofLoad, setTempRoofLoad] = useState(null);

  const handleWindLoadChange = (value) => {
    let newvalue = value.target.value;

    setTimeout(() => {
      if (newvalue) {
        const parsedValue = parseInt(newvalue, 10);
        if (!isNaN(parsedValue)) {
          setTempWindLoad(parsedValue);
        } else {
          setTempWindLoad(null); // Reset or handle invalid input
        }
      } else {
        setTempWindLoad(null); // Reset on empty input
      }
      const updatedRedBorderFields = redBorderFields.includes("WindLoad")
        ? newvalue
          ? redBorderFields.filter((field) => field !== "WindLoad")
          : redBorderFields
        : newvalue
          ? redBorderFields
          : [...redBorderFields, "WindLoad"];

      setFieldsWithRedBorder(updatedRedBorderFields);
    }, 0);
  };

  useEffect(() => {
    if (tempWindLoad !== null) {
      handleWindLoadOption(tempWindLoad);
    }
  }, [handleWindLoadOption, tempWindLoad]);

  const handleRoofLoadChange = (value) => {
    let newvalue = value.target.value;

    setTimeout(() => {
      if (newvalue) {
        const parsedValue = parseInt(newvalue, 10);
        if (!isNaN(parsedValue)) {
          setTempRoofLoad(parsedValue);
        } else {
          setTempRoofLoad(null); // Reset or handle invalid input
        }
      } else {
        setTempRoofLoad(null); // Reset on empty input
      }
      const updatedRedBorderFields = redBorderFields.includes("RoofLoad")
        ? newvalue
          ? redBorderFields.filter((field) => field !== "RoofLoad")
          : redBorderFields
        : newvalue
          ? redBorderFields
          : [...redBorderFields, "RoofLoad"];

      setFieldsWithRedBorder(updatedRedBorderFields);
    }, 0);
  };

  useEffect(() => {
    if (tempRoofLoad !== null) {
      let value = tempRoofLoad;
      handleRoofLoadOption(value);
    }
  }, [
    handleRoofLoadOption,
    tempRoofLoad,
    selectedRoofLoadChange,
    selectedWindLoadChange,
  ]);

  return (
    <div className="load-selection">
      <div
        className="popupwindow windload"
        style={{
          height: selectedRoomStyle !== "Existing Roof" ? "50%" : "100%",
        }}
      >
        <BoxHeader
          componentName="Wind Load"
          defaultMessage={
            "Example: A 20 pounds per square foot wind load (or dynamic pressure) corresponds to 88.5 mph wind speed.<br/>This wind speed is typical in a category one hurricane."
          }
        />
        <div className="input-container">
          <NumericInputWithButtons
            id="WindLoad"
            className={
              "load-selector-boxes " +
              (redBorderFields.includes("WindLoad")
                ? "redBackground"
                : "whiteBackground")
            }
            min={90}
            max={200}
            step={5}
            value={selectedWindLoadChange ? Number(selectedWindLoadChange) : ""}
            onChange={(valueAsNumber) => {
              handleWindLoadChange({
                target: {
                  value: valueAsNumber,
                },
              });
            }}
          />
        </div>
      </div>
      {selectedRoomStyle !== "Existing Roof" ? (
        <div className="popupwindow roofload">
          <BoxHeader
            componentName="Roof Load"
            defaultMessage={
              "Roof Load refers to the amount of force, per square foot, is allowed for any given Temo product."
            }
          />
          <div className="input-container">
            <NumericInputWithButtons
              id="RoofLoad"
              className={
                "load-selector-boxes " +
                (redBorderFields.includes("RoofLoad")
                  ? "redBackground"
                  : "whiteBackground")
              }
              min={10}
              max={100}
              step={5}
              value={
                selectedRoofLoadChange ? Number(selectedRoofLoadChange) : ""
              }
              onChange={(valueAsNumber) => {
                handleRoofLoadChange({
                  target: {
                    value: valueAsNumber,
                  },
                });
              }}
            />
          </div>
        </div>
      ) : (
        <div className="roofload"></div>
      )}
    </div>
  );
};

LoadSelection.propTypes = {
  handleWindLoadOption: PropTypes.func,
  handleRoofLoadOption: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  selectedRoofLoadChange: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  selectedWindLoadChange: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  selectedRoomStyle: PropTypes.string,
};

export default LoadSelection;
