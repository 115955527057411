import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import SunRoomWallDrawing from "./SunRoomWallsComponents/UniversalWalls/SunRoomWallDrawing";
import ExistingPostsWalls from "./SunRoomWallsComponents/ExistingRoof/ExistingPostsWalls";
import ClickMeOrValues from "./SunRoomWallsComponents/ExistingRoof/ClickMeOrValues";
import StyledDiv from "./SunRoomWallsComponents/ExistingRoof/LinconLogImages";
import FrontBays from "./SunRoomWallsComponents/Solarium/Bays";

const SunRoomWalls = ({
  selectedRoomStyle,
  setSelectedWallCodes,
  selectedWallCodes,
  setSelectedSwingDoorOrientation,
  selectedCodes,
  setSelectedCodes,
  selectedDoorHardware,
  setSelectedDoorHardware,
  selectedPatioDoorOrientation,
  setSelectedPatioDoorOrientation,
  doorOption,
  setDoorOption,
  doorWidth,
  setDoorWidth,
  selectedSwingDoorOrientation,
  redBorderFields,
  setFieldsWithRedBorder,
  selectedSunRoomType,
  handleWallLengthFeetChange,
  handleWallLengthInchesChange,
  wallLengthsFeet,
  wallLengthsInches,
  SSHinges,
  handleSSHingesOptionChange,
  selectedPavilionLayoutOption,
  selectedRailSideOption,
  selectedBreezeWay,
  setWallERLLengthsFeet,
  setWallERLLengthsInches,
  setWallERRLengthsFeet,
  setWallERRLengthsInches,
  setWallERFLengthsFeet,
  setWallERFLengthsInches,
  wallERLLengthsFeet,
  wallERLLengthsInches,
  wallERRLengthsFeet,
  wallERRLengthsInches,
  wallERFLengthsFeet,
  wallERFLengthsInches,
  setWallLengthsFeet,
  setWallLengthsInches,
  setLeftCavities,
  setRightCavities,
  setFrontCavities,
  setDoorERFOption,
  setDoorERROption,
  setDoorERLOption,
  doorERFOption,
  doorERROption,
  doorERLOption,
  doorERFWidth,
  setDoorERFWidth,
  doorERRWidth,
  setDoorERRWidth,
  doorERLWidth,
  setDoorERLWidth,
  lastWallLengthUpdate,
  setLastWallLengthUpdate,
  setSelectedERLSwingDoorOrientation,
  setSelectedERLPatioDoorOrientation,
  setSelectedERRSwingDoorOrientation,
  setSelectedERRPatioDoorOrientation,
  setSelectedERFSwingDoorOrientation,
  setSelectedERFPatioDoorOrientation,
  selectedERLPatioDoorOrientation,
  selectedERLSwingDoorOrientation,
  selectedERRPatioDoorOrientation,
  selectedERRSwingDoorOrientation,
  selectedERFPatioDoorOrientation,
  selectedERFSwingDoorOrientation,
  setSelectedERLCodes,
  setSelectedERRCodes,
  setSelectedERFCodes,
  selectedERLCodes,
  selectedERRCodes,
  selectedERFCodes,
  selectedERLButtonIndex,
  selectedERRButtonIndex,
  selectedERFButtonIndex,
  setSelectedERLButtonIndex,
  setSelectedERRButtonIndex,
  setSelectedERFButtonIndex,
  selectedERLImages,
  selectedERRImages,
  selectedERFImages,
  setSelectedERLImages,
  setSelectedERRImages,
  setSelectedERFImages,
  selectedImages,
  setSelectedImages,
  selectedButtonIndex,
  setSelectedButtonIndex,
  handleCodeSelect,
  setLeftSideDivs,
  setRightSideDivs,
  setFrontSideDivs,
  setLeftSidePickMeDivs,
  setRightSidePickMeDivs,
  setFrontSidePickMeDivs,
  leftSideDivs,
  rightSideDivs,
  frontSideDivs,
  leftSidePickMeDivs,
  rightSidePickMeDivs,
  frontSidePickMeDivs,
  verticalRightLines,
  setVerticalRightLines,
  blackRightSquares,
  setBlackRightSquares,
  setRightPickMes,
  pickMeRightDivs,
  blackFrontSquares,
  setBlackFrontSquares,
  setFrontPickMes,
  pickMeFrontDivs,
  horizontalLines,
  setHorizontalLines,
  blackLeftSquares,
  setBlackLeftSquares,
  setLeftPickMes,
  pickMeLeftDivs,
  setVerticalLeftLines,
  verticalLeftLines,
  setIndexFront,
  setIndexRight,
  setIndexLeft,
  indexFront,
  indexRight,
  indexLeft,
  setOpenFrontSingleDrawing,
  setOpenRightSingleDrawing,
  setOpenLeftSingleDrawing,
  openFrontSingleDrawing,
  openRightSingleDrawing,
  openLeftSingleDrawing,
  handleDoorOptionChange,
  handleDoorWidthChange,
  isScreenRoom,
  setSelectedLocations,
  setSelectedERFLocations,
  setSelectedERRLocations,
  setSelectedERLLocations,
  selectedLocations,
  selectedERFLocations,
  selectedERRLocations,
  selectedERLLocations,
  handleDoorLocationChange,
  handleSelectedScreenDoorWidthChoice,
  handleBays,
  setSelectedOption,
  selectedOption,
  handleSelectedPatioDoorOrientation,
  currentImage,
  handleSwingDoorOrientation,
}) => {
  const buttonRef = useRef(null);
  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false);
  const [showDoorHardware, setShowDoorHardware] = useState(false);
  const [isButtonScrollable, setIsButtonScrollable] = useState(false);
  const handleShowDoorHardwareClick = () => {
    setShowDoorHardware(true);
  };

  const checkButtonScrollability = useCallback(() => {
    const button = buttonRef.current;
    if (button) {
      setIsButtonScrollable(button.scrollWidth > button.clientWidth);
    }
  }, []);

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) return;

    const resizeObserver = new ResizeObserver(() => {
      checkButtonScrollability();
    });

    resizeObserver.observe(button);

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkButtonScrollability]);

  useEffect(() => {
    checkButtonScrollability();
  }, [checkButtonScrollability]);

  const setButtonRef = useCallback(
    (node) => {
      buttonRef.current = node;
      checkButtonScrollability();
    },
    [checkButtonScrollability],
  );

  const renderDivs = (divs, PickMeDivs) => {
    const styledDivs = divs.map((div, index) => (
      <StyledDiv
        id={div.id}
        key={`styled-${index}`}
        gridColumn={div.gridColumn}
        gridRow={div.gridRow}
        backgroundImage={div.backgroundImage}
        onClick={div.cursorPointer ? div.onClick : undefined}
        cursorPointer={div.cursorPointer}
        width={div.width}
        height={div.height}
      />
    ));
    const newStyledComponents = PickMeDivs.map((PickMeDiv, index) => {
      var clickMeImage =
        PickMeDiv.clickMeRightImage ||
        PickMeDiv.clickMeLeftImage ||
        PickMeDiv.clickMeFrontImage;
      return (
        <ClickMeOrValues
          key={`clickme-${index}`}
          gridRow={PickMeDiv.gridRow}
          gridColumn={PickMeDiv.gridColumn}
          id={PickMeDiv.id}
          clickMeImage={clickMeImage}
          values={PickMeDiv.values}
          selectedSunRoomType={selectedSunRoomType}
        />
      );
    });
    return (
      <>
        {styledDivs}
        {newStyledComponents}
      </>
    );
  };

  const [dimension, setDimension] = useState(
    Math.min(window.innerHeight, window.innerWidth),
  );

  const baseNames = [
    "Door",
    "Door Width",
    "Wall Code",
    "Swing Door Orientation",
    "Patio Door Orientation",
    "Wall Length Feet",
    "Wall Length Inches",
    "Door Location",
  ];
  const positions = ["", "Left", "Right", "Front"];
  const counts = {
    Door: 3,
    "Door Width": 6,
    "Wall Code": 6,
    "Swing Door Orientation": 6,
    "Patio Door Orientation": 6,
    "Wall Length Feet": 6,
    "Wall Length Inches": 6,
    "Door Location": 6,
  };
  const extras = ["Door Hardware", "SS Hinges", "Screen Door Width"];

  const componentNames = [];

  positions.forEach((position) => {
    baseNames.forEach((baseName) => {
      const count = counts[baseName];
      for (let i = 1; i <= count; i++) {
        const name = `${position} ${baseName} ${i}`.trim();
        componentNames.push(name);
      }
    });
  });

  componentNames.push(...extras);

  const shouldHaveRedBorder = componentNames.some((name) =>
    redBorderFields.includes(name),
  );

  useEffect(() => {
    const handleResize = () => {
      setDimension(Math.min(window.innerHeight, window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSeeOptionsClick = () => {
    setIsOptionsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsOptionsModalVisible(false);
  };

  return (
    <div
      style={{
        gridColumn: "1",
        gridRow: "1",
        justifySelf: "end",
        marginTop: "10vh",
        paddingTop: "1%",
        width: "8vw",
        height: "fit-content",
        marginRight: "2.4%",
        marginLeft: "2.4%",
        display: "flex",
        alignSelf: "center",
      }}
    >
      {selectedSunRoomType &&
        selectedRoomStyle &&
        selectedPavilionLayoutOption &&
        (selectedPavilionLayoutOption !== "2" ||
          selectedRailSideOption ||
          selectedBreezeWay === "Yes") && (
          <button
            className={`navbutton ${shouldHaveRedBorder ? "redBackground" : "greenBackground"}`}
            style={{
              fontSize: "2vh",
              width: "7vw",
              overflow: "hidden",
              marginTop: "1vh",
            }}
            ref={setButtonRef}
            onClick={handleSeeOptionsClick}
          >
            <span
              className={`${isButtonScrollable ? "newScrollableForHeader" : ""}`}
            >
              Wall
              <br />
              Choices
            </span>
          </button>
        )}
      {isOptionsModalVisible && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, .8)",
              zIndex: 1000,
            }}
          />
          <div
            className="popupwindow"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: "1em",
              zIndex: 1001,
              display: "flex",
              flexDirection:
                selectedPavilionLayoutOption === "2"
                  ? selectedRailSideOption === "RIGHT"
                    ? "row-reverse"
                    : "row"
                  : "row",
              height: `${dimension * 0.85}px`,
              width: `${dimension * 2}px`,
              maxWidth: "80vw",
              justifyContent: "center",
              alignItems:
                selectedSunRoomType !== "Linear Screen" ? "end" : "center",
            }}
          >
            <button
              className="navbutton"
              style={{
                position: "absolute",
                top: "1%",
                right: "1%",
                cursor: "pointer",
              }}
              onClick={handleCloseModal}
            >
              Close
            </button>
            {selectedRoomStyle !== "Existing Roof" &&
              selectedPavilionLayoutOption &&
              Array.from({
                length:
                  selectedPavilionLayoutOption === "1"
                    ? 3
                    : selectedPavilionLayoutOption === "2"
                      ? 2
                      : 1,
              }).map((_, index) =>
                selectedSunRoomType === "Solarium" &&
                (index === 1 ||
                  (selectedPavilionLayoutOption === "3" && index === 0)) ? (
                  <FrontBays
                    key={index}
                    redBorderFields={redBorderFields}
                    setFieldsWithRedBorder={setFieldsWithRedBorder}
                    handleBays={handleBays}
                    selectedDoorHardware={selectedDoorHardware}
                    setSelectedDoorHardware={setSelectedDoorHardware}
                    setShowDoorHardware={setShowDoorHardware}
                    index={index}
                    selectedPavilionLayoutOption={selectedPavilionLayoutOption}
                    doorWidth={doorWidth}
                    handleShowDoorHardwareClick={handleShowDoorHardwareClick}
                    SSHinges={SSHinges}
                    handleSSHingesOptionChange={handleSSHingesOptionChange}
                    showDoorHardware={showDoorHardware}
                    selectedSunRoomType={selectedSunRoomType}
                    handleSelectedScreenDoorWidthChoice={
                      handleSelectedScreenDoorWidthChoice
                    }
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                  />
                ) : (
                  <SunRoomWallDrawing
                    key={index}
                    index={index}
                    doorOption={doorOption}
                    doorWidth={doorWidth}
                    handleDoorOptionChange={handleDoorOptionChange}
                    handleDoorWidthChange={handleDoorWidthChange}
                    dimension={dimension}
                    setIsOptionsModalVisible={setIsOptionsModalVisible}
                    selectedRoomStyle={selectedRoomStyle}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    setSelectedSwingDoorOrientation={
                      setSelectedSwingDoorOrientation
                    }
                    setSelectedWallCodes={setSelectedWallCodes}
                    selectedWallCodes={selectedWallCodes}
                    selectedCodes={selectedCodes}
                    setSelectedCodes={setSelectedCodes}
                    selectedButtonIndex={selectedButtonIndex}
                    setSelectedButtonIndex={setSelectedButtonIndex}
                    selectedSwingDoorOrientation={selectedSwingDoorOrientation}
                    selectedDoorHardware={selectedDoorHardware}
                    setSelectedDoorHardware={setSelectedDoorHardware}
                    selectedPatioDoorOrientation={selectedPatioDoorOrientation}
                    setSelectedPatioDoorOrientation={
                      setSelectedPatioDoorOrientation
                    }
                    redBorderFields={redBorderFields}
                    setFieldsWithRedBorder={setFieldsWithRedBorder}
                    wallLengthsFeet={wallLengthsFeet}
                    wallLengthsInches={wallLengthsInches}
                    handleWallLengthFeetChange={handleWallLengthFeetChange}
                    handleWallLengthInchesChange={handleWallLengthInchesChange}
                    SSHinges={SSHinges}
                    handleSSHingesOptionChange={handleSSHingesOptionChange}
                    selectedPavilionLayoutOption={selectedPavilionLayoutOption}
                    selectedRailSideOption={selectedRailSideOption}
                    selectedBreezeWay={selectedBreezeWay}
                    selectedSunRoomType={selectedSunRoomType}
                    setWallLengthsFeet={setWallLengthsFeet}
                    setWallLengthsInches={setWallLengthsInches}
                    setDoorOption={setDoorOption}
                    setDoorWidth={setDoorWidth}
                    lastWallLengthUpdate={lastWallLengthUpdate}
                    setLastWallLengthUpdate={setLastWallLengthUpdate}
                    handleCodeSelect={handleCodeSelect}
                    isScreenRoom={isScreenRoom}
                    setSelectedLocations={setSelectedLocations}
                    selectedLocations={selectedLocations}
                    handleDoorLocationChange={handleDoorLocationChange}
                    handleSelectedScreenDoorWidthChoice={
                      handleSelectedScreenDoorWidthChoice
                    }
                    handleSelectedPatioDoorOrientation={
                      handleSelectedPatioDoorOrientation
                    }
                    currentImage={currentImage}
                    handleSwingDoorOrientation={handleSwingDoorOrientation}
                  />
                ),
              )}
            {selectedRoomStyle === "Existing Roof" &&
              selectedPavilionLayoutOption && (
                <ExistingPostsWalls
                  dimension={dimension}
                  redBorderFields={redBorderFields}
                  selectedPavilionLayoutOption={selectedPavilionLayoutOption}
                  selectedBreezeWay={selectedBreezeWay}
                  selectedSunRoomType={selectedSunRoomType}
                  selectedRailSideOption={selectedRailSideOption}
                  setWallERLLengthsFeet={setWallERLLengthsFeet}
                  setWallERLLengthsInches={setWallERLLengthsInches}
                  setWallERRLengthsFeet={setWallERRLengthsFeet}
                  setWallERRLengthsInches={setWallERRLengthsInches}
                  setWallERFLengthsFeet={setWallERFLengthsFeet}
                  setWallERFLengthsInches={setWallERFLengthsInches}
                  wallERLLengthsFeet={wallERLLengthsFeet}
                  wallERLLengthsInches={wallERLLengthsInches}
                  wallERRLengthsFeet={wallERRLengthsFeet}
                  wallERRLengthsInches={wallERRLengthsInches}
                  wallERFLengthsFeet={wallERFLengthsFeet}
                  wallERFLengthsInches={wallERFLengthsInches}
                  handleWallLengthFeetChange={handleWallLengthFeetChange}
                  handleWallLengthInchesChange={handleWallLengthInchesChange}
                  setLeftCavities={setLeftCavities}
                  setRightCavities={setRightCavities}
                  setFrontCavities={setFrontCavities}
                  setDoorERFOption={setDoorERFOption}
                  setDoorERROption={setDoorERROption}
                  setDoorERLOption={setDoorERLOption}
                  doorERFOption={doorERFOption}
                  doorERROption={doorERROption}
                  doorERLOption={doorERLOption}
                  handleDoorOptionChange={handleDoorOptionChange}
                  doorERFWidth={doorERFWidth}
                  setDoorERFWidth={setDoorERFWidth}
                  doorERRWidth={doorERRWidth}
                  setDoorERRWidth={setDoorERRWidth}
                  doorERLWidth={doorERLWidth}
                  setDoorERLWidth={setDoorERLWidth}
                  setLastWallLengthUpdate={setLastWallLengthUpdate}
                  lastWallLengthUpdate={lastWallLengthUpdate}
                  handleDoorWidthChange={handleDoorWidthChange}
                  setFieldsWithRedBorder={setFieldsWithRedBorder}
                  selectedERLPatioDoorOrientation={
                    selectedERLPatioDoorOrientation
                  }
                  selectedERLSwingDoorOrientation={
                    selectedERLSwingDoorOrientation
                  }
                  selectedERRPatioDoorOrientation={
                    selectedERRPatioDoorOrientation
                  }
                  selectedERRSwingDoorOrientation={
                    selectedERRSwingDoorOrientation
                  }
                  selectedERFPatioDoorOrientation={
                    selectedERFPatioDoorOrientation
                  }
                  selectedERFSwingDoorOrientation={
                    selectedERFSwingDoorOrientation
                  }
                  setSelectedERLSwingDoorOrientation={
                    setSelectedERLSwingDoorOrientation
                  }
                  setSelectedERLPatioDoorOrientation={
                    setSelectedERLPatioDoorOrientation
                  }
                  setSelectedERRSwingDoorOrientation={
                    setSelectedERRSwingDoorOrientation
                  }
                  setSelectedERRPatioDoorOrientation={
                    setSelectedERRPatioDoorOrientation
                  }
                  setSelectedERFSwingDoorOrientation={
                    setSelectedERFSwingDoorOrientation
                  }
                  setSelectedERFPatioDoorOrientation={
                    setSelectedERFPatioDoorOrientation
                  }
                  setSelectedERLCodes={setSelectedERLCodes}
                  setSelectedERRCodes={setSelectedERRCodes}
                  setSelectedERFCodes={setSelectedERFCodes}
                  selectedERLCodes={selectedERLCodes}
                  selectedERRCodes={selectedERRCodes}
                  selectedERFCodes={selectedERFCodes}
                  selectedERLButtonIndex={selectedERLButtonIndex}
                  selectedERRButtonIndex={selectedERRButtonIndex}
                  selectedERFButtonIndex={selectedERFButtonIndex}
                  setSelectedERLButtonIndex={setSelectedERLButtonIndex}
                  setSelectedERRButtonIndex={setSelectedERRButtonIndex}
                  setSelectedERFButtonIndex={setSelectedERFButtonIndex}
                  selectedERLImages={selectedERLImages}
                  selectedERRImages={selectedERRImages}
                  selectedERFImages={selectedERFImages}
                  setSelectedERLImages={setSelectedERLImages}
                  setSelectedERRImages={setSelectedERRImages}
                  setSelectedERFImages={setSelectedERFImages}
                  handleCodeSelect={handleCodeSelect}
                  selectedDoorHardware={selectedDoorHardware}
                  setSelectedDoorHardware={setSelectedDoorHardware}
                  SSHinges={SSHinges}
                  handleSSHingesOptionChange={handleSSHingesOptionChange}
                  setLeftSideDivs={setLeftSideDivs}
                  setRightSideDivs={setRightSideDivs}
                  setFrontSideDivs={setFrontSideDivs}
                  setLeftSidePickMeDivs={setLeftSidePickMeDivs}
                  setRightSidePickMeDivs={setRightSidePickMeDivs}
                  setFrontSidePickMeDivs={setFrontSidePickMeDivs}
                  leftSideDivs={leftSideDivs}
                  rightSideDivs={rightSideDivs}
                  frontSideDivs={frontSideDivs}
                  leftSidePickMeDivs={leftSidePickMeDivs}
                  rightSidePickMeDivs={rightSidePickMeDivs}
                  frontSidePickMeDivs={frontSidePickMeDivs}
                  verticalRightLines={verticalRightLines}
                  setVerticalRightLines={setVerticalRightLines}
                  blackRightSquares={blackRightSquares}
                  setBlackRightSquares={setBlackRightSquares}
                  setRightPickMes={setRightPickMes}
                  pickMeRightDivs={pickMeRightDivs}
                  blackFrontSquares={blackFrontSquares}
                  setBlackFrontSquares={setBlackFrontSquares}
                  setFrontPickMes={setFrontPickMes}
                  pickMeFrontDivs={pickMeFrontDivs}
                  horizontalLines={horizontalLines}
                  setHorizontalLines={setHorizontalLines}
                  blackLeftSquares={blackLeftSquares}
                  setBlackLeftSquares={setBlackLeftSquares}
                  setLeftPickMes={setLeftPickMes}
                  pickMeLeftDivs={pickMeLeftDivs}
                  setVerticalLeftLines={setVerticalLeftLines}
                  verticalLeftLines={verticalLeftLines}
                  setIndexFront={setIndexFront}
                  setIndexRight={setIndexRight}
                  setIndexLeft={setIndexLeft}
                  indexFront={indexFront}
                  indexRight={indexRight}
                  indexLeft={indexLeft}
                  renderDivs={renderDivs}
                  setOpenFrontSingleDrawing={setOpenFrontSingleDrawing}
                  setOpenRightSingleDrawing={setOpenRightSingleDrawing}
                  setOpenLeftSingleDrawing={setOpenLeftSingleDrawing}
                  openFrontSingleDrawing={openFrontSingleDrawing}
                  openRightSingleDrawing={openRightSingleDrawing}
                  openLeftSingleDrawing={openLeftSingleDrawing}
                  isScreenRoom={isScreenRoom}
                  setSelectedERFLocations={setSelectedERFLocations}
                  setSelectedERRLocations={setSelectedERRLocations}
                  setSelectedERLLocations={setSelectedERLLocations}
                  selectedERFLocations={selectedERFLocations}
                  selectedERRLocations={selectedERRLocations}
                  selectedERLLocations={selectedERLLocations}
                  handleDoorLocationChange={handleDoorLocationChange}
                  handleSelectedScreenDoorWidthChoice={
                    handleSelectedScreenDoorWidthChoice
                  }
                  handleSelectedPatioDoorOrientation={
                    handleSelectedPatioDoorOrientation
                  }
                  currentImage={currentImage}
                  handleSwingDoorOrientation={handleSwingDoorOrientation}
                />
              )}
          </div>
        </>
      )}
    </div>
  );
};

SunRoomWalls.propTypes = {
  selectedRoomStyle: PropTypes.string,
  setSelectedSwingDoorOrientation: PropTypes.func,
  setSelectedWallCodes: PropTypes.func,
  selectedWallCodes: PropTypes.array,
  selectedCodes: PropTypes.array,
  setSelectedCodes: PropTypes.func,
  selectedDoorHardware: PropTypes.string,
  setSelectedDoorHardware: PropTypes.func,
  selectedPatioDoorOrientation: PropTypes.array,
  setSelectedPatioDoorOrientation: PropTypes.func,
  doorOption: PropTypes.array,
  setDoorOption: PropTypes.func,
  doorWidth: PropTypes.array,
  setDoorWidth: PropTypes.func,
  selectedSwingDoorOrientation: PropTypes.array,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  selectedSunRoomType: PropTypes.string,
  handleWallLengthFeetChange: PropTypes.func,
  handleWallLengthInchesChange: PropTypes.func,
  wallLengthsFeet: PropTypes.array,
  wallLengthsInches: PropTypes.array,
  SSHinges: PropTypes.string,
  handleSSHingesOptionChange: PropTypes.func,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedRailSideOption: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  setWallERLLengthsFeet: PropTypes.func,
  setWallERLLengthsInches: PropTypes.func,
  setWallERRLengthsFeet: PropTypes.func,
  setWallERRLengthsInches: PropTypes.func,
  setWallERFLengthsFeet: PropTypes.func,
  setWallERFLengthsInches: PropTypes.func,
  wallERLLengthsFeet: PropTypes.array,
  wallERLLengthsInches: PropTypes.array,
  wallERRLengthsFeet: PropTypes.array,
  wallERRLengthsInches: PropTypes.array,
  wallERFLengthsFeet: PropTypes.array,
  wallERFLengthsInches: PropTypes.array,
  setWallLengthsFeet: PropTypes.func,
  setWallLengthsInches: PropTypes.func,
  setLeftCavities: PropTypes.func,
  setRightCavities: PropTypes.func,
  setFrontCavities: PropTypes.func,
  setDoorERFOption: PropTypes.func,
  setDoorERROption: PropTypes.func,
  setDoorERLOption: PropTypes.func,
  doorERFOption: PropTypes.array,
  doorERROption: PropTypes.array,
  doorERLOption: PropTypes.array,
  doorERFWidth: PropTypes.array,
  setDoorERFWidth: PropTypes.func,
  doorERRWidth: PropTypes.array,
  setDoorERRWidth: PropTypes.func,
  doorERLWidth: PropTypes.array,
  setDoorERLWidth: PropTypes.func,
  lastWallLengthUpdate: PropTypes.number,
  setLastWallLengthUpdate: PropTypes.func,
  setSelectedERLSwingDoorOrientation: PropTypes.func,
  setSelectedERLPatioDoorOrientation: PropTypes.func,
  setSelectedERRSwingDoorOrientation: PropTypes.func,
  setSelectedERRPatioDoorOrientation: PropTypes.func,
  setSelectedERFSwingDoorOrientation: PropTypes.func,
  setSelectedERFPatioDoorOrientation: PropTypes.func,
  selectedERLPatioDoorOrientation: PropTypes.array,
  selectedERLSwingDoorOrientation: PropTypes.array,
  selectedERRPatioDoorOrientation: PropTypes.array,
  selectedERRSwingDoorOrientation: PropTypes.array,
  selectedERFPatioDoorOrientation: PropTypes.array,
  selectedERFSwingDoorOrientation: PropTypes.array,
  setSelectedERLCodes: PropTypes.func,
  setSelectedERRCodes: PropTypes.func,
  setSelectedERFCodes: PropTypes.func,
  selectedERLCodes: PropTypes.array,
  selectedERRCodes: PropTypes.array,
  selectedERFCodes: PropTypes.array,
  selectedImages: PropTypes.array,
  setSelectedImages: PropTypes.func,
  selectedButtonIndex: PropTypes.array,
  setSelectedButtonIndex: PropTypes.func,
  selectedERFButtonIndex: PropTypes.array,
  selectedERRButtonIndex: PropTypes.array,
  selectedERLButtonIndex: PropTypes.array,
  setSelectedERFButtonIndex: PropTypes.func,
  setSelectedERRButtonIndex: PropTypes.func,
  setSelectedERLButtonIndex: PropTypes.func,
  selectedERFImages: PropTypes.array,
  selectedERRImages: PropTypes.array,
  selectedERLImages: PropTypes.array,
  setSelectedERFImages: PropTypes.func,
  setSelectedERRImages: PropTypes.func,
  setSelectedERLImages: PropTypes.func,
  handleCodeSelect: PropTypes.func,
  setLeftSideDivs: PropTypes.func,
  setRightSideDivs: PropTypes.func,
  setFrontSideDivs: PropTypes.func,
  setLeftSidePickMeDivs: PropTypes.func,
  setRightSidePickMeDivs: PropTypes.func,
  setFrontSidePickMeDivs: PropTypes.func,
  leftSideDivs: PropTypes.array,
  rightSideDivs: PropTypes.array,
  frontSideDivs: PropTypes.array,
  leftSidePickMeDivs: PropTypes.array,
  rightSidePickMeDivs: PropTypes.array,
  frontSidePickMeDivs: PropTypes.array,
  verticalRightLines: PropTypes.array,
  setVerticalRightLines: PropTypes.func,
  blackRightSquares: PropTypes.array,
  setBlackRightSquares: PropTypes.func,
  setRightPickMes: PropTypes.func,
  pickMeRightDivs: PropTypes.array,
  blackFrontSquares: PropTypes.array,
  setBlackFrontSquares: PropTypes.func,
  setFrontPickMes: PropTypes.func,
  pickMeFrontDivs: PropTypes.array,
  horizontalLines: PropTypes.array,
  setHorizontalLines: PropTypes.func,
  blackLeftSquares: PropTypes.array,
  setBlackLeftSquares: PropTypes.func,
  setLeftPickMes: PropTypes.func,
  pickMeLeftDivs: PropTypes.array,
  setVerticalLeftLines: PropTypes.func,
  verticalLeftLines: PropTypes.array,
  setIndexFront: PropTypes.func,
  setIndexRight: PropTypes.func,
  setIndexLeft: PropTypes.func,
  indexFront: PropTypes.number || PropTypes.string,
  indexRight: PropTypes.number || PropTypes.string,
  indexLeft: PropTypes.number || PropTypes.string,
  setOpenFrontSingleDrawing: PropTypes.func,
  setOpenRightSingleDrawing: PropTypes.func,
  setOpenLeftSingleDrawing: PropTypes.func,
  openFrontSingleDrawing: PropTypes.bool,
  openRightSingleDrawing: PropTypes.bool,
  openLeftSingleDrawing: PropTypes.bool,
  handleDoorOptionChange: PropTypes.func,
  handleDoorWidthChange: PropTypes.func,
  isScreenRoom: PropTypes.bool,
  setSelectedLocations: PropTypes.func,
  setSelectedERFLocations: PropTypes.func,
  setSelectedERRLocations: PropTypes.func,
  setSelectedERLLocations: PropTypes.func,
  selectedLocations: PropTypes.array,
  selectedERFLocations: PropTypes.array,
  selectedERRLocations: PropTypes.array,
  selectedERLLocations: PropTypes.array,
  handleDoorLocationChange: PropTypes.func,
  handleSelectedScreenDoorWidthChoice: PropTypes.func,
  handleBays: PropTypes.func,
  setSelectedOption: PropTypes.func,
  selectedOption: PropTypes.string,
  handleSelectedPatioDoorOrientation: PropTypes.func,
  currentImage: PropTypes.string,
  handleSwingDoorOrientation: PropTypes.func,
};

export default SunRoomWalls;
