import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const ExteriorStyleSelection = ({
  handleExteriorStyleOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedExteriorOption, setSelectedExteriorOption] = useState("");
  const Corbels = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/PergolaCorbels.jpg`;
  const Rafter_Flat_End_Caps = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/PergolaFlatEndCaps.jpg`;
  const Contemporary = `https://temoappstorage.blob.core.windows.net/temoimages/UniversalViewingImages/PergolaContemporary.jpg`;
  const images = [Contemporary, Corbels, Rafter_Flat_End_Caps];
  const messages = ["Contemporary", "Corbels", "Rafter Flat End Caps"];

  const handleExteriorOptionChange = (selected) => {
    const optionChange = selected;
    const updatedRedBorderFields = redBorderFields.includes("Exterior Style")
      ? selected
        ? redBorderFields.filter((field) => field !== "Exterior Style")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Exterior Style"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    setSelectedExteriorOption(optionChange);
    handleExteriorStyleOptionChange(optionChange);
  };

  return (
    <div className="popupwindow exteriorstyle-choices">
      <BoxHeader
        componentName="Exterior Style"
        images={images}
        message={messages}
        redBorderFields={redBorderFields}
        redFieldName={"Exterior Style"}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedExteriorOption === "CONTEMPORARY" ? "clicked" : ""}`}
          onClick={() => handleExteriorOptionChange("CONTEMPORARY")}
        >
          Contemporary
        </div>
        <div
          className={`clickable-word ${selectedExteriorOption === "CORBELS" ? "clicked" : ""}`}
          onClick={() => handleExteriorOptionChange("CORBELS")}
        >
          Corbels
        </div>
      </div>
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedExteriorOption === "FLAT END CAPS" ? "clicked" : ""}`}
          onClick={() => handleExteriorOptionChange("FLAT END CAPS")}
        >
          Rafter Flat End Caps
        </div>
      </div>
    </div>
  );
};

ExteriorStyleSelection.propTypes = {
  handleExteriorStyleOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  selectedBeamOption: PropTypes.string,
};

export default ExteriorStyleSelection;
