import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../../UniversalComponents/BoxHeader";

const FreesStandingorAttached = ({
  onAttachedOptionChange,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedAttachedOption, setSelectedAttachedOption] = useState("");
  const Free_Standing = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/FreeStandingPavilion.JPG`;
  const Attached = `https://temoappstorage.blob.core.windows.net/temoimages/PavilionViewingImages/AttachedPavilion.jpg`;
  const images = [Free_Standing, Attached];
  const messages = ["Free-Standing", "Attached"];

  const handleAttachedOptionChange = (selected) => {
    setSelectedAttachedOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "Free-Standing or Attached",
    )
      ? selected
        ? redBorderFields.filter(
            (field) => field !== "Free-Standing or Attached",
          )
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "Free-Standing or Attached"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    onAttachedOptionChange(selected);
  };

  return (
    <div className="popupwindow freestandingorattached">
      <BoxHeader
        componentName="Free-Standing or Attached"
        redBorderFields={redBorderFields}
        redFieldName={"Free-Standing or Attached"}
        images={images}
        imageMessages={messages}
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedAttachedOption === "FREE-STANDING" ? "clicked" : ""}`}
          onClick={() => handleAttachedOptionChange("FREE-STANDING")}
        >
          Free-Standing
        </div>
        <div
          className={`clickable-word ${selectedAttachedOption === "attached" ? "clicked" : ""}`}
          onClick={() => handleAttachedOptionChange("attached")}
        >
          Attached
        </div>
      </div>
    </div>
  );
};

FreesStandingorAttached.propTypes = {
  onAttachedOptionChange: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default FreesStandingorAttached;
