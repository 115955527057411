import React, { useEffect, useState } from "react";
import BoxHeader from "./BoxHeader";
import PropTypes from "prop-types";

const StructuralBeamSelection = ({
  redBorderFields,
  handleStructuralBeamOption,
  setFieldsWithRedBorder,
  selectedPostOption,
}) => {
  const [selectedStructuralBeam, setSelectedStructuralBeam] = useState("");
  const [className, setClassName] = useState("structbeam");

  const handleStructuralBeamChange = (selectedBeam) => {
    setSelectedStructuralBeam(selectedBeam);
    handleStructuralBeamOption(selectedBeam);
    const updatedRedBorderFields = redBorderFields.includes("Structural Beam")
      ? selectedBeam
        ? redBorderFields.filter((field) => field !== "Structural Beam")
        : redBorderFields
      : selectedBeam
        ? redBorderFields
        : [...redBorderFields, "Structural Beam"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    if (selectedPostOption === "NO COLUMNS OR BEAMS NEEDED") {
      handleStructuralBeamOption("");
    }
  };
  useEffect(() => {
    if (selectedPostOption === "ROMA" || selectedPostOption === "FLORENTINE") {
      setClassName("trimcolor-choices");
    } else {
      setClassName("structbeam");
    }
  }, [selectedPostOption]);
  return (
    <div className={`popupwindow ${className}`}>
      <BoxHeader
        componentName="Structural Beam"
        redBorderFields={redBorderFields}
        redFieldName="Structural Beam"
        defaultMessage={
          "Our Pergola Beams are 2x7 or 5x10.<br/>The 5x10 is two connected halves."
        }
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedStructuralBeam === "2x7" ? "clicked" : ""}`}
          onClick={() => handleStructuralBeamChange("2x7")}
        >
          2x7
        </div>
        <div
          className={`clickable-word ${selectedStructuralBeam === "5x10" ? "clicked" : ""}`}
          onClick={() => handleStructuralBeamChange("5x10")}
        >
          5x10
        </div>
      </div>
    </div>
  );
};

StructuralBeamSelection.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleStructuralBeamOption: PropTypes.func,
  selectedPostOption: PropTypes.string,
  handleExteriorOptionChange: PropTypes.func,
  selectedExteriorOption: PropTypes.string,
};

export default StructuralBeamSelection;
