import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BoxHeader from "./BoxHeader";
import {
  feetInchConversion,
  calculateRoofDensity,
} from "./RoofDensityComponents/RoofDensityUtils";

const RoofDensity = ({
  redBorderFields,
  setFieldsWithRedBorder,
  handleRoofDensity,
  selectedAttachedOption,
  roofstyle,
  lengthFeet,
  lengthInches,
  selectedRoofLoadChange,
  selectedShingle,
  pavstyle,
  isScreenRoom,
  selectedSunRoomType,
}) => {
  const [optionVisibility, setOptionVisibility] = useState({
    option1: false,
    option2: false,
    option3: false,
  });

  const handleDropdownChange = (event) => {
    const selected = event.target.value;
    const updatedRedBorderFields = redBorderFields.includes("roof density")
      ? selected
        ? redBorderFields.filter((field) => field !== "roof density")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "roof density"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleRoofDensity(selected);
  };

  useEffect(() => {
    var totalInches = feetInchConversion(lengthFeet, lengthInches);
    const { option1, option2, option3 } = calculateRoofDensity(
      selectedAttachedOption,
      selectedRoofLoadChange,
      totalInches,
      selectedShingle,
      pavstyle,
      isScreenRoom,
    );
    setOptionVisibility({
      option1: option1,
      option2: option2,
      option3: option3,
    });
  }, [
    selectedAttachedOption,
    selectedRoofLoadChange,
    lengthFeet,
    lengthInches,
    selectedShingle,
    pavstyle,
    isScreenRoom,
  ]);

  return (
    <div className="popupwindow roofdensity">
      <form onSubmit={(e) => e.preventDefault()}>
        <BoxHeader
          componentName="TEMO Roof Choice"
          redBorderFields={redBorderFields}
          redFieldName="roof density"
          defaultMessage="Temo roof panels are 0.032 aluminum thickness.<br/>Sandstone Roof Panels can only be selected if aluminum thickness is 0.024."
        />
        {roofstyle !== "CATHEDRAL" && selectedSunRoomType !== "Solarium" && (
          <span className="fontsizeforinputs">
            <select
              id="roofdensity"
              className="input-box "
              style={{ width: "95%" }}
              onChange={handleDropdownChange}
            >
              <option value="">Select a TEMO Roof Choice</option>
              {optionVisibility.option1 && (
                <option value='4 1/4" 0.024 1 pound'>
                  4 1/4&quot; 0.024 1 pound
                </option>
              )}
              {optionVisibility.option2 && (
                <option value='4 1/4" 0.032 1 pound'>
                  4 1/4&quot; 0.032 1 pound
                </option>
              )}
              {optionVisibility.option3 && (
                <option value='4 1/4" 0.032 2 pound'>
                  4 1/4&quot; 0.032 2 pound
                </option>
              )}
            </select>
          </span>
        )}
        {roofstyle === "CATHEDRAL" && selectedSunRoomType !== "Solarium" && (
          <span className="fontsizeforinputs">
            <select
              id="roofdensity"
              className="input-box "
              style={{ width: "95%" }}
              onChange={handleDropdownChange}
            >
              <option value="">Select a roof density</option>
              <option value='4 1/4" 0.024 1 pound'>
                4 1/4&quot; 0.024 1 pound
              </option>
              <option value='4 1/4" 0.032 1 pound'>
                4 1/4&quot; 0.032 1 pound
              </option>
              <option value='4 1/4" 0.032 2 pound'>
                4 1/4&quot; 0.032 2 pound
              </option>
            </select>
          </span>
        )}
      </form>
    </div>
  );
};

RoofDensity.propTypes = {
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
  handleRoofDensity: PropTypes.func,
  selectedAttachedOption: PropTypes.string,
  roofstyle: PropTypes.string,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
  selectedRoofLoadChange: PropTypes.string,
  selectedShingle: PropTypes.string,
  pavstyle: PropTypes.string,
  isScreenRoom: PropTypes.bool,
  selectedSunRoomType: PropTypes.string,
};

export default RoofDensity;
