import React, { useState } from "react";
import PropTypes from "prop-types";
import BoxHeader from "../UniversalComponents/BoxHeader";

const TEMOCorners = ({
  handleSelectedTEMOCornersChoice,
  redBorderFields,
  setFieldsWithRedBorder,
}) => {
  const [selectedTEMOCornersOption, setSelectedTEMOCornersOption] =
    useState("");

  const handleTEMOCornersOptionChange = (selected) => {
    setSelectedTEMOCornersOption(selected);
    const updatedRedBorderFields = redBorderFields.includes(
      "TEMO CORNERS YES or NO",
    )
      ? selected
        ? redBorderFields.filter((field) => field !== "TEMO CORNERS YES or NO")
        : redBorderFields
      : selected
        ? redBorderFields
        : [...redBorderFields, "TEMO CORNERS YES or NO"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    handleSelectedTEMOCornersChoice(selected);
  };

  return (
    <div className="popupwindow" style={{ width: "100%", marginTop: "1%" }}>
      <BoxHeader
        componentName={"TEMO Corners"}
        redBorderFields={redBorderFields}
        redFieldName={"TEMO CORNERS YES or NO"}
        defaultMessage={
          "This refers to Temo Corners replacing existing corner posts."
        }
      />
      <div className="clickable-words">
        <div
          className={`clickable-word ${selectedTEMOCornersOption === "YES" ? "clicked" : ""}`}
          onClick={() => handleTEMOCornersOptionChange("YES")}
        >
          Yes
        </div>
        <div
          className={`clickable-word ${selectedTEMOCornersOption === "NO" ? "clicked" : ""}`}
          onClick={() => handleTEMOCornersOptionChange("NO")}
        >
          No
        </div>
      </div>
    </div>
  );
};

TEMOCorners.propTypes = {
  handleSelectedTEMOCornersChoice: PropTypes.func,
  redBorderFields: PropTypes.array,
  setFieldsWithRedBorder: PropTypes.func,
};

export default TEMOCorners;
